import { useState, useEffect } from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import Divider from '@mui/material/Divider';
import Checkbox from '@mui/material/Checkbox';
import Link from '@mui/material/Link';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import LoginIcon from '@mui/icons-material/Login';
import Typography from '@mui/material/Typography';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { useNavigate } from "react-router-dom";


import { Auth, Hub } from 'aws-amplify';

// AWS Fuction Calls
import { listAllIndustries } from '../awsFuncCalls'





function Copyright(props) {
  return (
    <Typography variant="body2" color="text.secondary" align="center" {...props}>
      {'Copyright © '}
      <Link color="inherit" href="https://savvybusinessstrategies.com/">
        savvybusinessstrategies.com
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

// TODO remove, this demo shouldn't need to reset the theme.

const defaultTheme = createTheme();

export default function Register(props) {

  const navigate = useNavigate()

  const [industries, setIndustries] = useState([])
  const [username, setUsername] = useState('')
  const [password, setPassword] = useState('')
  const [companyName, setCompanyName] = useState('')
  const [postalZip, setPostalZip] = useState('')
  const [industry, setIndustry] = useState('')
  const [step, setStep] = useState(1)
  const [code, setCode] = useState('')

  function listenToAutoSignInEvent() {
    Hub.listen('auth', ({ payload }) => {
      const { event } = payload;
      if (event === 'autoSignIn') {
        const user = payload.data;
        // assign user
      } else if (event === 'autoSignIn_failure') {
        // redirect to sign in page
      }
    })
  }

  useEffect(() => {
    const asyncFunction = async () => {
      try {
        const res = await listAllIndustries()
        const tempArr = res.industries.sort((a, b) => a.title - b.title)
        setIndustries(tempArr)
        // setFilteredRequests(tempArr)
        return true
      } catch (error) {
        console.log(error)
        return error;
      }
    }
    asyncFunction();
  }, []);


  const handleIndustryChange = (event) => {
    setIndustry(event.target.value);
  };

  const next = () => {
    setStep()
  }

  async function register() {
    try {
      const { user } = await Auth.signUp({
        username,
        password,
        attributes: {
          email: username,
          'custom:companyName': companyName,
          'custom:industry': industry,
          'custom:postalZip': postalZip,
        },
        autoSignIn: { // optional - enables auto sign in after user is confirmed
          enabled: true,
        }
      });
      setStep(5)
    } catch (error) {
      console.log('error signing up:', error);
      const errMessage = error.toString().substring(error.toString().indexOf(': ') + 1);
      alert(errMessage)
    }
  }

  async function resendCode() {
    try {
      const user = await Auth.resendSignUp(username)
      setStep(5)
    }
    catch (error) {
      setStep(1)
      console.log('error signing up:', error);
      const errMessage = error.toString().substring(error.toString().indexOf(': ') + 1);
      alert(errMessage)
    }
  }


  /* Confirm Register function for MFA */
  async function confirmSignUp() {
    try {
      await Auth.confirmSignUp(username, code);
      props.setUserLoggedIn(true)
      props.setUserData({
        firstName: '',
        lastName: '',
        email: username,
        companyName: '',
        address: '',
        city: '',
        province: '',
        postalCode: '',
        phoneNumber: '',
        industry: '',
        subIndustries: [],
        subscriptionStatus: '',
        subscriptionLevel: '',
        group: '',
      })
      setTimeout(() => {
        navigate('/dashboard/account/plans')
      }, 500);
    } catch (error) {
      console.log('error signing up:', error);
      const errMessage = error.toString().substring(error.toString().indexOf(': ') + 1);
      alert(errMessage)
    }
  }

  return (
    <Grid container component="main" sx={{ height: '100vh' }}>
      <CssBaseline />
      <Grid
        item
        xs={false}
        sm={4}
        md={7}
        sx={{
          backgroundImage: 'url(https://source.unsplash.com/random/?business)',
          backgroundRepeat: 'no-repeat',
          backgroundColor: (t) =>
            t.palette.mode === 'light' ? t.palette.grey[50] : t.palette.grey[900],
          backgroundSize: 'cover',
          backgroundPosition: 'center',
        }}
      />
      <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
        <Box
          sx={{
            my: 8,
            mx: 4,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Avatar sx={{ m: 1, bgcolor: 'primary.main' }}>
            <LoginIcon />
            {/* <LockOutlinedIcon /> */}
          </Avatar>
          <Typography component="h1" variant="h5" style={{ marginBottom: 30 }}>
            Get Access Now
            </Typography>

          {/* <Box component="form" noValidate sx={{ mt: 1, border: '1px solid black' }}> */}
          <Grid container justifyContent="center">
            {
              step === "resendcode" &&
              <Grid item xs={12} md={10} lg={8}>
                <TextField
                  value={username}
                  onChange={(e) => setUsername(e.target.value)}
                  required
                  fullWidth
                  // id="email"
                  label="Email Address"
                  // name="email"
                  autoComplete="email"
                  autoFocus
                />
              </Grid>
            }
            {
              step === 1 &&
              <Grid item xs={12} md={10} lg={8}>
                <FormControl>
                  <Typography variant='body1' style={{ marginBottom: 20 }}>
                    Select your general industry.  This will display leads specific to your needs. If you don't see your industry, contact us here.
                  </Typography>
                  {/* <InputLabel id="industryLabel">Select the industry you want leads for</InputLabel> */}
                  <Select
                    value={industry}
                    // label="Industry"
                    required
                    onChange={handleIndustryChange}
                  >
                    {
                      industries.map((ind) => {
                        return (
                          <MenuItem key={ind.id} value={ind.id}>{ind.title}</MenuItem>
                        )
                      })
                    }
                    {/* <MenuItem value={"Cleaning"}>Cleaning</MenuItem>
                    <MenuItem value={"HVAC"}>HVAC</MenuItem>
                    <MenuItem value={"Security"}>Security</MenuItem> */}
                  </Select>
                </FormControl>
              </Grid>
            }
            {
              step === 2 &&
              <Grid item xs={12} md={10} lg={8}>
                <Typography variant='body1' style={{ marginBottom: 20 }}>
                  Enter your Postal Code or Zip Code. This will display relevant quote requests in your area.
                  </Typography>
                <TextField
                  value={postalZip}
                  onChange={(e) => setPostalZip(e.target.value)}
                  required
                  fullWidth
                  // id="email"
                  // label="Postal Code / Zip"
                  // name="email"
                  autoFocus
                />
              </Grid>
            }
            {
              step === 3 &&
              <Grid item xs={12} md={10} lg={8}>
                <Typography variant='body1' style={{ marginBottom: 20 }}>
                  Enter your Company Name.
                </Typography>
                <TextField
                  value={companyName}
                  onChange={(e) => setCompanyName(e.target.value)}
                  required
                  fullWidth
                  // id="email"
                  label="Company Name"
                  // name="email"
                  autoComplete="email"
                  autoFocus
                />
              </Grid>
            }
            {
              step === 4 &&
              <Grid item xs={12} md={10} lg={8}>
                <TextField
                  style={{marginBottom: 12}}
                  value={username}
                  onChange={(e) => setUsername(e.target.value)}
                  required
                  fullWidth
                  // id="email"
                  label="Email Address"
                  // name="email"
                  autoComplete="email"
                  autoFocus
                />
                <TextField
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  required
                  fullWidth
                  name="password"
                  label="Create Password"
                  type="password"
                  id="password"
                  autoComplete="current-password"
                />
              </Grid>
            }
            {
              step === 5 &&
              <Grid item xs={12} md={10} lg={8}>
                <Typography style={{marginBottom: 10, textAlign: 'center'}}>Check your email for a 6 digit code, please enter it below</Typography>
                <TextField
                  value={code}
                  onChange={(e) => setCode(e.target.value)}
                  required
                  fullWidth
                  name="code"
                  label="Verification Code"
                  id="code"
                />
              </Grid>
            }
            <Grid item xs={12} md={10} lg={8}>
              {
                step === 4 ?
                <>
                  <Typography fontSize={12}>*password must be 8 characters long</Typography>
                  <Button
                    onClick={() => register()}
                    disabled={
                      (step === 3 && (username.length === 0 || password.length === 0))
                    }
                    // type="submit"
                    fullWidth
                    variant="contained"
                    sx={{ mt: 3, mb: 2 }}
                  >
                    Next
                </Button>
                </>
                  :
                  step < 4 ?
                    <Button
                      onClick={() => setStep(step + 1)}
                      disabled={
                        (step === 1 && industry.length === 0) ||
                        (step === 2 && postalZip.length === 0) ||
                        (step === 3 && companyName.length === 0) ||
                        (step === 4 && (username.length === 0 || password.length === 0))
                      }
                      // type="submit"
                      fullWidth
                      variant="contained"
                      sx={{ mt: 3, mb: 2 }}
                    >
                      Next
                    </Button>
                    :
                    step === "resendcode" ?
                      <Button
                        onClick={() => resendCode()}
                        // disabled={(step === 4 && code.length !== 6)}
                        // type="submit"
                        fullWidth
                        variant="contained"
                        sx={{ mt: 3, mb: 2 }}
                      >
                        Resend Code
                      </Button>
                      :
                      <Button
                        onClick={() => confirmSignUp()}
                        disabled={(step === 4 && code.length !== 6)}
                        // type="submit"
                        fullWidth
                        variant="contained"
                        sx={{ mt: 3, mb: 2 }}
                      >
                        Verify Code
                </Button>
              }
              {step > 1 &&
                <Button
                  onClick={() => setStep(step - 1)}
                  // type="submit"
                  fullWidth
                  variant="contained"
                  sx={{ mb: 2 }}
                >
                  Back
              </Button>
              }
            </Grid>
            <Grid container flexDirection="column" alignItems="center">
              <Grid item>
                <Divider>Or</Divider>
              </Grid>
              <Grid item>
                <Link
                  href=""
                  component="button"
                  variant="h6"
                  onClick={() => navigate('/login')}
                >
                  Login
                  </Link>
              </Grid>
              {/* <Grid item> */}
                <Divider/>
              {/* </Grid> */}
              <Grid item>
                <Link
                  component="button"
                  variant="body2"
                  onClick={() => setStep("resendcode")}
                  sx={{
                    color: 'black',
                    textDecoration: 'none'
                  }}
                >
                  Code Expired/Already Have A Code?
                  </Link>
              </Grid>
            </Grid>
            <Copyright sx={{ mt: 5 }} />
          </Grid>
          {/* </Box> */}
        </Box>
      </Grid>
    </Grid>
  );
}