/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getAdmin = /* GraphQL */ `
  query GetAdmin($id: ID!) {
    getAdmin(id: $id) {
      id
      firstName
      lastName
      email
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listAdmins = /* GraphQL */ `
  query ListAdmins(
    $filter: ModelAdminFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAdmins(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        firstName
        lastName
        email
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getStripeWebhook = /* GraphQL */ `
  query GetStripeWebhook($id: ID!) {
    getStripeWebhook(id: $id) {
      id
      object
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listStripeWebhooks = /* GraphQL */ `
  query ListStripeWebhooks(
    $filter: ModelStripeWebhookFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listStripeWebhooks(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        object
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getVendor = /* GraphQL */ `
  query GetVendor($id: ID!) {
    getVendor(id: $id) {
      id
      stripeUserId
      firstName
      lastName
      email
      phoneNumber
      companyName
      address
      city
      province
      postalCode
      industry {
        id
        title
        requests {
          nextToken
          __typename
        }
        active
        subIndustry {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      subIndustry {
        items {
          id
          title
          active
          createdAt
          updatedAt
          vendorSubIndustryId
          industrySubIndustryId
          subIndustryIndustryId
          __typename
        }
        nextToken
        __typename
      }
      subscriptionLevel {
        id
        frequency
        title
        amount
        active
        createdDate
        inactiveDate
        entitlement
        createdAt
        updatedAt
        __typename
      }
      subscriptionStatus
      subscriptionExpiry
      autoRenew
      creationDate
      favourites {
        items {
          id
          createdAt
          updatedAt
          vendorFavouritesId
          favouritesRequestsId
          favouritesOwnerIdId
          __typename
        }
        nextToken
        __typename
      }
      userNotes {
        items {
          id
          note
          date
          requestID
          vendorID
          createdAt
          updatedAt
          vendorUserNotesId
          requestUserNotesId
          __typename
        }
        nextToken
        __typename
      }
      group
      createdAt
      updatedAt
      vendorIndustryId
      vendorSubscriptionLevelId
      __typename
    }
  }
`;
export const listVendors = /* GraphQL */ `
  query ListVendors(
    $filter: ModelVendorFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listVendors(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        stripeUserId
        firstName
        lastName
        email
        phoneNumber
        companyName
        address
        city
        province
        postalCode
        industry {
          id
          title
          active
          createdAt
          updatedAt
          __typename
        }
        subIndustry {
          nextToken
          __typename
        }
        subscriptionLevel {
          id
          frequency
          title
          amount
          active
          createdDate
          inactiveDate
          entitlement
          createdAt
          updatedAt
          __typename
        }
        subscriptionStatus
        subscriptionExpiry
        autoRenew
        creationDate
        favourites {
          nextToken
          __typename
        }
        userNotes {
          nextToken
          __typename
        }
        group
        createdAt
        updatedAt
        vendorIndustryId
        vendorSubscriptionLevelId
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const vendorsByEmail = /* GraphQL */ `
  query VendorsByEmail(
    $email: String!
    $sortDirection: ModelSortDirection
    $filter: ModelVendorFilterInput
    $limit: Int
    $nextToken: String
  ) {
    vendorsByEmail(
      email: $email
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        stripeUserId
        firstName
        lastName
        email
        phoneNumber
        companyName
        address
        city
        province
        postalCode
        industry {
          id
          title
          active
          createdAt
          updatedAt
          __typename
        }
        subIndustry {
          nextToken
          __typename
        }
        subscriptionLevel {
          id
          frequency
          title
          amount
          active
          createdDate
          inactiveDate
          entitlement
          createdAt
          updatedAt
          __typename
        }
        subscriptionStatus
        subscriptionExpiry
        autoRenew
        creationDate
        favourites {
          nextToken
          __typename
        }
        userNotes {
          nextToken
          __typename
        }
        group
        createdAt
        updatedAt
        vendorIndustryId
        vendorSubscriptionLevelId
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getLead = /* GraphQL */ `
  query GetLead($id: ID!) {
    getLead(id: $id) {
      id
      companyName
      url
      address
      address2
      city
      provinceState
      postalZip
      contactFirstName
      contactLastName
      titlePosition
      contactPhone
      contactEmail
      buildingType
      requestType {
        id
        title
        requests {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      industry {
        id
        title
        requests {
          nextToken
          __typename
        }
        active
        subIndustry {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      subIndustry {
        id
        title
        industry {
          id
          title
          active
          createdAt
          updatedAt
          __typename
        }
        requests {
          nextToken
          __typename
        }
        active
        createdAt
        updatedAt
        vendorSubIndustryId
        industrySubIndustryId
        subIndustryIndustryId
        __typename
      }
      requestNotes
      internalNotes
      callBackDate
      uploadDate
      goLiveDate
      expirationDate
      createdAt
      updatedAt
      leadRequestTypeId
      leadIndustryId
      leadSubIndustryId
      __typename
    }
  }
`;
export const listLeads = /* GraphQL */ `
  query ListLeads(
    $filter: ModelLeadFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listLeads(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        companyName
        url
        address
        address2
        city
        provinceState
        postalZip
        contactFirstName
        contactLastName
        titlePosition
        contactPhone
        contactEmail
        buildingType
        requestType {
          id
          title
          createdAt
          updatedAt
          __typename
        }
        industry {
          id
          title
          active
          createdAt
          updatedAt
          __typename
        }
        subIndustry {
          id
          title
          active
          createdAt
          updatedAt
          vendorSubIndustryId
          industrySubIndustryId
          subIndustryIndustryId
          __typename
        }
        requestNotes
        internalNotes
        callBackDate
        uploadDate
        goLiveDate
        expirationDate
        createdAt
        updatedAt
        leadRequestTypeId
        leadIndustryId
        leadSubIndustryId
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getRequest = /* GraphQL */ `
  query GetRequest($id: ID!) {
    getRequest(id: $id) {
      id
      companyName
      url
      address
      address2
      city
      provinceState
      postalZip
      contactFirstName
      contactLastName
      titlePosition
      contactPhone
      contactEmail
      buildingType
      requestType {
        id
        title
        requests {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      industry {
        id
        title
        requests {
          nextToken
          __typename
        }
        active
        subIndustry {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      subIndustry {
        id
        title
        industry {
          id
          title
          active
          createdAt
          updatedAt
          __typename
        }
        requests {
          nextToken
          __typename
        }
        active
        createdAt
        updatedAt
        vendorSubIndustryId
        industrySubIndustryId
        subIndustryIndustryId
        __typename
      }
      requestNotes
      uploadDate
      goLiveDate
      expirationDate
      userNotes {
        items {
          id
          note
          date
          requestID
          vendorID
          createdAt
          updatedAt
          vendorUserNotesId
          requestUserNotesId
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      industryRequestsId
      subIndustryRequestsId
      requestTypeRequestsId
      requestRequestTypeId
      requestIndustryId
      requestSubIndustryId
      __typename
    }
  }
`;
export const listRequests = /* GraphQL */ `
  query ListRequests(
    $filter: ModelRequestFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listRequests(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        companyName
        url
        address
        address2
        city
        provinceState
        postalZip
        contactFirstName
        contactLastName
        titlePosition
        contactPhone
        contactEmail
        buildingType
        requestType {
          id
          title
          createdAt
          updatedAt
          __typename
        }
        industry {
          id
          title
          active
          createdAt
          updatedAt
          __typename
        }
        subIndustry {
          id
          title
          active
          createdAt
          updatedAt
          vendorSubIndustryId
          industrySubIndustryId
          subIndustryIndustryId
          __typename
        }
        requestNotes
        uploadDate
        goLiveDate
        expirationDate
        userNotes {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        industryRequestsId
        subIndustryRequestsId
        requestTypeRequestsId
        requestRequestTypeId
        requestIndustryId
        requestSubIndustryId
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getIndustry = /* GraphQL */ `
  query GetIndustry($id: ID!) {
    getIndustry(id: $id) {
      id
      title
      requests {
        items {
          id
          companyName
          url
          address
          address2
          city
          provinceState
          postalZip
          contactFirstName
          contactLastName
          titlePosition
          contactPhone
          contactEmail
          buildingType
          requestNotes
          uploadDate
          goLiveDate
          expirationDate
          createdAt
          updatedAt
          industryRequestsId
          subIndustryRequestsId
          requestTypeRequestsId
          requestRequestTypeId
          requestIndustryId
          requestSubIndustryId
          __typename
        }
        nextToken
        __typename
      }
      active
      subIndustry {
        items {
          id
          title
          active
          createdAt
          updatedAt
          vendorSubIndustryId
          industrySubIndustryId
          subIndustryIndustryId
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listIndustries = /* GraphQL */ `
  query ListIndustries(
    $filter: ModelIndustryFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listIndustries(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        title
        requests {
          nextToken
          __typename
        }
        active
        subIndustry {
        items{
          id
          title
          active
        }
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getSubIndustry = /* GraphQL */ `
  query GetSubIndustry($id: ID!) {
    getSubIndustry(id: $id) {
      id
      title
      industry {
        id
        title
        requests {
          nextToken
          __typename
        }
        active
        subIndustry {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      requests {
        items {
          id
          companyName
          url
          address
          address2
          city
          provinceState
          postalZip
          contactFirstName
          contactLastName
          titlePosition
          contactPhone
          contactEmail
          buildingType
          requestNotes
          uploadDate
          goLiveDate
          expirationDate
          createdAt
          updatedAt
          industryRequestsId
          subIndustryRequestsId
          requestTypeRequestsId
          requestRequestTypeId
          requestIndustryId
          requestSubIndustryId
          __typename
        }
        nextToken
        __typename
      }
      active
      createdAt
      updatedAt
      vendorSubIndustryId
      industrySubIndustryId
      subIndustryIndustryId
      __typename
    }
  }
`;
export const listSubIndustries = /* GraphQL */ `
  query ListSubIndustries(
    $filter: ModelSubIndustryFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSubIndustries(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        title
        industry {
          id
          title
          active
          createdAt
          updatedAt
          __typename
        }
        requests {
          nextToken
          __typename
        }
        active
        createdAt
        updatedAt
        vendorSubIndustryId
        industrySubIndustryId
        subIndustryIndustryId
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getRequestType = /* GraphQL */ `
  query GetRequestType($id: ID!) {
    getRequestType(id: $id) {
      id
      title
      requests {
        items {
          id
          companyName
          url
          address
          address2
          city
          provinceState
          postalZip
          contactFirstName
          contactLastName
          titlePosition
          contactPhone
          contactEmail
          buildingType
          requestNotes
          uploadDate
          goLiveDate
          expirationDate
          createdAt
          updatedAt
          industryRequestsId
          subIndustryRequestsId
          requestTypeRequestsId
          requestRequestTypeId
          requestIndustryId
          requestSubIndustryId
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listRequestTypes = /* GraphQL */ `
  query ListRequestTypes(
    $filter: ModelRequestTypeFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listRequestTypes(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        title
        requests {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getSubscriptionLevel = /* GraphQL */ `
  query GetSubscriptionLevel($id: ID!) {
    getSubscriptionLevel(id: $id) {
      id
      frequency
      title
      amount
      active
      createdDate
      inactiveDate
      entitlement
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listSubscriptionLevels = /* GraphQL */ `
  query ListSubscriptionLevels(
    $filter: ModelSubscriptionLevelFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSubscriptionLevels(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        frequency
        title
        amount
        active
        createdDate
        inactiveDate
        entitlement
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getFavourites = /* GraphQL */ `
  query GetFavourites($id: ID!) {
    getFavourites(id: $id) {
      id
      requests {
        id
        companyName
        url
        address
        address2
        city
        provinceState
        postalZip
        contactFirstName
        contactLastName
        titlePosition
        contactPhone
        contactEmail
        buildingType
        requestType {
          id
          title
          createdAt
          updatedAt
          __typename
        }
        industry {
          id
          title
          active
          createdAt
          updatedAt
          __typename
        }
        subIndustry {
          id
          title
          active
          createdAt
          updatedAt
          vendorSubIndustryId
          industrySubIndustryId
          subIndustryIndustryId
          __typename
        }
        requestNotes
        uploadDate
        goLiveDate
        expirationDate
        userNotes {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        industryRequestsId
        subIndustryRequestsId
        requestTypeRequestsId
        requestRequestTypeId
        requestIndustryId
        requestSubIndustryId
        __typename
      }
      ownerId {
        id
        stripeUserId
        firstName
        lastName
        email
        phoneNumber
        companyName
        address
        city
        province
        postalCode
        industry {
          id
          title
          active
          createdAt
          updatedAt
          __typename
        }
        subIndustry {
          nextToken
          __typename
        }
        subscriptionLevel {
          id
          frequency
          title
          amount
          active
          createdDate
          inactiveDate
          entitlement
          createdAt
          updatedAt
          __typename
        }
        subscriptionStatus
        subscriptionExpiry
        autoRenew
        creationDate
        favourites {
          nextToken
          __typename
        }
        userNotes {
          nextToken
          __typename
        }
        group
        createdAt
        updatedAt
        vendorIndustryId
        vendorSubscriptionLevelId
        __typename
      }
      createdAt
      updatedAt
      vendorFavouritesId
      favouritesRequestsId
      favouritesOwnerIdId
      __typename
    }
  }
`;
export const listFavourites = /* GraphQL */ `
  query ListFavourites(
    $filter: ModelFavouritesFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listFavourites(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        requests {
          id
          companyName
          url
          address
          address2
          city
          provinceState
          postalZip
          contactFirstName
          contactLastName
          titlePosition
          contactPhone
          contactEmail
          buildingType
          requestNotes
          uploadDate
          goLiveDate
          expirationDate
          createdAt
          updatedAt
          industryRequestsId
          subIndustryRequestsId
          requestTypeRequestsId
          requestRequestTypeId
          requestIndustryId
          requestSubIndustryId
          __typename
        }
        ownerId {
          id
          stripeUserId
          firstName
          lastName
          email
          phoneNumber
          companyName
          address
          city
          province
          postalCode
          subscriptionStatus
          subscriptionExpiry
          autoRenew
          creationDate
          group
          createdAt
          updatedAt
          vendorIndustryId
          vendorSubscriptionLevelId
          __typename
        }
        createdAt
        updatedAt
        vendorFavouritesId
        favouritesRequestsId
        favouritesOwnerIdId
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getNotes = /* GraphQL */ `
  query GetNotes($id: ID!) {
    getNotes(id: $id) {
      id
      note
      date
      requestID
      request {
        id
        companyName
        url
        address
        address2
        city
        provinceState
        postalZip
        contactFirstName
        contactLastName
        titlePosition
        contactPhone
        contactEmail
        buildingType
        requestType {
          id
          title
          createdAt
          updatedAt
          __typename
        }
        industry {
          id
          title
          active
          createdAt
          updatedAt
          __typename
        }
        subIndustry {
          id
          title
          active
          createdAt
          updatedAt
          vendorSubIndustryId
          industrySubIndustryId
          subIndustryIndustryId
          __typename
        }
        requestNotes
        uploadDate
        goLiveDate
        expirationDate
        userNotes {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        industryRequestsId
        subIndustryRequestsId
        requestTypeRequestsId
        requestRequestTypeId
        requestIndustryId
        requestSubIndustryId
        __typename
      }
      vendorID
      vendor {
        id
        stripeUserId
        firstName
        lastName
        email
        phoneNumber
        companyName
        address
        city
        province
        postalCode
        industry {
          id
          title
          active
          createdAt
          updatedAt
          __typename
        }
        subIndustry {
          nextToken
          __typename
        }
        subscriptionLevel {
          id
          frequency
          title
          amount
          active
          createdDate
          inactiveDate
          entitlement
          createdAt
          updatedAt
          __typename
        }
        subscriptionStatus
        subscriptionExpiry
        autoRenew
        creationDate
        favourites {
          nextToken
          __typename
        }
        userNotes {
          nextToken
          __typename
        }
        group
        createdAt
        updatedAt
        vendorIndustryId
        vendorSubscriptionLevelId
        __typename
      }
      createdAt
      updatedAt
      vendorUserNotesId
      requestUserNotesId
      __typename
    }
  }
`;
export const listNotes = /* GraphQL */ `
  query ListNotes(
    $filter: ModelNotesFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listNotes(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        note
        date
        requestID
        request {
          id
          companyName
          url
          address
          address2
          city
          provinceState
          postalZip
          contactFirstName
          contactLastName
          titlePosition
          contactPhone
          contactEmail
          buildingType
          requestNotes
          uploadDate
          goLiveDate
          expirationDate
          createdAt
          updatedAt
          industryRequestsId
          subIndustryRequestsId
          requestTypeRequestsId
          requestRequestTypeId
          requestIndustryId
          requestSubIndustryId
          __typename
        }
        vendorID
        vendor {
          id
          stripeUserId
          firstName
          lastName
          email
          phoneNumber
          companyName
          address
          city
          province
          postalCode
          subscriptionStatus
          subscriptionExpiry
          autoRenew
          creationDate
          group
          createdAt
          updatedAt
          vendorIndustryId
          vendorSubscriptionLevelId
          __typename
        }
        createdAt
        updatedAt
        vendorUserNotesId
        requestUserNotesId
        __typename
      }
      nextToken
      __typename
    }
  }
`;
