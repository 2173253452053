import { useState, useEffect } from 'react';
import { Routes, Route, Navigate, useNavigate } from "react-router-dom"
import { Amplify, API, graphqlOperation, Auth } from 'aws-amplify'
import { createTheme, ThemeProvider } from "@mui/material/styles";
import CssBaseline from '@mui/material/CssBaseline';
import ProtectedRoutes from "./protectedroutes"
import AdminRoutes from "./adminroutes"
import PublicRoutes from "./publicroutes"
import DashboardLayout from "./layouts/dashboard"
import LoginPage from "./pages/LoginPage"
import Plans from "./pages/Plans"
import Register from "./pages/Register"
import DashboardAppPage from './pages/admin/DashboardAdmin';
import HeroPage from "./pages/HeroPage"
import Favourites from "./pages/Favourites"
import ServiceRequests from "./pages/ServiceRequests"
import ContractRequests from "./pages/ContractRequests"
import Leads from "./pages/Leads"
import RequestDetails from "./pages/RequestDetails"
import UploadLeads from "./pages/admin/UploadLeads"
import AddIndustry from "./pages/admin/AddIndustry"
import AddSubIndustry from "./pages/admin/AddSubIndustry"
import ViewAllLeads from "./pages/admin/ViewAllLeads"
import EditLead from "./pages/admin/EditLead"
import Vendors from "./pages/admin/Vendors"
import awsExports from "./aws-exports";
import AccountPage from "./pages/AccountPage"

import { getVendorInfo } from './awsFuncCalls'
import FAQ from './pages/FAQ';
import Company from './pages/Company';
import Resources from "./pages/Resources"
import Contact from "./pages/Contact"
import Privacy from "./pages/Privacy"
import Terms from "./pages/Terms"
import IndustryList from "./pages/IndustryList"
import LoadingImage from './components/loading/LoadingImage';


Amplify.configure(awsExports);

// Auth.currentCredentials()
//   .then(d => console.log('data Auth: ', d)) // consolelog d for details
//   .catch(e => console.log('error Auth: ', e))


const initialUserData = {
  firstName: '',
  lastName: '',
  email: '',
  companyName: '',
  address: '',
  city: '',
  province: '',
  postalCode: '',
  phoneNumber: '',
  industry: '',
  subIndustries: [],
  subscriptionStatus: '',
  subscriptionLevel: '',
  group: '',
};

function App() {
  // Century gothic
  const navigate = useNavigate()

  const [loading, setLoading] = useState(true)
  const [userLoggedIn, setUserLoggedIn] = useState(false)
  const [userData, setUserData] = useState(initialUserData);
  const [subscribed, setSubscribed] = useState(false)

  const theme = createTheme({
    palette: {
      primary: {
        // main: "#fc03f8",
        main: "#f78d02"
      },
      secondary: {
        main: "#2802fa",
      },
    },
    typography: {
      navBar: {
        fontSize: "2rem"
      }
    }
  });


  useEffect(() => {
    // console.log('running the effect again')
    console.log("here")
    const getCogData = async () => {
      try {
        const cognitoUserInfo = await Auth.currentAuthenticatedUser({ bypassCache: true })
        if (cognitoUserInfo.username) {
          console.log("here")
          try {
            const userInfo = await getVendorInfo(cognitoUserInfo.username)
            // const tempObj = userInfo.response
            setUserData({
              ...userData,
              id: userInfo.response.id ? userInfo.response.id : "unknown",
              firstName: userInfo.response.firstName ? userInfo.response.firstName : "",
              lastName: userInfo.response.lastName ? userInfo.response.lastName : "",
              email: userInfo.response.email ? userInfo.response.email : "",
              companyName: userInfo.response.companyName ? userInfo.response.companyName : "",
              address: userInfo.response.address ? userInfo.response.address : "",
              city: userInfo.response.city ? userInfo.response.city : "",
              province: userInfo.response.province ? userInfo.response.province : "",
              postalCode: userInfo.response.postalCode ? userInfo.response.postalCode : "",
              phoneNumber: userInfo.response.phoneNumber ? userInfo.response.phoneNumber : "",
              industry: userInfo.response.industry.title ? userInfo.response.industry.title : "",
              subIndustries: ["test"],
              subscriptionExpiry: userInfo.response.subscriptionExpiry ? userInfo.response.subscriptionExpiry : "N/A",
              subscriptionStatus: userInfo.response.subscriptionStatus ? userInfo.response.subscriptionStatus : false, // userInfo.response.firstName ? userInfo.response.firstName : "",
              subscriptionLevel: userInfo.response.subscriptionLevel ? userInfo.response.subscriptionLevel : "N/A", // userInfo.response.firstName ? userInfo.response.firstName : "",
              group: userInfo.response.group ? userInfo.response.group : "",
            })
            setUserLoggedIn(true)
            console.log("here")
            setLoading(false)
            // setUserData(initialUserData)
            // console.log(userInfo, "-> Vendor Info")
          } catch (error) {
            setLoading(false)
            console.log("here")
            console.log(error)
            return error;
          }
        } else {
          console.log("here")
          setUserLoggedIn(false)
          setLoading(false)
        }

      }
      catch (e) {
        console.log(e)
        console.log("here")
        setLoading(false)
      }
      return true
    }
    getCogData()
  }, [])


  const signIn = async (username, password) => {
    // setDisableButton(true)
    try {
      const cognitoUserInfo = await Auth.signIn(username, password);
      console.log(cognitoUserInfo, "USER INFO")
      /* Once the user successfully signs in, update the form state to show the signed in state */
      if (cognitoUserInfo.username) {
        const userInfo = await getVendorInfo(cognitoUserInfo.username)
        // const tempObj = userInfo.response
        setUserData({
          ...userData,
          id: userInfo.response.id ? userInfo.response.id : "unknown",
          firstName: userInfo.response.firstName ? userInfo.response.firstName : "",
          lastName: userInfo.response.lastName ? userInfo.response.lastName : "",
          email: userInfo.response.email ? userInfo.response.email : "",
          companyName: userInfo.response.companyName ? userInfo.response.companyName : "",
          address: userInfo.response.address ? userInfo.response.address : "",
          city: userInfo.response.city ? userInfo.response.city : "",
          province: userInfo.response.province ? userInfo.response.province : "",
          postalCode: userInfo.response.postalCode ? userInfo.response.postalCode : "",
          phoneNumber: userInfo.response.phoneNumber ? userInfo.response.phoneNumber : "",
          industry: userInfo.response.industry.title ? userInfo.response.industry.title : "",
          subIndustries: ["test"],
          subscriptionExpiry: userInfo.response.subscriptionExpiry ? userInfo.response.subscriptionExpiry : "N/A",
          subscriptionStatus: userInfo.response.subscriptionStatus ? userInfo.response.subscriptionStatus : false, // userInfo.response.firstName ? userInfo.response.firstName : "",
          subscriptionLevel: userInfo.response.subscriptionLevel ? userInfo.response.subscriptionLevel : "N/A", // userInfo.response.firstName ? userInfo.response.firstName : "",
          group: userInfo.response.group ? userInfo.response.group : "",
        })
        setUserLoggedIn(true)
        setLoading(false)
        console.log("here")
        // console.log(cognitoUserInfo, "sign in button")
      } else {
        setLoading(false)
        console.log("here")
        return
      }
    } catch (err) {
      setLoading(false)
      console.log("here")
      console.log(err);
      alert(err.message)
      // return ({error: err})
      // const errMessage = isFrench ? "Courriel ou mot de passe incorrect" : "Email and Password do not match"
      // setErrorMessage(errMessage)
      // setDisableButton(false)
    }
  }

  return (
    <>
      {loading
        ?
        <LoadingImage />
        :
        <div >
          <CssBaseline />
          <ThemeProvider theme={theme}>
            
            <Routes>
              <Route path="/" element={<PublicRoutes userLoggedIn={userLoggedIn} />}>
                <Route path="" element={<HeroPage />} />
                <Route path="/home" element={<Navigate to="/" replace />} />
                <Route path="/login" element={<LoginPage userLoggedIn={userLoggedIn} setUserLoggedIn={setUserLoggedIn} signIn={signIn} />} />
                <Route path="/register" element={<Register userLoggedIn={userLoggedIn} setUserLoggedIn={setUserLoggedIn} setUserData={setUserData} />} />
                <Route path="/company" element={<Company userLoggedIn={userLoggedIn} setUserLoggedIn={setUserLoggedIn} setUserData={setUserData} />} />
                <Route path="/faq" element={<FAQ />} />
                <Route path="/resources" element={<Resources />} />
                <Route path="/contact" element={<Contact userData={userData} />} />
                <Route path="/industries" element={<IndustryList />} />
                <Route path="/terms" element={<Terms />} />
                <Route path="/privacy" element={<Privacy />} />
                {/* <Route path="plans" element={<Plans userLoggedIn={userLoggedIn} setUserLoggedIn={setUserLoggedIn} signIn={signIn} />} /> */}
              </Route>
              <Route element={<ProtectedRoutes userLoggedIn={userLoggedIn} />}>
                <Route path="/dashboard" element={<DashboardLayout setUserLoggedIn={setUserLoggedIn} userData={userData} setUserData={setUserData} userLoggedIn={userLoggedIn} />} >
                  {/* <Route path='admin' element={<DashboardAppPage />} /> */}
                  <Route path='favourites' element={<Favourites userData={userData} />} />
                  <Route path='favourites/:requestid' element={<RequestDetails />} />
                  <Route path='servicerequests' element={<ServiceRequests userData={userData} />} />
                  <Route path='leads' element={<Leads userData={userData} />} />
                  <Route path='servicerequests/:requestid' element={<RequestDetails userData={userData} />} />
                  <Route path='contractrequests' element={<ContractRequests userData={userData} />} />
                  <Route path='contractrequests/:requestid' element={<RequestDetails />} />
                  <Route path='account' element={<AccountPage subscribed={subscribed} />} />
                  <Route path="contact" element={<Contact userData={userData} />} />
                  <Route path="dashboard/contact" element={<Contact userData={userData} />} />
                  <Route path="account/plans" element={<Plans userLoggedIn={userLoggedIn} setUserLoggedIn={setUserLoggedIn} signIn={signIn} userData={userData} />} />
                </Route>
              </Route>
              <Route element={<AdminRoutes userData={userData} />}>
                <Route path="admin" element={<DashboardLayout setUserLoggedIn={setUserLoggedIn} userData={userData} userLoggedIn={userLoggedIn} />} >
                  <Route path='' element={<DashboardAppPage setUserLoggedIn={setUserLoggedIn} userData={userData} />} />
                  <Route path='uploadleads' element={<UploadLeads />} />
                  <Route path='addindustry' element={<AddIndustry />} />
                  <Route path='addsubindustry' element={<AddSubIndustry />} />
                  <Route path='viewleads' element={<ViewAllLeads />} />
                  <Route path='viewleads/:requestid' element={<EditLead />} />
                  <Route path='viewvendors' element={<Vendors />} />
                </Route>
              </Route>
            </Routes>
          </ThemeProvider>
        </div>
      }
    </>
  )
}

export default App