
import * as React from "react";
import {
    Navigate,
    Outlet
} from "react-router-dom";



export default function ProtectedRoutes({
    userLoggedIn,
    children
}) {

    // const data = false
    if (!userLoggedIn) {
        return <Navigate to={'/login'}
            replace
        />
    }
    return children || <Outlet />;
}