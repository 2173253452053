import React, { useState, useEffect } from 'react';
import { Typography, TextField, Button, List, ListItem, ListItemText, ListItemSecondaryAction, IconButton, Dialog, DialogTitle, DialogContent, DialogActions } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';


import { listAllIndustries, listAllSubIndustries, addSubIndustry, editIndustry } from '../../awsFuncCalls'

function AddSubIndustry() {
    const [newSubIndustryName, setNewSubIndustryName] = useState('');
    const [industries, setIndustries] = useState([])
    const [subIndustries, setSubIndustries] = useState([])
    const [editSubIndustryId, setEditSubIndustryId] = useState(null);
    const [editedSubIndustryName, setEditedSubIndustryName] = useState('');
    const [selectedIndustryId, setSelectedIndustryId] = useState('')

    useEffect(() => {
        const asyncFunction = async () => {
            try {
                const res = await listAllIndustries()
                const tempArr = res.industries.sort((a, b) => a.title.toUpperCase().localeCompare(b.title.toUpperCase()))
                setIndustries(tempArr)
                // setFilteredRequests(tempArr)
                try {
                    const res = await listAllSubIndustries()
                    const tempArr = res.subIndustries.sort((a, b) => a.title.toUpperCase().localeCompare(b.title.toUpperCase()))
                    setSubIndustries(tempArr)
                    // setFilteredRequests(tempArr)
                    return true
                } catch (e) {
                    console.log(e)
                    return e;
                }
            } catch (error) {
                console.log(error)
                return error;
            }
        }
        asyncFunction();
    }, []);

    const handleAddSubIndustry = async (selectedIndustryId) => {
        if (newSubIndustryName.trim() !== '') {
            const res = await addSubIndustry(newSubIndustryName.trim(), selectedIndustryId)
            setSubIndustries([...subIndustries, res.newSubInd]);
            setNewSubIndustryName('');
            setSelectedIndustryId('')
        }
    };

    const handleEditIndustry = (id, name) => {
        setEditSubIndustryId(id);
        setEditedSubIndustryName(name);
    };

    const handleSaveEdit = async (id) => {
        if (editedSubIndustryName.trim() !== '') {
            const res = await editIndustry(editedSubIndustryName.trim(), id)
            if (res.success) {
                const updatedIndustries = industries.map(industry => {
                    if (industry.id === id) {
                        return { ...industry, title: editedSubIndustryName.trim() };
                    }
                    return industry;
                });
                setIndustries(updatedIndustries);
                setEditSubIndustryId(null);
                setEditedSubIndustryName('');
            }
        }
    };

    const handleCancelEdit = () => {
        setEditSubIndustryId(null);
        setEditedSubIndustryName('');
    };

    const handleDeleteIndustry = id => {
        const updatedIndustries = industries.filter(industry => industry.id !== id);
        setIndustries(updatedIndustries);
    };

    const handleIndsutryChange = (id) => {
        setSelectedIndustryId(id)
    }

    return (
        <div>
            <Typography variant="h3">Sub Industries</Typography>
            <List>
                {subIndustries.map(subIndustry => (
                    <ListItem key={subIndustry.id}>
                        {/* {editSubIndustryId === industry.id ? 
                        (
                            <>
                                <TextField
                                    value={industry.title}
                                    fullWidth
                                    variant="outlined"
                                    size="small"
                                />
                                <ArrowForwardIcon />
                                <TextField
                                    value={editedSubIndustryName}
                                    onChange={e => setEditedSubIndustryName(e.target.value)}
                                    fullWidth
                                    variant="outlined"
                                    size="small"
                                />
                                <Button onClick={() => handleSaveEdit(industry.id)} variant="contained" color="primary">Save</Button>
                                <Button onClick={handleCancelEdit} variant="contained" color="secondary">Cancel</Button>
                            </>
                        ) 
                        : 
                        ( */}
                            <>
                                <ListItemText primary={subIndustry.title} key={subIndustry.title} />
                                <ListItemText primary={subIndustry.industry.title} key={subIndustry.industry.title} />

                                {/* <ListItemSecondaryAction>
                                    <IconButton onClick={() => handleEditIndustry(industry.id, industry.name)}>
                                        <EditIcon />
                                    </IconButton>
                                    <IconButton onClick={() => handleDeleteIndustry(industry.id)}>
                                        <DeleteIcon />
                                    </IconButton>
                                </ListItemSecondaryAction> */}
                            </>
                        {/* )
                        } */}
                    </ListItem>
                ))}
            </List>
            <TextField
                value={newSubIndustryName}
                onChange={e => setNewSubIndustryName(e.target.value)}
                
                label="Enter new Sub Industry"
                variant="outlined"
                size="small"
            />
            <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={selectedIndustryId}
                label="Industry"
                onChange={e => setSelectedIndustryId(e.target.value)}
                style={{ width: 100 }}
            >
                {
                    industries.map((ind) => {
                        return (
                            <MenuItem name={'test'} value={ind.id}>{ind.title}</MenuItem>
                        )
                    })
                }
            </Select>

            <Button onClick={() => handleAddSubIndustry(selectedIndustryId)} variant="contained" color="primary">Add</Button>
        </div>
    );
}

export default AddSubIndustry;
