import React, { useState, useEffect } from 'react'
import { useLocation, useNavigate } from 'react-router-dom';

// @mui
import { Grid, Button, Container, Typography, TextField, Divider } from '@mui/material';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import AddIcon from '@mui/icons-material/Add';
import NotificationAddIcon from '@mui/icons-material/NotificationAdd';
import StarIcon from '@mui/icons-material/Star';
import StarBorderIcon from '@mui/icons-material/StarBorder';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { red, purple, pink, yellow, blue, lightGreen, orange, lime, amber, lightBlue } from '@mui/material/colors';
import Avatar from '@mui/material/Avatar';
import Paper from '@mui/material/Paper';
import ModeOfTravelIcon from '@mui/icons-material/ModeOfTravel';
import PermIdentityIcon from '@mui/icons-material/PermIdentity';
import SendIcon from '@mui/icons-material/Send';

import { Auth, Hub } from 'aws-amplify';

// AWS Fuction Calls
import { saveNotes, listRequestNotes, getServiceRequests, getVendorFavorites, addToFavourite, removeFavourites } from '../awsFuncCalls'

// ----------------------------------------------------------------------

export default function RequestDetails() {

  const navigate = useNavigate()

  const [noteInput, setNoteInput] = useState('')
  const [notes, setNotes] = useState([])
  const [requestDetails, setRequestDetails] = useState([])
  const [favourite, setFavourite] = useState([])
  const [isFavourite, setIsFavourite] = useState(false)
  const [loading, setLoading] = useState(true)

  const { state } = useLocation();
  const requestId = state?.row.id;

  useEffect(() => {
    const asyncFunction = async () => {

      const cognitoUserInfo = await Auth.currentAuthenticatedUser({ bypassCache: true })
      if (cognitoUserInfo) {
        try {
          const notesArr = await listRequestNotes(cognitoUserInfo.attributes.sub, requestId)
          const requestDetails = await getServiceRequests(requestId)
          const userFavourites = await getVendorFavorites(cognitoUserInfo.attributes.sub)
          const fave = userFavourites.favourites.data.listFavourites.items.find((obj) => obj.favouritesRequestsId.toString() === requestId)
          setRequestDetails(requestDetails.allRequests.data.getRequest)
          setNotes(notesArr.notes)
          setFavourite(fave)
          setLoading(false)
          setIsFavourite(userFavourites.favourites.data.listFavourites.items.some(item => item.requests.id === requestId))
          return true
        } catch (error) {
          console.log(error)
          return error;
        }
      }
      else {
        return true
      }
    }
    asyncFunction();

  }, [isFavourite])

  const generateNote = async (note, requestID) => {
    const cognitoUserInfo = await Auth.currentAuthenticatedUser({ bypassCache: true })
    const date = Date.now()
    const vendorID = cognitoUserInfo.attributes.sub
    const requestUserNotesId = requestId
    const vendorUserNotesId = 'de953c3c-c432-408b-a96d-fe710dba1708'
    saveNotes(date, note, requestID, requestUserNotesId, vendorID, vendorID)
    setNotes([...notes, { date, note }])
    setNoteInput('')
  }

  const addFavourites = async () => {
    const cognitoUserInfo = await Auth.currentAuthenticatedUser({ bypassCache: true })
    if (cognitoUserInfo) {
      const vendorId = cognitoUserInfo.attributes.sub
      if (isFavourite) {
        removeFavourites(favourite.id)
        setIsFavourite(false)
      } else {
        addToFavourite(requestId, vendorId)
        setIsFavourite(true)
      }
    }
  }

  return (
    <>
      {
        loading ?
          <div>loading</div>
          :
          <Grid container sx={{ paddingX: 10, border: '0px solid red' }}>
            <Paper elevation={0}>
              <Grid container >
                <Grid container item xs={12} style={{ marginBottom: 20 }}>
                  <IconButton
                    aria-label="Favourite"
                    size="large"
                    onClick={() => navigate(-1)}
                  >
                    <ArrowBackIcon fontSize='large' />
                  </IconButton>
                </Grid>
                <Grid container xs={12} sx={{ borderRight: '0px solid grey', borderBottom: '0px solid grey' }}>
                  <Grid container justifyContent='space-evenly' alignItems='center' item sx={{ border: '0px solid lightGrey' }}>
                    <Grid item md={4}>
                      <img
                        src={`https://logo.clearbit.com/${requestDetails.url}?size=600}`}
                        alt="test"
                      />
                    </Grid>
                    <Grid item container md={4} justifyContent='center' style={{ border: '0px solid green' }} >
                      <Typography variant="h2" textAlign='center' fontWeight='bold'>
                        {requestDetails.companyName}
                      </Typography>
                      <Grid item container justifyContent='center'>
                        <Typography variant="h6" textAlign='center' sx={{ border: '0px solid green' }} >
                          www.{requestDetails.url}
                        </Typography>
                      </Grid>
                    </Grid>
                    <Grid item md={4} style={{ border: '0px solid green' }} >
                      <Grid item container justifyContent='flex-end' sx={{ border: '0px solid lightGrey' }} >
                        <Tooltip title={isFavourite ? "Remove from Favourites" : "Add to Favourites"}>
                          <IconButton
                            aria-label="Favourite"
                            size="large"
                            onClick={addFavourites}
                          >
                            {isFavourite ? <StarIcon style={{ color: blue[900], fontSize: 40 }} /> : <StarBorderIcon style={{ color: blue[900], fontSize: 45 }} />}
                          </IconButton>
                        </Tooltip>
                        <Tooltip title="Add Reminder">
                          <IconButton
                            aria-label="Reminder"
                            size="large"
                            onClick={() => console.log("add a reminder here")}
                          >
                            <NotificationAddIcon style={{ color: orange[500], fontSize: 40 }} />
                          </IconButton>
                        </Tooltip>
                        <Tooltip title="Send to my email">
                          <IconButton
                            aria-label="Reminder"
                            size="large"
                            onClick={() => console.log("add a reminder here")}
                          >
                            <SendIcon style={{ color: pink.A200, fontSize: 40 }} />
                          </IconButton>
                        </Tooltip>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid container >
                    <Grid container justifyContent='space-between'>
                      <Grid item sm={6} container flexDirection='column' style={{ marginTop: 20, borderRadius: 15, border: '2px solid black' }}>
                        <Grid item>
                          <Grid item container direction="column" style={{ marginTop: 0, padding: 10 }}>
                            <Grid item container style={{ marginBottom: 10 }}>
                              <Typography variant="h4" fontWeight="Bold"  >
                                {`${requestDetails.requestType.title} Information`}
                              </Typography>
                            </Grid>
                            <Grid item container style={{ marginTop: '3px' }}>
                              <Grid item container xs={4} direction="row" style={{ marginTop: '3px' }}>
                                <Grid item><Typography variant="h6" fontWeight='bold'>Industry:</Typography></Grid>
                              </Grid>
                              <Grid item container xs={8} direction="row" style={{ marginTop: '3px' }}>
                                <Grid item><Typography variant="h6"  >{requestDetails.industry.title}</Typography></Grid>
                              </Grid>
                              <Grid item container xs={4} direction="row" style={{ marginTop: '3px' }}>
                                <Grid item><Typography variant="h6" fontWeight='bold'>Sub Industry:</Typography></Grid>
                              </Grid>
                              <Grid item container xs={8} direction="row" style={{ marginTop: '3px' }}>
                                <Grid item><Typography variant="h6"  >{requestDetails.subIndustry.title}</Typography></Grid>
                              </Grid>
                              <Grid item container xs={4} direction="row" style={{ marginTop: '3px' }}>
                                <Grid item><Typography variant="h6" fontWeight='bold'>Go Live Date:</Typography></Grid>
                              </Grid>
                              <Grid item container xs={8} direction="row" style={{ marginTop: '3px' }}>
                                <Grid item><Typography variant="h6"  >{new Date((Number(requestDetails.goLiveDate))).toDateString()}</Typography></Grid>
                              </Grid>
                              <Grid item container xs={4} direction="row" style={{ marginTop: '3px' }}>
                                <Grid item><Typography sx={{color: 'red'}} variant="h6" fontWeight='bold'>Expiry Date:</Typography></Grid>
                              </Grid>
                              <Grid item container xs={8} direction="row" style={{ marginTop: '3px' }}>
                                <Grid item><Typography variant="h6" >{new Date((Number(requestDetails.expirationDate))).toDateString()}</Typography></Grid>
                              </Grid>
                            </Grid>
                          </Grid>

                          <Grid item container style={{ border: "0px solid lightGrey", marginTop: 10, padding: 10 }} >
                            <Typography variant="h6" textAlign="left"  fontWeight='bold' >
                              Request Notes
                            </Typography>
                            <Grid item container style={{ marginTop: '3px', border: "0px solid black" }}>
                              <Grid item>
                                <Typography textAlign="left" variant="h6" >{requestDetails.requestNotes}</Typography>
                              </Grid>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>


                      <Grid item sm={5.5} px={{ border: '0px solid red', marginTop: 0, paddingRight: 0 }}>
                        <Grid item style={{ marginTop: 20, padding: 20, borderRadius: 15, border: '2px solid black', backgroundColor: 'lightGrey', }}>
                          <Grid item container >
                            <PermIdentityIcon sx={{ fontSize: 100 }} />
                            <Grid item flexDirection='column'>
                              <Typography  fontWeight="bold" sx={{ fontSize: 40 }}>
                                {`${requestDetails.contactFirstName} ${requestDetails.contactLastName}`}
                              </Typography>
                              <Typography  fontWeight="bold" sx={{ fontSize: 24 }}>
                                {requestDetails.titlePosition}
                              </Typography>
                            <Typography sx={{ fontSize: 22 }}>
                              {requestDetails.contactEmail}
                            </Typography>
                            <Typography sx={{ fontSize: 22 }}>
                              {requestDetails.contactPhone}
                            </Typography>
                            </Grid>
                          </Grid>
                        </Grid>
                        <Grid item style={{ backgroundColor: 'black', marginTop: 20, padding: 20, borderRadius: 15 }}>
                          <Grid item container >
                            <ModeOfTravelIcon sx={{ fontSize: 100, color: 'white' }} />
                            <Grid item flexDirection='column'>
                              <Typography fontWeight="bold" sx={{ fontSize: 24, color: 'white' }}>
                                {requestDetails.address}
                              </Typography>
                              <Grid item container spacing={2} >
                                <Grid item>
                                  <Typography fontWeight="bold" sx={{ fontSize: 24, color: 'white' }}>{requestDetails.city}</Typography>
                                </Grid>
                                <Grid item>
                                  <Typography fontWeight="bold" sx={{ fontSize: 24, color: 'white' }}>{requestDetails.provinceState}</Typography>
                                </Grid>
                              </Grid>
                              <Typography fontWeight="bold" sx={{ fontSize: 24, color: 'white' }}>
                                {requestDetails.postalZip.toUpperCase()}
                              </Typography>
                            <Typography sx={{ fontSize: 22, color: 'white' }}>
                              www.{requestDetails.url}
                            </Typography>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item container justifyContent="center" style={{ border: '2px solid lightGrey', marginTop: 30, borderRadius: 15 }} >
                <Tooltip title={isFavourite ? "Remove from Favourites" : "Add to Favourites"}>
                  <IconButton
                    aria-label="Favourite"
                    size="large"
                    onClick={addFavourites}
                  >
                    {isFavourite ? <StarIcon style={{ color: blue[900], fontSize: 35 }} /> : <StarBorderIcon style={{ fontSize: 35, color: blue[900] }} />}
                  </IconButton>
                </Tooltip>
                <Tooltip title="Add Reminder">
                  <IconButton
                    aria-label="Reminder"
                    size="large"
                    onClick={() => console.log("add a reminder here")}
                  >
                    <NotificationAddIcon style={{ fontSize: 35, color: orange[500] }} />
                  </IconButton>
                </Tooltip>
                <Tooltip title="Send to my email">
                  <IconButton
                    aria-label="Reminder"
                    size="large"
                    onClick={() => console.log("add a reminder here")}
                  >
                    <SendIcon style={{ color: pink.A200, fontSize: 35 }} />
                  </IconButton>
                </Tooltip>
              </Grid>
              <Grid item container style={{ border: "2px solid lightGrey", padding: 20, borderRadius: 15, marginTop: 30 }}>
                <Grid item xs={12} >
                  <Typography variant="h5" textAlign="left" >
                    Comments
                    </Typography>
                </Grid>
                <Grid item xs={10} style={{ paddingTop: '10px', border: "0px solid black" }}>
                  <TextField
                    fullWidth
                    id="fullWidth"
                    value={noteInput}
                    onChange={(event) => setNoteInput(event.target.value)}
                    size="small"
                    placeholder="Add Comment"
                  />
                </Grid>
                <Grid item container xs={2} justifyContent='center' alignItems='center' style={{ paddingTop: '10px', border: "0px solid black" }}>
                  <Button
                    variant='contained'
                    onClick={() => generateNote(noteInput, requestDetails.id)}
                  >
                    <AddIcon />
                  </Button>
                </Grid>
                {
                  notes.length > 0 &&
                  notes.sort((a, b) => b.date - a.date).map((note) => {
                    return (
                      <React.Fragment key={note.id}>
                        <Grid key={note.id} container style={{ marginTop: "15px", borderBottom: '1px solid grey' }} >
                          <Grid item xs={10} >
                            <Typography textAlign="left">
                              {note.note}
                            </Typography>
                          </Grid>
                          <Grid container item xs={2} alignItems="flex-end" style={{ border: '0px solid red' }}>
                            <Typography >
                              {new Date((Number(note.date))).toLocaleDateString()}
                              {' '}
                              {new Date((Number(note.date))).toLocaleTimeString()}
                            </Typography>
                          </Grid>
                        </Grid>
                      </React.Fragment>
                    )
                  })
                }
              </Grid>
            </Paper>
          </Grid>
      }

    </>
  );
}
