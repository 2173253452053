
import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom'
import { red, purple, pink, yellow, blue, lightGreen, orange, lime, amber, lightBlue } from '@mui/material/colors';
import { DataGrid, GridToolbar } from '@mui/x-data-grid';

import { Typography } from '@mui/material';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import InputAdornment from '@mui/material/InputAdornment';
import SearchIcon from '@mui/icons-material/Search';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import RequestPageIcon from '@mui/icons-material/RequestPage';
import BarChartIcon from '@mui/icons-material/BarChart';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';

import { listAllVendors } from '../../awsFuncCalls'


export default function ViewAllLeads() {
    const navigate = useNavigate()

    const [contractRequests, setContractRequests] = useState([])
    const [vendors, setVendors] = useState([])
    const [filteredRequests, setFilteredRequests] = useState([])
    const [sortFilter, setSortFilter] = useState('expirationDate')
    const [searchValue, setSearchValue] = useState('')


    const columns = [
        {
            field: 'companyName',
            headerName: 'Company',
            minWidth: 150,
            flex: 1,
            headerClassName: 'super-app-theme--header',
        },
        {
            field: 'industry',
            headerName: 'Industry',
            minWidth: 120,
            flex: 1,
            headerClassName: 'super-app-theme--header',
            valueGetter: (params) => {
                return params.row.industry.title
            },
        },
        {
            field: 'firstName',
            headerName: 'First Name',
            minWidth: 150,
            flex: 1,
            headerClassName: 'super-app-theme--header',
        },
        {
            field: 'lastName',
            headerName: 'Last Name',
            minWidth: 150,
            flex: 1,
            headerClassName: 'super-app-theme--header',
        },
        {
            field: 'email',
            headerName: 'Email',
            minWidth: 150,
            flex: 1,
            headerClassName: 'super-app-theme--header',
        },
        {
            field: 'postalCode',
            headerName: 'Postal Code',
            minWidth: 150,
            flex: 1,
            headerClassName: 'super-app-theme--header',
        },
        {
            field: 'subscriptionStatus',
            headerName: 'Subscription Status',
            minWidth: 150,
            flex: 1,
            headerClassName: 'super-app-theme--header',
        },
        {
            field: 'subscriptionLevel',
            headerName: 'Level',
            minWidth: 150,
            flex: 1,
            headerClassName: 'super-app-theme--header',
        },
        // {
        //   field: 'city',
        //   headerName: 'City',
        //   minWidth: 75,
        //   flex: 1,
        //   headerClassName: 'super-app-theme--header',
        // },
        // {
        //   field: 'provinceState',
        //   headerName: 'Province',
        //   minWidth: 75,
        //   flex: 1,
        //   headerClassName: 'super-app-theme--header',
        // },
        // {
        //   field: 'subIndustry',
        //   headerName: 'Service',
        //   minWidth: 120,
        //   flex: 1,
        //   headerClassName: 'super-app-theme--header',
        //   valueGetter: (params) => {
        //     return params.row.subIndustry.title
        //   },
        // },
        // {
        //   field: 'requestNotes',
        //   headerName: 'Service Note',
        //   minWidth: 200,
        //   sortable: false,
        //   filterable: false,
        //   flex: 1,
        //   headerClassName: 'super-app-theme--header',
        //   valueGetter: (params) => {
        //     return `${params.row.requestNotes.slice(0, 25)}...`
        //   },
        // },
        // {
        //   field: 'goLiveDate',
        //   headerName: 'Go Live Date',
        //   minWidth: 150,
        //   flex: 1,
        //   headerClassName: 'super-app-theme--header',
        //   valueGetter: (params) => {
        //     return new Date((Number(params.row.goLiveDate))).toDateString()
        //   },
        // },
        // {
        //   field: 'expirationDate',
        //   headerName: 'Expiry Date',
        //   minWidth: 150,
        //   flex: 1,
        //   headerClassName: 'super-app-theme--header',
        //   valueGetter: (params) => {
        //     return new Date((Number(params.row.expirationDate))).toDateString()
        //   },
        // },
        // {
        //   field: 'id',
        //   headerName: 'More',
        //   minWidth: 100,
        //   sortable: false,
        //   filterable: false,
        //   flex: 1,
        //   headerClassName: 'super-app-theme--header',
        //   renderCell: (params) => {
        //     return (
        //       <Button style={{ color: 'black' }} onClick={() => navigate(`${params.row.id}`,
        //         {
        //           state: {
        //             row: params.row
        //           }
        //         }
        //       )}
        //       >
        //         ...
        //       </Button>
        //     )
        //   },
        // }
    ];

    useEffect(() => {
        const asyncFunction = async () => {
            try {
                const res = await listAllVendors()
                const tempArr = res.allVendors.data.listVendors.items.sort((a, b) => a.companyName - b.companyName)
                setVendors(tempArr)
                setFilteredRequests(tempArr)
                return true
            } catch (error) {
                console.log(error)
                return error;
            }
        }
        asyncFunction();
    }, []);

    const requestSearch = (e) => {
        setSearchValue(e.target.value)
        const filteredRows = vendors.filter((row) => {
            return (
                row.companyName.toLowerCase().includes(e.target.value.toLowerCase())
            )

        });
        setFilteredRequests(filteredRows);
    };

    useEffect(() => {
        runFilter()
    }, [sortFilter])

    const runFilter = () => {
        let filtered
        if (sortFilter === "expirationDate") {
            filtered = contractRequests.sort((a, b) => {
                return a[sortFilter] - b[sortFilter]
            })
        }
        if (sortFilter === "companyName" || sortFilter === "city") {
            filtered = contractRequests.sort((a, b) => {
                return (a[sortFilter]).localeCompare(b[sortFilter]);
            })
        }
        return filtered
    }



    return (
        <>
            <Grid container style={{ marginBottom: 30, backgroundColor: 'white' }}>
                <Grid item container lg={.75} md={1} sm={12} style={{ alignItems: 'center', justifyContent: 'flex-start', flexDirection: "row" }}>
                    <BarChartIcon sx={{ color: purple.A400 }} />
                    <ChevronRightIcon />
                </Grid>
                <Grid item container lg={2.5} md={3} sm={12} style={{ alignItems: 'center', justifyContent: 'flex-start' }}>
                    <Typography variant="h6">Admin - View All Vendors</Typography>
                </Grid>
                <Grid item md={7} sm={8}>
                    <TextField
                        fullWidth
                        // label="Search"
                        id="fullWidth"
                        InputProps={{
                            startAdornment: <InputAdornment position="start"><SearchIcon /></InputAdornment>,
                        }}
                        value={searchValue}
                        onChange={(e) => requestSearch(e)}
                        size="small"
                        placeholder="Quick Search"
                    />
                </Grid>
            </Grid>
            <Paper elevation={1} >
                <Box
                    sx={{
                        height: "100%",
                        width: '100%',
                        '& .super-app-theme--header': {
                            backgroundColor: pink[50],
                        },
                    }}
                >
                    <DataGrid
                        autoHeight
                        rows={filteredRequests}
                        columns={columns}
                        initialState={{
                            pagination: {
                                paginationModel: {
                                    pageSize: 5,
                                },
                            },
                        }}
                        pageSizeOptions={[5, 20, 100]}
                        disableRowSelectionOnClick
                        slots={{ toolbar: GridToolbar }}
                        slotProps={{
                            toolbar: {
                                printOptions: { disableToolbarButton: true },
                                csvOptions: { disableToolbarButton: true },
                            },
                        }}
                        GridColDef={false}
                    />
                </Box>
            </Paper>
        </>
    );
}