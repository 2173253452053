import React from 'react';
import { Container, Grid, Typography } from '@mui/material';


import { Link } from 'react-router-dom'

const Footer = () => {
  return (
    <Grid container sx={{ mt: 5, py: 2, backgroundColor: '#262626', color: 'white' , textAlign: 'center',  }}>
      {/* <Grid container spacing={3}> */}

      {/* Company Section */}
      <Grid item container xs={12} md={3} flexDirection="column" alignItems="center" >
        <Typography variant="h6" gutterBottom>
          Company
          </Typography>
        <nav>
          {/* <Link key={'/login'} to={`/login`} style={{ textDecoration: 'none', color: 'black' }}>
            <MenuItem key={'/login1'} onClick={handleClose}>
              <Avatar key={'/login2'}><IconResolver key={"/login3"} iconName="LockOpen" /></Avatar><Typography textAlign="center">Login</Typography>
            </MenuItem>
          </Link> */}
          <Link key={'/company'} to={`/company`} style={{ textDecoration: 'none', color: 'white' }}>
            Company
            </Link>
          <br />
          <Link key={'/resources'} to={`/resources`} style={{ textDecoration: 'none', color: 'white' }}>
            Resources
            </Link>
          <br />
          <Link key={'/contact'} to={`/contact`} style={{ textDecoration: 'none', color: 'white' }}>
            Contact Us
            </Link>
        </nav>
      </Grid>

      {/* Useful Links Section */}
      <Grid item container xs={12} md={3} flexDirection="column" alignItems="center" >
        <Typography variant="h6" gutterBottom>
          Useful Links
          </Typography>
        <nav>
          <Link key={'/login'} to={`/login`} style={{ textDecoration: 'none', color: 'white' }}>
            Login
            </Link>
          <br />
          <Link key={'/register'} to={`/register`} style={{ textDecoration: 'none', color: 'white' }}>
            Register
            </Link>
          <br />
          <Link key={'/demo'} to={`/contact`} style={{ textDecoration: 'none', color: 'white' }}>
            Request A Demo
            </Link>
          <br />
        </nav>
      </Grid>

      {/* Social Section */}
      <Grid item container xs={12} md={3} flexDirection="column" alignItems="center" >
        <Typography variant="h6" gutterBottom>
          Social
          </Typography>
        <nav>
          <Link key={'/facebook'} to={`/contact`} style={{ textDecoration: 'none', color: 'white' }}>
            Facebook
            </Link>
          <br />
          <Link key={'/linkedin'} to={`/contact`} style={{ textDecoration: 'none', color: 'white' }}>
            LinkedIn
            </Link>
          <br />
          {/* <Link href="#" color="inherit" variant="body2">
            Twitter
            </Link>
          <br />
          <Link href="#" color="inherit" variant="body2">
            YouTube
            </Link> */}
        </nav>
      </Grid>


      {/* Teerms Section */}
      <Grid item container xs={12} md={3} flexDirection="column" alignItems="center" >
        <Typography variant="h6" gutterBottom>
          Other
          </Typography>
        <nav>
          <Link key={'/terms'} to={`/terms`} style={{ textDecoration: 'none', color: 'white' }}>
            Terms and Conditions
            </Link>
          <br />
          <Link key={'/privacy'} to={`/privacy`} style={{ textDecoration: 'none', color: 'white' }}>
            Privacy Policy
            </Link>
          <br />
          <a href={'https://clearbit.com'} target='_blank' rel='noreferrer' style={{ textDecoration: 'none', color: 'white' }}>Logos provided by Clearbit</a>
          <br />
          {/* <Link href="#" color="inherit" variant="body2">
            Twitter
            </Link>
          <br />
          <Link href="#" color="inherit" variant="body2">
            YouTube
            </Link> */}
        </nav>
      </Grid>
      {/* </Grid> */}
    </Grid>
  );
};

export default Footer;
