// import { useState, useEffect } from 'react';
// import { useNavigate } from 'react-router-dom'
// import { red, purple, pink, yellow, blue, lightGreen, orange, lime, amber, lightBlue } from '@mui/material/colors';
// import { DataGrid, GridToolbar } from '@mui/x-data-grid';

// import { Amplify, API, graphqlOperation, Auth } from 'aws-amplify'
// import { Typography } from '@mui/material';
// import TextField from '@mui/material/TextField';
// import Grid from '@mui/material/Grid';
// import Box from '@mui/material/Box';
// import Tooltip from '@mui/material/Tooltip';
// import Avatar from '@mui/material/Avatar';
// import InputAdornment from '@mui/material/InputAdornment';
// import SearchIcon from '@mui/icons-material/Search';
// import ChevronRightIcon from '@mui/icons-material/ChevronRight';
// import RequestPageIcon from '@mui/icons-material/RequestPage';
// import CleaningServicesIcon from '@mui/icons-material/CleaningServices';
// import Button from '@mui/material/Button';
// import Paper from '@mui/material/Paper';
// import Link from '@mui/material/Link';

// import LoadingImage from '../components/loading/LoadingImage';
// import Plans from './Plans'

// import { listAllContractRequests } from '../awsFuncCalls'


// export default function ContractRequests(props) {
//   const navigate = useNavigate()

//   const [contractRequests, setContractRequests] = useState([])
//   const [filteredRequests, setFilteredRequests] = useState([])
//   const [sortFilter, setSortFilter] = useState('expirationDate')
//   const [searchValue, setSearchValue] = useState('')
//   const [needToSubscribe, setNeedToSubscribe] = useState(false)
//   const [needToUpgrade, setNeedToUpgrade] = useState(false)
//   const [isLoading, setIsLoading] = useState(true)


//   const columns = [
//     {
//       field: 'companyName',
//       headerName: 'Company',
//       minWidth: 150,
//       flex: 1.5,
//       headerClassName: 'super-app-theme--header',
//       renderCell: (params) => {

//         return (
//           params.row.companyName &&
//           <>
//           <img
//             src={`https://logo.clearbit.com/${params.row.url}?size=200}`}
//             alt={params.row.url}
//             style={{ maxHeight: 45, marginRight: 13 }}
//           />
//           <Typography fontWeight="bold">
//             {` ${params.row.companyName}`}
//           </Typography>
//           </>
//         );
//       },
//     },
//     {
//       field: 'city',
//       headerName: 'City',
//       minWidth: 75,
//       flex: .7,
//       headerClassName: 'super-app-theme--header',
//       renderCell: (params) => {

//         return (
//           params.row.city &&
//           <Typography >
//             {params.row.city}
//           </Typography>
//         );
//       },
//     },
//     {
//       field: 'provinceState',
//       headerName: 'Province',
//       minWidth: 40,
//       flex: .3,
//       headerClassName: 'super-app-theme--header',
//       renderCell: (params) => {

//         return (
//           params.row.provinceState &&
//           <Typography >
//             {params.row.provinceState.toUpperCase()}
//           </Typography>
//         );
//       },
//     },
//     {
//       field: 'subIndustry',
//       headerName: 'Service',
//       minWidth: 120,
//       flex: .7,
//       headerClassName: 'super-app-theme--header',
//       renderCell: (params) => {
//         return (
//           <Typography >
//             {params.row.subIndustry.title}
//           </Typography>
//         );
//       },
//     },
//     {
//       field: 'requestNotes',
//       headerName: 'Service Note',
//       minWidth: 200,
//       sortable: false,
//       filterable: false,
//       flex: 1,
//       headerClassName: 'super-app-theme--header',
//       renderCell: (params) => {
//         return (
//           <Tooltip title={params.row.requestNotes}>
//           <Typography >
//             {`${params.row.requestNotes.slice(0, 25)}...`}
//           </Typography>
//           </Tooltip>
//         );
//       },
//     },
//     {
//       field: 'goLiveDate',
//       headerName: 'Go Live Date',
//       minWidth: 150,
//       flex: .7,
//       headerClassName: 'super-app-theme--header',
//       renderCell: (params) => {
//         return (
//           <Typography >
//             {new Date((Number(params.row.goLiveDate))).toDateString()}
//           </Typography>
//         );
//       },
//     },
//     {
//       field: 'expirationDate',
//       headerName: 'Expiry Date',
//       minWidth: 150,
//       flex: .7,
//       headerClassName: 'super-app-theme--header',
//       renderCell: (params) => {
//         return (
//           <Typography >
//             {new Date((Number(params.row.expirationDate))).toDateString()}
//           </Typography>
//         );
//       },
//     },
//   ];


//   useEffect(() => {
//     const serviceRequestFetch = async () => {
//       const cognitoUserInfo = await Auth.currentAuthenticatedUser({ bypassCache: true })
//       if (props.userData.group === "Admin" || cognitoUserInfo.attributes['custom:entitlement'] === 'prod_PVq5ieA6tvgtWI') {
//         try {
//           const res = await listAllContractRequests(cognitoUserInfo.attributes['custom:industry'])
//           const tempArr = res.allRequests.data.listRequests.items.sort((a, b) => a.expirationDate - b.expirationDate)
//           setContractRequests(tempArr)
//           setFilteredRequests(tempArr)
//           setTimeout(() => {
//             setIsLoading(false)
//           }, 1500);
//           return true
//         } catch (error) {
//           console.log(error)
//           return error;
//         }
//       }
//       if (cognitoUserInfo.attributes['custom:entitlement'] === '0154a494-c49b-4e1f-9935-59b1d1f6a791' || cognitoUserInfo.attributes['custom:entitlement'] === 'prod_PVq5ieA6tvgtWI' || !cognitoUserInfo.attributes['custom:entitlement']) {
//         setNeedToSubscribe(true)
//         setTimeout(() => {
//           setIsLoading(false)
//         }, 1500);
//         return true
//       }
//       setNeedToUpgrade(true)
//       setTimeout(() => {
//         setIsLoading(false)
//       }, 1500);
//       return true
//     }

//     serviceRequestFetch();
//   }, []);

//   const requestSearch = (e) => {
//     setSearchValue(e.target.value)
//     const filteredRows = contractRequests.filter((row) => {
//       return (
//         row.companyName.toLowerCase().includes(e.target.value.toLowerCase()) ||
//         row.city.toLowerCase().includes(e.target.value.toLowerCase()) ||
//         row.subIndustry.title.toLowerCase().includes(e.target.value.toLowerCase())
//       )

//     });
//     setFilteredRequests(filteredRows);
//   };

//   useEffect(() => {
//     runFilter()
//   }, [sortFilter])

//   const runFilter = () => {
//     let filtered
//     if (sortFilter === "expirationDate") {
//       filtered = contractRequests.sort((a, b) => {
//         return a[sortFilter] - b[sortFilter]
//       })
//       // console.log(filtered, sortFilter, "in runFilter")
//     }
//     if (sortFilter === "companyName" || sortFilter === "city") {
//       filtered = contractRequests.sort((a, b) => {
//         return (a[sortFilter]).localeCompare(b[sortFilter]);
//       })
//       // console.log(filtered, sortFilter, "in runFilter")
//     }
//     return filtered
//   }

//   const handleRowClick = (params) => {
//     navigate(`${params.row.id}`,
//     {
//       state: {
//         row: params.row
//       }
//     })
//   }

//   return (
//     <>
//       {
//         isLoading ?
//           <LoadingImage dataPage />
//           :
//           needToSubscribe ?
//             <Plans userData={props.userData} />
//             :
//             needToUpgrade ?
//               <Grid
//                 container
//                 flexDirection='column'
//                 justifyContent='center'
//                 alignItems='center'
//               >
//                 <Typography variant='body2' textAlign='center' style={{ marginTop: 20, margin: 40 }}>
//                   You need to upgrade in order to view this section.
//               </Typography>
//                 <Button component={Link} href="https://billing.stripe.com/p/login/test_8wM042erD6kfc24144" target="_blank" variant='outlined' style={{ marginTop: 20 }}>
//                   Modify Subscription *
//             </Button>
//                 <Typography variant='body2' textAlign='center' style={{ marginTop: 20, margin: 40 }}>
//                   * By clicking above, you will be forwarded to a webpage hosted by Stripe, our payment processor.  Follow the onscreen instructions to access your customer portal.
//               </Typography>
//               </Grid>
//               :
//               <>
//                 <Grid container style={{ marginBottom: 30, backgroundColor: 'white' }}>
//                   <Grid item container lg={.75} md={1} sm={12} style={{ alignItems: 'center', justifyContent: 'flex-start', flexDirection: "row" }}>
//                     <RequestPageIcon sx={{ color: orange[500] }} />
//                     <ChevronRightIcon />
//                   </Grid>
//                   <Grid item container lg={2.5} md={3} sm={12} style={{ alignItems: 'center', justifyContent: 'flex-start' }}>
//                     <Typography variant="h6">Contract Requests</Typography>
//                   </Grid>
//                   <Grid item md={7} sm={8}>
//                     <TextField
//                       fullWidth
//                       // label="Search"
//                       id="fullWidth"
//                       InputProps={{
//                         startAdornment: <InputAdornment position="start"><SearchIcon /></InputAdornment>,
//                       }}
//                       value={searchValue}
//                       onChange={(e) => requestSearch(e)}
//                       size="small"
//                       placeholder="Quick Search"
//                     />
//                   </Grid>
//                 </Grid>
//                 <Paper elevation={1} >
//                   <Box
//                     sx={{
//                       height: "100%",
//                       width: '100%',
//                       '& .super-app-theme--header': {
//                         backgroundColor: '#EBEBEB',
//                         // fontWeight: '#EBEBEB'
//                       },
//                     }}
//                   >
//                   <DataGrid
//                     autoHeight
//                     disableClipboardPaste
//                     onCellKeyDown={handleRowClick}
//                     onRowClick={handleRowClick}
//                     onCellClick={handleRowClick}
//                     disableRowSelectionOnClick
//                     disableClipboardPaste
//                     onClick={handleRowClick}
//                     rows={filteredRequests}
//                     columns={columns}
//                     initialState={{
//                       pagination: {
//                         paginationModel: {
//                           pageSize: 10,
//                         },
//                       },
//                     }}
//                     pageSizeOptions={[10, 20, 100]}
//                     disableRowSelectionOnClick
//                     GridColDef={false}
//                     disableColumnMenu
//                     density="comfortable"
//                   />
//                   </Box>
//                 </Paper>
//               </>
//       }
//     </>
//   );
// }


import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom'
import { red, purple, pink, yellow, blue, lightGreen, orange, lime, amber, lightBlue } from '@mui/material/colors';
import { DataGrid, GridToolbar } from '@mui/x-data-grid';

import { Amplify, API, graphqlOperation, Auth } from 'aws-amplify'
import { Typography } from '@mui/material';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Tooltip from '@mui/material/Tooltip';
import Avatar from '@mui/material/Avatar';
import InputAdornment from '@mui/material/InputAdornment';
import SearchIcon from '@mui/icons-material/Search';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import RequestPageIcon from '@mui/icons-material/RequestPage';
import CleaningServicesIcon from '@mui/icons-material/CleaningServices';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import Link from '@mui/material/Link';

import LoadingImage from '../components/loading/LoadingImage';
import Plans from './Plans'

import { listAllServiceRequests, listAllSubIndustries } from '../awsFuncCalls'
import * as mutations from '../graphql/mutations';


export default function ContractRequests(props) {
  const navigate = useNavigate()

  const [contractRequests, setContractRequests] = useState([])
  const [filteredRequests, setFilteredRequests] = useState([])
  const [sortFilter, setSortFilter] = useState('expirationDate')
  const [searchValue, setSearchValue] = useState('')
  const [needToSubscribe, setNeedToSubscribe] = useState(false)
  const [needToUpgrade, setNeedToUpgrade] = useState(false)
  const [isLoading, setIsLoading] = useState(true)
  const [subIndustries, setSubIndustries] = useState([])

  const columns = [
    {
      field: 'log',
      headerName: '',
      minWidth: 50,
      flex: .7,
      headerClassName: 'super-app-theme--header',
      renderCell: (params) => {

        return (
          params.row.url &&
          <Grid item container justifyContent='center'>
            <img
              src={`https://logo.clearbit.com/${params.row.url}?size=200}`}
              alt={params.row.url}
              style={{ maxHeight: 45, marginRight: 13 }}
            />
          </Grid>
        );
      },
    },
    {
      field: 'companyName',
      headerName: 'Company',
      minWidth: 150,
      flex: 1.5,
      headerClassName: 'super-app-theme--header',
      renderCell: (params) => {

        return (
          params.row.companyName &&
          <>
            {/* <img
              src={`https://logo.clearbit.com/${params.row.url}?size=200}`}
              alt={params.row.url}
              style={{ maxHeight: 45, marginRight: 13 }}
            /> */}
            <Typography fontWeight="bold">
              {` ${params.row.companyName}`}
            </Typography>
          </>
        );
      },
    },
    {
      field: 'city',
      headerName: 'City',
      minWidth: 75,
      flex: .7,
      headerClassName: 'super-app-theme--header',
      renderCell: (params) => {

        return (
          params.row.city &&
          <Typography >
            {params.row.city}
          </Typography>
        );
      },
    },
    {
      field: 'provinceState',
      headerName: 'Province',
      minWidth: 40,
      flex: .3,
      headerClassName: 'super-app-theme--header',
      renderCell: (params) => {

        return (
          params.row.provinceState &&
          <Typography >
            {params.row.provinceState.toUpperCase()}
          </Typography>
        );
      },
    },
    {
      field: 'subIndustry',
      headerName: 'Service',
      minWidth: 120,
      flex: .7,
      headerClassName: 'super-app-theme--header',
      renderCell: (params) => {
        let subTitle = "testtt"
        subIndustries.map((obj) => {
          if (obj.id === params.row.requestSubIndustryId) {
            subTitle = obj.title
          }
          return subTitle
        })
        return (
          (<Typography>{subTitle}</Typography>)
        )
      },
    },
    {
      field: 'requestNotes',
      headerName: 'Service Note',
      minWidth: 200,
      sortable: false,
      filterable: false,
      flex: 1,
      headerClassName: 'super-app-theme--header',
      renderCell: (params) => {
        return (
          <Tooltip title={params.row.requestNotes}>
            <Typography >
              {`${params.row.requestNotes.slice(0, 25)}...`}
            </Typography>
          </Tooltip>
        );
      },
    },
    {
      field: 'goLiveDate',
      headerName: 'Go Live Date',
      minWidth: 150,
      flex: .7,
      headerClassName: 'super-app-theme--header',
      renderCell: (params) => {
        return (
          <Typography >
            {new Date((Number(params.row.goLiveDate))).toDateString()}
          </Typography>
        );
      },
    },
    {
      field: 'expirationDate',
      headerName: 'Expiry Date',
      minWidth: 150,
      flex: .7,
      headerClassName: 'super-app-theme--header',
      renderCell: (params) => {
        return (
          <Typography >
            {new Date((Number(params.row.expirationDate))).toDateString()}
          </Typography>
        );
      },
    }
  ];

  useEffect(() => {
    const subsIndustryFetch = async () => {
      try {
        const subIndResults = await listAllSubIndustries()
        setSubIndustries(subIndResults.subIndustries)
      }
      catch (e) {
        console.log(e, 'error fetching Sub Industry')
        return ({ error: e })
      }
      return true
    }
    subsIndustryFetch()
  }, [])


  useEffect(() => {
    const serviceRequestFetch = async () => {
      // Handle form submission logic here
      const cognitoUserInfo = await Auth.currentAuthenticatedUser({ bypassCache: true })
      try {
        const requestResults = await API.graphql({
          query: mutations.listRequestsForUser,
          variables: {
            usreId: cognitoUserInfo.username,
            fetchItem: 'contract'
          }
        })
        const temp = await JSON.parse(requestResults.data.listRequestsForUser)
        if (temp.needToSubscribe) {
          setNeedToSubscribe(temp.needToSubscribe)
          setTimeout(() => {
            setIsLoading(false)
          }, 1500);
          return true
        }
        if (temp.needToUpgrade) {
          setNeedToUpgrade(temp.needToUpgrade)
          setTimeout(() => {
            setIsLoading(false)
          }, 1500);
          return true
        }
        setContractRequests(temp.data)
        setFilteredRequests(temp.data)
        setNeedToUpgrade(temp.needToUpgrade)
        setTimeout(() => {
          setIsLoading(false)
        }, 1500);
      } catch (e) {
        console.log(e)
      }
      return true
    }

    serviceRequestFetch();
  }, []);

  const requestSearch = (e) => {
    setSearchValue(e.target.value)
    const filteredRows = contractRequests.filter((row) => {
      return (
        row.companyName.toLowerCase().includes(e.target.value.toLowerCase()) // ||
        // row.subIndustry.title.toLowerCase().includes(e.target.value.toLowerCase())
      )

    });
    setFilteredRequests(filteredRows);
  };

  useEffect(() => {
    runFilter()
  }, [sortFilter])

  const runFilter = () => {
    let filtered
    if (sortFilter === "expirationDate") {
      filtered = contractRequests.sort((a, b) => {
        return a[sortFilter] - b[sortFilter]
      })
    }
    if (sortFilter === "companyName" || sortFilter === "city") {
      filtered = contractRequests.sort((a, b) => {
        return (a[sortFilter]).localeCompare(b[sortFilter]);
      })
    }
    return filtered
  }

  const handleRowClick = (params) => {
    navigate(`${params.row.id}`,
      {
        state: {
          row: params.row
        }
      })
  }

  return (
    <>
      {
        isLoading ?
          <LoadingImage dataPage />
          :
          needToSubscribe ?
            <Plans userData={props.userData} />
            :
            needToUpgrade ?
              <Grid
                container
                flexDirection='column'
                justifyContent='center'
                alignItems='center'
              >
                <Typography variant='body2' textAlign='center' style={{ marginTop: 20, margin: 40 }}>
                  Upgrade to gain access to these great features.
            </Typography>
                <Button component={Link} href="https://billing.stripe.com/p/login/test_8wM042erD6kfc24144" target="_blank" variant='outlined' style={{ marginTop: 20 }}>
                  Modify Subscription *
          </Button>
                <Typography variant='body2' textAlign='center' style={{ marginTop: 20, margin: 40 }}>
                  * By clicking above, you will be forwarded to a webpage hosted by Stripe, our payment processor.  Follow the onscreen instructions to access your customer portal.
            </Typography>
              </Grid>
              :
              <>
                <Grid container style={{ marginBottom: 30, backgroundColor: 'white' }}>
                  <Grid item container lg={.75} md={1} sm={12} style={{ alignItems: 'center', justifyContent: 'flex-start', flexDirection: "row" }}>
                    <RequestPageIcon sx={{ color: orange[500] }} />
                    <ChevronRightIcon />
                  </Grid>
                  <Grid item container lg={2.5} md={3} sm={12} style={{ alignItems: 'center', justifyContent: 'flex-start' }}>
                    <Typography variant="h6">Contract Requests</Typography>
                  </Grid>
                  <Grid item md={7} sm={8}>
                    <TextField
                      fullWidth
                      // label="Search"
                      id="fullWidth"
                      InputProps={{
                        startAdornment: <InputAdornment position="start"><SearchIcon /></InputAdornment>,
                      }}
                      value={searchValue}
                      onChange={(e) => requestSearch(e)}
                      size="small"
                      placeholder="Quick Search"
                    />
                  </Grid>
                </Grid>
                <Paper elevation={1} >
                  <Box
                    sx={{
                      height: "100%",
                      width: '100%',
                      '& .super-app-theme--header': {
                        backgroundColor: '#EBEBEB',
                        // fontWeight: '#EBEBEB'
                      },
                    }}
                  >
                    <DataGrid
                      autoHeight
                      disableClipboardPaste
                      onCellKeyDown={handleRowClick}
                      onRowClick={handleRowClick}
                      onCellClick={handleRowClick}
                      disableRowSelectionOnClick
                      disableClipboardPaste
                      onClick={handleRowClick}
                      rows={filteredRequests}
                      columns={columns}
                      initialState={{
                        pagination: {
                          paginationModel: {
                            pageSize: 10,
                          },
                        },
                      }}
                      pageSizeOptions={[10, 20, 100]}
                      disableRowSelectionOnClick
                      GridColDef={false}
                      disableColumnMenu
                      density="comfortable"
                    />
                  </Box>
                </Paper>
              </>
      }
    </>
  );
}