import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom'
import { red, purple, pink, yellow, blue, lightGreen, orange, lime, amber, lightBlue } from '@mui/material/colors';
import { DataGrid, GridToolbarContainer, GridToolbarExport } from '@mui/x-data-grid';

import { Amplify, API, graphqlOperation, Auth } from 'aws-amplify'
import { Typography } from '@mui/material';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Tooltip from '@mui/material/Tooltip';
import Avatar from '@mui/material/Avatar';
import InputAdornment from '@mui/material/InputAdornment';
import SearchIcon from '@mui/icons-material/Search';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import DescriptionIcon from '@mui/icons-material/Description';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import Link from '@mui/material/Link';

import LoadingImage from '../components/loading/LoadingImage';
import Plans from './Plans'

import { listAllServiceRequests, listAllSubIndustries } from '../awsFuncCalls'
import * as mutations from '../graphql/mutations';



function CustomToolbar() {
    return (
        <GridToolbarContainer>
            <GridToolbarExport printOptions={{ disableToolbarButton: true }} />
        </GridToolbarContainer>
    );
}

export default function ContractRequests(props) {
    const navigate = useNavigate()

    const [contractRequests, setContractRequests] = useState([])
    const [filteredRequests, setFilteredRequests] = useState([])
    const [sortFilter, setSortFilter] = useState('expirationDate')
    const [searchValue, setSearchValue] = useState('')
    const [needToSubscribe, setNeedToSubscribe] = useState(false)
    const [needToUpgrade, setNeedToUpgrade] = useState(false)
    const [isLoading, setIsLoading] = useState(true)
    const [subIndustries, setSubIndustries] = useState([])

    const columns = [
        {
            field: 'companyName',
            headerName: 'Company',
            minWidth: 150,
            flex: 1,
            headerClassName: 'super-app-theme--header',
            renderCell: (params) => {

                return (
                    params.row.companyName &&
                    <>
                        {/* <img
              src={`https://logo.clearbit.com/${params.row.url}?size=200}`}
              alt={params.row.url}
              style={{ maxHeight: 45, marginRight: 13 }}
            /> */}
                        <Typography fontWeight="bold">
                            {` ${params.row.companyName}`}
                        </Typography>
                    </>
                );
            },
        },
        {
            field: 'address',
            headerName: 'Address',
            minWidth: 75,
            flex: 1,
            headerClassName: 'super-app-theme--header',
            renderCell: (params) => {

                return (
                    params.row.address &&
                    <Typography >
                        {params.row.address}
                    </Typography>
                );
            },
        },
        {
            field: 'city',
            headerName: 'City',
            minWidth: 75,
            flex: .7,
            headerClassName: 'super-app-theme--header',
            renderCell: (params) => {

                return (
                    params.row.city &&
                    <Typography >
                        {params.row.city}
                    </Typography>
                );
            },
        },
        {
            field: 'provinceState',
            headerName: 'Province',
            minWidth: 40,
            flex: .3,
            headerClassName: 'super-app-theme--header',
            renderCell: (params) => {

                return (
                    params.row.provinceState &&
                    <Typography >
                        {params.row.provinceState.toUpperCase()}
                    </Typography>
                );
            },
        },
        {
            field: 'contactPhone',
            headerName: 'Phone',
            minWidth: 40,
            flex: .5,
            headerClassName: 'super-app-theme--header',
            renderCell: (params) => {

                return (
                    params.row.contactPhone &&
                    <Typography >
                        {params.row.contactPhone}
                    </Typography>
                );
            },
        },
        {
            field: 'contactFirstName',
            headerName: 'First Name',
            minWidth: 140,
            flex: .5,
            headerClassName: 'super-app-theme--header',
            renderCell: (params) => {

                return (
                    params.row.contactFirstName !== "NA" &&
                    <Typography >
                        {params.row.contactFirstName}
                    </Typography>
                );
            },
        },
        {
            field: 'contactLastName',
            headerName: 'Last Name',
            minWidth: 140,
            flex: .5,
            headerClassName: 'super-app-theme--header',
            renderCell: (params) => {

                return (
                    params.row.contactLastName !== "NA" &&
                    <Typography style={{ marginLeft: ".3em" }}>
                        {params.row.contactLastName}
                    </Typography>
                );
            },
        },
        {
            field: 'url',
            headerName: 'URL',
            minWidth: 100,
            flex: 1,
            headerClassName: 'super-app-theme--header',
            renderCell: (params) => {
                return (
                    params.row.url &&
                    <Typography>
                        www.{params.row.url}
                    </Typography>
                );
            },
        },
    ];

    useEffect(() => {
        const subsIndustryFetch = async () => {
            try {
                const subIndResults = await listAllSubIndustries()
                setSubIndustries(subIndResults.subIndustries)
            }
            catch (e) {
                console.log(e, 'error fetching Sub Industry')
                return ({ error: e })
            }
            return true
        }
        subsIndustryFetch()
    }, [])


    useEffect(() => {
        const leadFetch = async () => {
            // Handle form submission logic here
            const cognitoUserInfo = await Auth.currentAuthenticatedUser({ bypassCache: true })
            try {
                const requestResults = await API.graphql({
                    query: mutations.listRequestsForUser,
                    variables: {
                        usreId: cognitoUserInfo.username,
                        fetchItem: 'lead'
                    }
                })
                const temp = await JSON.parse(requestResults.data.listRequestsForUser)
                if (temp.needToSubscribe) {
                    setNeedToSubscribe(temp.needToSubscribe)
                    setTimeout(() => {
                        setIsLoading(false)
                    }, 1500);
                    return true
                }
                if (temp.needToUpgrade) {
                    setNeedToUpgrade(temp.needToUpgrade)
                    setTimeout(() => {
                        setIsLoading(false)
                    }, 1500);
                    return true
                }
                setContractRequests(temp.data)
                setFilteredRequests(temp.data)
                setNeedToUpgrade(temp.needToUpgrade)
                setTimeout(() => {
                    setIsLoading(false)
                }, 1500);
            } catch (e) {
                console.log(e)
            }
            // if (props.userData.group === "Admin" || cognitoUserInfo.attributes['custom:entitlement'] === 'prod_PVq4BW2HYAHuhS' || cognitoUserInfo.attributes['custom:entitlement'] === 'prod_PVq5ieA6tvgtWI') {
            //   try {
            //     const res = await listAllServiceRequests(cognitoUserInfo.attributes['custom:industry'])
            //     const tempArr = res.allRequests.data.listRequests.items.sort((a, b) => a.expirationDate - b.expirationDate)
            //     setContractRequests(tempArr)
            //     setFilteredRequests(tempArr)
            //     setTimeout(() => {
            //       setIsLoading(false)
            //     }, 1500);
            //     return true
            //   } catch (error) {
            //     return error;
            //   }
            // }
            // if (cognitoUserInfo.attributes['custom:entitlement'] === '0154a494-c49b-4e1f-9935-59b1d1f6a791' || !cognitoUserInfo.attributes['custom:entitlement']) {
            //   setNeedToSubscribe(true)
            //   setTimeout(() => {
            //     setIsLoading(false)
            //   }, 1500);
            //   return true
            // }
            // setNeedToUpgrade(true)
            // setTimeout(() => {
            //   setIsLoading(false)
            // }, 1500);
            return true
        }

        leadFetch();
    }, []);


    // const handleSubmit = async (e) => {
    //   e.preventDefault();
    //   // Handle form submission logic here
    //   try {
    //     const sendHelp = await API.graphql({
    //       query: mutations.listRequestsForUser,
    //       variables: {
    //         usreId: props.userData.id
    //       }
    //     })
    //     console.log(sendHelp)
    //   } catch (e) {
    //     console.log(e)
    //   }
    // };

    const requestSearch = (e) => {
        setSearchValue(e.target.value)
        const filteredRows = contractRequests.filter((row) => {
            return (
                row.companyName.toLowerCase().includes(e.target.value.toLowerCase()) ||
                row.subIndustry.title.toLowerCase().includes(e.target.value.toLowerCase())
            )

        });
        setFilteredRequests(filteredRows);
    };

    useEffect(() => {
        runFilter()
    }, [sortFilter])

    const runFilter = () => {
        let filtered
        if (sortFilter === "expirationDate") {
            filtered = contractRequests.sort((a, b) => {
                return a[sortFilter] - b[sortFilter]
            })
        }
        if (sortFilter === "companyName" || sortFilter === "city") {
            filtered = contractRequests.sort((a, b) => {
                return (a[sortFilter]).localeCompare(b[sortFilter]);
            })
        }
        return filtered
    }

    const handleRowClick = (params) => {
        navigate(`${params.row.id}`,
            {
                state: {
                    row: params.row
                }
            })
    }

    return (
        <>
            {
                isLoading ?
                    <LoadingImage dataPage />
                    :
                    needToSubscribe ?
                        <Plans userData={props.userData} />
                        :
                        needToUpgrade ?
                            <Grid
                                container
                                flexDirection='column'
                                justifyContent='center'
                                alignItems='center'
                            >
                                <Typography variant='body2' textAlign='center' style={{ marginTop: 20, margin: 40 }}>
                                    Upgrade to gain access to these great features.
            </Typography>
                                <Button component={Link} href="https://billing.stripe.com/p/login/test_8wM042erD6kfc24144" target="_blank" variant='outlined' style={{ marginTop: 20 }}>
                                    Modify Subscription *
          </Button>
                                <Typography variant='body2' textAlign='center' style={{ marginTop: 20, margin: 40 }}>
                                    * By clicking above, you will be forwarded to a webpage hosted by Stripe, our payment processor.  Follow the onscreen instructions to access your customer portal.
            </Typography>
                            </Grid>
                            :
                            <>
                                <Grid container style={{ marginBottom: 30, backgroundColor: 'white' }}>
                                    <Grid item container lg={.75} md={1} sm={12} style={{ alignItems: 'center', justifyContent: 'flex-start', flexDirection: "row" }}>
                                        <DescriptionIcon sx={{ color: lightGreen.A400 }} />
                                        <ChevronRightIcon />
                                    </Grid>
                                    <Grid item container lg={2.5} md={3} sm={12} style={{ alignItems: 'center', justifyContent: 'flex-start' }}>
                                        <Typography variant="h6">Leads</Typography>
                                    </Grid>
                                    <Grid item md={7} sm={8}>
                                        <TextField
                                            fullWidth
                                            // label="Search"
                                            id="fullWidth"
                                            InputProps={{
                                                startAdornment: <InputAdornment position="start"><SearchIcon /></InputAdornment>,
                                            }}
                                            value={searchValue}
                                            onChange={(e) => requestSearch(e)}
                                            size="small"
                                            placeholder="Quick Search"
                                        />
                                    </Grid>
                                </Grid>
                                <Paper elevation={1} >
                                    <Box
                                        sx={{
                                            height: "100%",
                                            width: '100%',
                                            '& .super-app-theme--header': {
                                                backgroundColor: '#EBEBEB',
                                                // fontWeight: '#EBEBEB'
                                            },
                                        }}
                                    >
                                        <DataGrid
                                            autoHeight
                                            disableRowSelectionOnClick
                                            disableClipboardPaste
                                            rows={filteredRequests}
                                            columns={columns}
                                            initialState={{
                                                pagination: {
                                                    paginationModel: {
                                                        pageSize: 100,
                                                    },
                                                },
                                            }}
                                            slots={{
                                                toolbar: CustomToolbar,
                                            }}
                                            pageSizeOptions={[25, 50, 100]}
                                            disableRowSelectionOnClick
                                            GridColDef={false}
                                            disableColumnMenu
                                            density="comfortable"

                                        />
                                    </Box>
                                </Paper>
                            </>
            }
        </>
    );
}