
import * as React from "react";
import {
    Navigate,
    Outlet
} from "react-router-dom";

export default function AdminRoutes({
    userData,
    children
}) {
    const data = false
    if (userData.group !== "Admin") {
        return <Navigate to={'/login'}
            replace
        />
    }
    return children || <Outlet />;
}