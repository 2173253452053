import React from 'react';
import { Container, Typography, Paper } from '@mui/material';

function Company() {
  return (
    <Container sx={{ marginTop: 4 }}>
      <Paper elevation={3} sx={{ padding: 4 }}>
        <Typography variant="h4" gutterBottom>
          About Us
        </Typography>
        <Typography variant="h6" paragraph>
          With over a decades worth of experience in multiple commercial industries.
        </Typography>
        <Typography variant="h6" paragraph>
          Our mission at SBS is to help existing business owners within various service/vendor
          industries, obtain pertinent data (through the use of our company's very own Savvy 
          Business Strategies.)
        </Typography>
        <Typography variant="h6" paragraph>
          Connecting businesses to their desired end goal has never been so easy.  Let us take care of the 
          outsourcing process for you.
        </Typography>
      </Paper>
    </Container>
  );
}

export default Company;
