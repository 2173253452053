import React from 'react';
import { Accordion, AccordionSummary, AccordionDetails, Typography, Grid, Paper, Button } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import FAQ from '../components/FAQ/FAQ'

const FrequentlyAsked = () => {

  return (
    <div>
      <Grid container justifyContent="center" style={{ marginBottom: 50, marginTop: 30 }}>
        <Typography variant="h3" fontWeight="bold">
          Frequently Asked Questions
        </Typography>
      </Grid>
      <Grid container>
        <Grid item container md={4} justifyContent='center' style={{marginTop: 80}}>
          <Typography variant='h4' style={{fontFamily: 'Snell Roundhand'}}>
            Any questions?  We're here to help...
          </Typography>
        </Grid>
        <Grid item container md={8}>
          <FAQ />
        </Grid>
      </Grid>
    </div>
  );
};

export default FrequentlyAsked;
