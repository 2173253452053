import React from 'react';
import { Container, Typography, Paper } from '@mui/material';

function Resources() {
  return (
    <Container sx={{ marginTop: 4 }}>
      <Paper elevation={3} sx={{ padding: 4, textAlign: 'center' }}>
        <Typography variant="h4" gutterBottom>
          Coming Soon
        </Typography>
        <Typography variant="body1" paragraph>
          We are working on something amazing! Stay tuned for updates.
        </Typography>
        {/* <img src="/under-construction.png" alt="Under Construction" style={{ maxWidth: '100%', height: 'auto' }} /> */}
      </Paper>
    </Container>
  );
}

export default Resources;
