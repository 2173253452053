import { useState, useEffect } from 'react';
import "./UploadLeads.css";
import Papa from "papaparse";
import TextField from '@mui/material/TextField';
import { styled } from '@mui/material/styles';
import Button from '@mui/material/Button';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow, { tableRowClasses } from "@mui/material/TableRow";
import Paper from '@mui/material/Paper';
import FileUploadIcon from '@mui/icons-material/FileUpload';
import FileDownloadDoneIcon from '@mui/icons-material/FileDownloadDone';
import Grid from '@mui/material/Grid';
import { uploadRequests, listAllIndustries, listAllSubIndustries } from '../../awsFuncCalls'

const VisuallyHiddenInput = styled('input')({
    clip: 'rect(0 0 0 0)',
    clipPath: 'inset(50%)',
    height: 1,
    overflow: 'hidden',
    position: 'absolute',
    bottom: 0,
    left: 0,
    whiteSpace: 'nowrap',
    width: 1,
});

// const StyledTableRow = styled(TableRow)(({ theme }) => ({
//     [`&.${tableRowClasses.root}`]: {
//         height: "200px",
//     },
//     // "&:nth-of-type(odd)": {
//     //   backgroundColor: theme.palette.action.hover
//     // },
// }));


function FileUpload() {
    // State to store parsed data
    const [parsedData, setParsedData] = useState([]);
    // State to store table Column name
    const [tableRows, setTableRows] = useState([]);
    // State to store the values
    const [values, setValues] = useState([]);
    const [completed, setCompleted] = useState([])
    const [industries, setIndustries] = useState([])
    const [subIndustries, setSubIndustries] = useState([])



    useEffect(() => {
        const asyncFunction = async () => {
            try {
                const ind = await listAllIndustries()
                const tempArr = ind.industries.sort((a, b) => a.title - b.title)
                setIndustries(tempArr)
                return true
            } catch (error) {
                console.log(error)
                return error;
            }
        }
        asyncFunction();
    }, []);

    const changeHandler = (event) => {
        // Passing file data (event.target.files[0]) to parse using Papa.parse
        Papa.parse(event.target.files[0], {
            header: true,
            skipEmptyLines: true,
            complete: (results) => {
                const rowsArray = [];
                const valuesArray = [];

                // Iterating data to get column name and their values
                results.data.map((d) => {
                    rowsArray.push(Object.keys(d));
                    valuesArray.push(Object.values(d));
                    return true
                });

                // Parsed Data Response in array format
                setParsedData(results.data);

                // Filtered Column Names
                // setTableRows(rowsArray[0]);
                setTableRows([
                    "Company Name",
                    "Address",
                    "Address 2",
                    "City",
                    "Province/State",
                    "Postal/Zip",
                    "URL",
                    "First Name",
                    "Last Name",
                    "Title/Position",
                    "Contact Phone",
                    "Contact Email",
                    "Building Type",
                    "Request Type",
                    "Industry",
                    "SubIndstry",
                    "Request Notes",
                    "Go Live Date",
                    "Expiration Date",
                ])

                // Filtered Values
                setValues(valuesArray);
            },
        });
    };

    const handleUpload = async (val, index) => {
        const uploadResponse = await uploadRequests(val)
        if (uploadResponse.uploadRes.data.createRequest.id) {
            setCompleted([...completed, index])
        }
    }

    const handleIndustryChange = (event, index, i) => {
        const arrayToBeChanged = [...values]
        arrayToBeChanged[index].splice(i, 1, event.target.value.id)
        setValues(arrayToBeChanged)

        setSubIndustries(event.target.value.subIndustry.items)
    };
    const handleSubIndustryChange = (event, index, i) => {
        const arrayToBeChanged = [...values]
        arrayToBeChanged[index].splice(i, 1, event.target.value.id)
        setValues(arrayToBeChanged)
    };

    return (
        <div>
            {/* File Uploader */}

            <Button component="label" variant="contained" startIcon={<CloudUploadIcon />}>
                Choose File
                <VisuallyHiddenInput
                    type="file"
                    name="file"
                    onChange={(e) => changeHandler(e)}
                    accept=".csv"
                />
            </Button>
            <br />
            <br />

            <TableContainer component={Paper}>
                <Table stickyHeader sx={{ minWidth: 750 }} size="small" aria-labelledby="tableTitle">
                    <TableHead>
                        <TableRow>
                            {tableRows.map((rows, index) => {
                                return <TableCell sx={{ fontSize: 16 }} key={index} >{rows}</TableCell>
                            })}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {values.map((obj, index) => {
                            return (
                                <TableRow
                                    key={index}

                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                >

                                    {obj.map((val, i) => {
                                        if (val.trim() === "Industry") {
                                            return (
                                                <TableCell
                                                    key={val.trim() + i}
                                                    sx={{ fontSize: 14 }}
                                                    align="right"
                                                >
                                                    <Select
                                                        labelId="demo-simple-select-label"
                                                        id="demo-simple-select"
                                                        value={""}
                                                        label="Industry"
                                                        onChange={(e) => handleIndustryChange(e, index, i)}
                                                        style={{ width: 100 }}
                                                    >
                                                        {
                                                            industries.map((ind) => {
                                                                return (
                                                                    <MenuItem key={ind.id} name={'industry'} value={ind}>{ind.title}</MenuItem>
                                                                )
                                                            })
                                                        }
                                                    </Select>
                                                </TableCell>
                                            )
                                        }
                                        if (val.trim() === "subIndustry") {
                                            return (
                                                <TableCell
                                                    sx={{ fontSize: 14 }}
                                                    key={i}
                                                    align="right"
                                                >
                                                    <Select
                                                        labelId="demo-simple-select-label"
                                                        id="demo-simple-select"
                                                        value={""}
                                                        label="subInd"
                                                        onChange={(e) => handleSubIndustryChange(e, index, i)}
                                                        style={{ width: 100 }}
                                                    >
                                                        {
                                                            subIndustries.map((subInd) => {
                                                                return (
                                                                    <MenuItem key={subInd.id} name='subIndustry' value={subInd}>{subInd.title}</MenuItem>
                                                                )
                                                            })
                                                        }
                                                    </Select>
                                                </TableCell>
                                            )
                                        }
                                        return (
                                            <TableCell
                                                sx={{ fontSize: 14 }}
                                                key={i}
                                                align="right"
                                            >
                                                <div style={{ minWidth: '160px' }}>
                                                    {val}
                                                </div>
                                            </TableCell>
                                        )
                                    })}
                                    <TableCell
                                        sx={{ fontSize: 14 }}
                                        key={index}
                                        align="center"
                                    >
                                        {
                                            completed.includes(index) ?
                                                <FileDownloadDoneIcon />
                                                :

                                                <Button
                                                    // disabled={obj[14] === "Industry" || obj[15] === "subIndstry"}
                                                    onClick={() => handleUpload(obj, index)}
                                                    variant="contained" endIcon={<FileUploadIcon />}>
                                                    Upload
                                                </Button>
                                        }
                                    </TableCell>
                                    <TableCell
                                        sx={{ fontSize: 14 }}
                                        key={index}
                                        align="center"
                                    >
                                        <Grid item container justifyContent='center'>
                                            <img
                                                src={`https://logo.clearbit.com/${obj[6]}?size=200}`}
                                                onError="this.src='https://upload.wikimedia.org/wikipedia/commons/c/ce/Example_image.png'"
                                                alt={obj[6]}
                                                style={{ maxHeight: 45, marginRight: 13 }}
                                            />
                                        </Grid>
                                    </TableCell>
                                </TableRow>
                            );
                        })}
                    </TableBody>
                </Table>
            </TableContainer>
        </div>
    );
}

export default FileUpload;