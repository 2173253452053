import React, { useState, useEffect } from 'react'
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import { Link } from 'react-router-dom'
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Button from '@mui/material/Button';
import Footer from '../components/header/Footer';
import { listAllIndustries } from '../awsFuncCalls'

const tiers = [
    {
        title: 'Free',
        price: '0',
        description: [
            'Access to blogs',
            'Sales resources',
            'Other items',
            'Free Consultation',
        ],
        buttonText: 'Sign up for free',
        buttonVariant: 'outlined',
    },
    {
        title: 'Basic',
        subheader: 'Most popular',
        price: '295',
        description: [
            'Service Requests',
            'Reminders',
            'Latest data',
            'Private Notes',
        ],
        buttonText: 'Get started',
        buttonVariant: 'contained',
    },
    {
        title: 'Pro',
        price: '395',
        description: [
            'Everything from Basic',
            'Contract requests',
            'Featured on our partner page'
        ],
        buttonText: 'Contact us',
        buttonVariant: 'outlined',
    },
];

const cardDetails = [
    // {
    //     image: "https://images.unsplash.com/photo-1521791136064-7986c2920216?q=80&w=2938&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
    //     title: "Contract/Tender Dates",
    //     paragraph: "Portal access to Tender Dates, Contract Expiration Dates, RFP/RFQ and inquiries as to when a potential client might be actively searching for services",
    //     button: "Get Started",
    //     url: "/register"
    // },    
    {
        image: "https://images.unsplash.com/photo-1521791136064-7986c2920216?q=80&w=2938&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
        title: "Job-Ready Connected Data",
        paragraph: "A “Deal Sourcing” platform with multi-source verified, complete, connected, and current data, spun into a web of insight that connects high ticket company profiles with all the information you need, so you see more possibilities and win more often",
        button: "Get Started",
        url: "/register"
    },
    {
        image: "https://images.unsplash.com/photo-1486312338219-ce68d2c6f44d?q=80&w=2944&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
        title: "Strike When the Time is Right!",
        paragraph: "Build a proprietary advantage by tracking high-priority targets and automatically monitor for signs of progress and transaction/tender readiness to know when to reach out and how to stay ahead of the competition",
        button: "Get Started",
        url: "/register"
    },
    {
        image: "https://images.unsplash.com/photo-1551836022-d5d88e9218df?q=80&w=2940&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
        title: "Build your existing Book of Business to it’s highest potential",
        paragraph: "Move from deal to deal with connected data that gives you instant clarity, actionable intent, and delivers inquiries as to when a potential client might be actively searching for services. Filter through, categorize, and stay organized",
        button: "Get Started",
        url: "/register"
    },
]

function HeroPage(props) {

    const [industries, setIndustries] = useState([])
    // const { post } = props;

    useEffect(() => {
        const fetchIndData = async() => {
            try {
                const ind = await listAllIndustries()
                const tempArr = ind.industries.sort((a, b) => a.title - b.title)
                setIndustries(tempArr)
                return true
            } catch (error) {
                console.log(error.data, 'data on hero page')
                console.log(error, 'error on hero page')
                return error;
            }
        }
        fetchIndData()
    }, [])

    return (
        <>
            <Paper
                sx={{
                    height: { xs: '28em', md: '40em' },
                    width: '100%',
                    position: 'relative',
                    backgroundColor: 'grey.800',
                    color: '#fff',
                    mb: 5,
                    backgroundSize: 'cover',
                    backgroundRepeat: 'no-repeat',
                    backgroundPosition: 'center',
                    backgroundImage: `url(https://images.unsplash.com/photo-1600522907071-85ea3bd6f625?q=80&w=2835&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D)`,
                }}
            >
                <Box
                    sx={{
                        position: 'absolute',
                        top: 0,
                        bottom: 0,
                        right: 0,
                        left: 0,
                        backgroundColor: 'rgba(0, 0, 0,.5)',
                    }}
                />
                <Grid container >
                    <Grid item xs={12} md={6} textAlign='center'>
                        <Box
                            sx={{
                                marginLeft: 10,
                                marginRight: 10,
                                position: 'absolute',
                                top: {xs :'10%', md: '40%'},
                                bottom: 0,
                                left: 0,
                            }}
                        >
                            <Typography sx={{ typography: { lg: 'h2', md: 'h3', xs: 'h5' }, marginBottom: 10}} color="inherit" gutterBottom >
                                {"Helping Building Managers and Vendors stay Ahead of the Game in Real Time"}
                            </Typography>
                        </Box>
                    </Grid>
                </Grid>
            </Paper>

            <Grid container justifyContent="space-around">
                {
                    cardDetails.map(obj => {
                        return (
                            <Grid key={obj.title} item component={Card} textAlign='center' sx={{ marginBottom: 5, width: { xs: 300, md: 500 }, borderRadius: 5, display: 'flex', flexDirection: 'column', justifyContent: 'space-between', }}  >
                                <CardMedia
                                    style={{
                                        height: 240,
                                        backgroundColor: 'rgba(0, 0, 0,.5)'
                                    }}
                                    image={obj.image}
                                    title={obj.title}
                                />
                                <CardContent>
                                    <Typography gutterBottom variant="h5" component="div">
                                        {obj.title}
                                    </Typography>
                                    <Typography variant="body2" color="text.secondary">
                                        {obj.paragraph}
                                    </Typography>
                                </CardContent>
                                <Grid item component={CardActions} >
                                    <Link to={obj.url} style={{ textDecoration: 'none' }}>
                                        <Button onClick={() => console.log(obj.url)} size="small"><Typography sx={{ fontWeight: 'bold' }}>{obj.button}</Typography></Button>
                                    </Link>
                                </Grid>
                            </Grid>
                        )
                    })
                }
            </Grid>
            <Grid container style={{ marginTop: 50 }}>
                <Grid item xs={12} textAlign='center' sx={{ my: 1, mx: { xs: 5, md: 20 } }}
                >
                    <Typography variant="h3">
                    Get started now for instant access.
                    </Typography>
                </Grid>
                <Grid item xs={12} textAlign='center' sx={{ my: 1, mx: { xs: 5, md: 20 } }}
                >
                    <Typography variant="h3">
                    Cancel at any time.
                    </Typography>
                </Grid>
            </Grid>
            <Grid container justifyContent='center' style={{ marginTop: 50 }}>
                <Grid container justifyContent='center' sx={{ my: 5 }}>
                    <Link key={'/industries'} to={`/industries`} style={{ textDecoration: 'none', color: 'black' }}>
                        <Button variant="contained" >
                            <Typography variant='h6' color="white">
                                See All Industries
                            </Typography>
                        </Button>
                    </Link>
                </Grid>
                <Grid container >
                    {
                        industries.map(obj => {
                            return (
                                <Grid key={obj.id} item container xs={12} flexDirection="column" alignItems='center' justifyContent='center' >
                                    <Grid item container justifyContent="center" >
                                        <Typography sx={{ textAlign: 'center', color: 'black', fontWeight: 'bold', fontSize: '2rem' }}>{obj.title}</Typography>
                                    </Grid>
                                </ Grid>
                            )
                        })
                    }
                </Grid>
            </Grid>

            <Footer />
        </>
    );
}

export default HeroPage;