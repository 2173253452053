
import * as React from "react";
import {
  Outlet
} from "react-router-dom";
import MainHeader from './components/header/MainHeader'

export default function PublicRoutes(props) {

    return (
      <div >
        {/* <DashboardLayout /> */}
        <MainHeader userLoggedIn={props.userLoggedIn} />
        <Outlet />
      </div>
    )
  }