import { useState, useEffect } from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Link from '@mui/material/Link';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import { createTheme } from '@mui/material/styles';
import { useNavigate } from "react-router-dom";
import { Auth } from 'aws-amplify';
import LoadingImage from '../components/loading/LoadingImage';


function Copyright(props) {
  return (
    <Typography variant="body2" color="text.secondary" align="center" {...props}>
      {'Copyright © '}
      <Link color="inherit" href="https://savvybusinessstrategies.com/">
        savvybusinessstrategies.com
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

// TODO remove, this demo shouldn't need to reset the theme.

const defaultTheme = createTheme();

export default function LoginPage(props) {

  // const cognitoUserInfo = await Auth.currentAuthenticatedUser({ bypassCache: true })


  const navigate = useNavigate()

  // if(props.userLoggedIn) {
  //   navigate('/dashboard')
  // }

  const [username, setUsername] = useState('')
  const [password, setPassword] = useState('')
  const [newPassword, setNewPassword] = useState('')
  const [verificationCode, setVerificationCode] = useState()
  const [step, setStep] = useState(1)
  const [forgotPassword, setForgotPassword] = useState(false)
  const [loading, setLoading] = useState(false)


  useEffect(() => {
    if (props.userLoggedIn) {
      navigate('/dashboard')
    }
  }, [props.userLoggedIn])



  // Send confirmation code to user's email
  async function forgotPasswordStep1(username) {
    try {
      const data = await Auth.forgotPassword(username);
      setStep(2)
    } catch (err) {
      console.log(err);
    }
  }

  // Collect confirmation code and new password
  async function forgotPasswordSubmit(username, code, newPassword) {
    try {
      const data = await Auth.forgotPasswordSubmit(username, code, newPassword);
      setStep(1)
      setForgotPassword(false)
    } catch (err) {
      console.log(err);
    }
  }

  const login = () => {
    setLoading(true)
    try {
      props.signIn(username, password)
    }
    catch (error) {
      console.log(error, "e in login page")
    }
    
    setTimeout(() => {
      navigate('/dashboard')
      setLoading(false)
    }, 1000);
  }


  return (
    // <ThemeProvider theme={defaultTheme}>
    loading ?
    <LoadingImage />
    :
      <Grid container component="main" sx={{ height: '100vh' }}>
        <CssBaseline />
        <Grid
          item
          xs={false}
          sm={4}
          md={7}
          sx={{
            backgroundImage: 'url(https://source.unsplash.com/random/?business)',
            backgroundRepeat: 'no-repeat',
            backgroundColor: (t) =>
              t.palette.mode === 'light' ? t.palette.grey[50] : t.palette.grey[900],
            backgroundSize: 'cover',
            backgroundPosition: 'center',
          }}
        />
        {
          forgotPassword ?
            <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
              <Box
                sx={{
                  my: 8,
                  mx: 4,
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                }}
              >
                <Avatar sx={{ m: 1, bgcolor: 'primary.main' }}>
                  <LockOutlinedIcon />
                </Avatar>
                <Typography component="h1" variant="h5">
                  Forgot Password
                </Typography>
                <Box component="form" noValidate sx={{ mt: 1 }}>
                  {
                    step === 1 &&
                    <>
                      <TextField
                        value={username}
                        onChange={(e) => setUsername(e.target.value)}
                        margin="normal"
                        required
                        fullWidth
                        name="email"
                        label="Email Address"
                        // type="password"
                        id="email"
                        autoComplete="email"
                      />
                      <Button
                        onClick={() => forgotPasswordStep1(username)}
                        disabled={username.length === 0}
                        // type="submit"
                        fullWidth
                        variant="contained"
                        sx={{ mt: 3, mb: 2 }}
                      >
                        Reset Password
                        </Button>
                    </>
                  }
                  {
                    step === 2 &&
                    <>
                    <Typography>
                      Check your email for your verification code
                    </Typography>
                      <TextField
                        value={verificationCode}
                        onChange={(e) => setVerificationCode(e.target.value)}
                        margin="normal"
                        required
                        fullWidth
                        name="password"
                        label="Code"
                        type="number"
                        id="password"
                        autoComplete="current-password"
                      />
                      <TextField
                        value={newPassword}
                        onChange={(e) => setNewPassword(e.target.value)}
                        margin="normal"
                        required
                        fullWidth
                        name="password"
                        label="New Password"
                        type="password"
                        id="password"
                        autoComplete="current-password"
                      />
                      <Button
                        onClick={() => forgotPasswordSubmit(username, verificationCode, newPassword )}
                        disabled={username.length === 0}
                        // type="submit"
                        fullWidth
                        variant="contained"
                        sx={{ mt: 3, mb: 2 }}
                      >
                        Submit New Password
                        </Button>
                    </>
                  }
                  <Grid container>
                    <Grid item xs>
                      <Button
                        // component="button"
                        variant="body2"
                        onClick={() => setForgotPassword(false)}
                      >
                        Login?
                      </Button>
                    </Grid>
                    <Grid item xs>
                      <Button
                        // component="button"
                        variant="body2"
                        onClick={() => navigate('/register')}
                      >
                        Don't have an account? Sign Up
                      </Button>
                    </Grid>
                  </Grid>
                  <Copyright sx={{ mt: 5 }} />
                </Box>
              </Box>
            </Grid>
            :
            <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
              <Box
                sx={{
                  my: 8,
                  mx: 4,
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                }}
              >
                <Avatar sx={{ m: 1, bgcolor: 'primary.main' }}>
                  <LockOutlinedIcon />
                </Avatar>
                <Typography component="h1" variant="h5">
                  Login
                </Typography>
                <Box component="form" noValidate sx={{ mt: 1 }} >
                  <TextField
                    value={username}
                    onChange={(e) => setUsername(e.target.value)}
                    margin="normal"
                    required
                    fullWidth
                    // id="email"
                    label="Email Address"
                    name="email"
                    autoComplete="email"
                    autoFocus
                  />
                  <TextField
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    margin="normal"
                    required
                    fullWidth
                    name="password"
                    label="Password"
                    type="password"
                    id="password"
                    autoComplete="current-password"
                  />
                  {/* <FormControlLabel
                    control={<Checkbox value="remember" color="primary" />}
                    label="Remember me"
                  /> */}
                  <Button
                    onClick={() => login()}
                    // type="submit"
                    fullWidth
                    variant="contained"
                    sx={{ mt: 3, mb: 2 }}
                  >
                    Login
                  </Button>
                  <Grid container>
                    <Grid item xs>
                      <Button
                        // component="button"
                        variant="body2"
                        onClick={() => setForgotPassword(true)}
                      >
                        Forgot password?
                      </Button>
                    </Grid>
                    <Grid item>
                      <Button
                        // component="button"
                        variant="body2"
                        onClick={() => navigate('/register')}
                      >
                        Don't have an account? Sign Up
                    </Button>
                    </Grid>
                  </Grid>
                  <Copyright sx={{ mt: 5 }} />
                </Box>
              </Box>
            </Grid>
        }
      </Grid>
    // </ThemeProvider>
  );
}