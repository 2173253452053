import React, { useState, useEffect } from 'react'
import { Typography, Container, List, ListItem, ListItemText } from '@mui/material';
import { listAllIndustries } from '../awsFuncCalls'

// const services = [
//   { id: 1, name: 'Web Development', description: 'We create stunning and responsive websites tailored to your needs.' },
//   { id: 2, name: 'Graphic Design', description: 'Our designers craft visually appealing graphics for your brand.' },
//   { id: 3, name: 'Digital Marketing', description: 'We help you reach your target audience and boost your online presence.' },
//   { id: 4, name: 'Content Writing', description: 'Our writers produce high-quality content to engage your audience.' },
//   { id: 5, name: 'SEO Optimization', description: 'We optimize your website to improve its visibility on search engines.' },
// ];

const IndustryList = () => {


  const [industries, setIndustries] = useState([])

  useEffect(() => {
    const fetchIndData = async() => {
        try {
            const ind = await listAllIndustries()
            const tempArr = ind.industries.sort((a, b) => a.title - b.title)
            setIndustries(tempArr)
            // const subInd = await listAllSubIndustries()
            // console.log(subInd, "listsubindusties")
            // const tempArr2 = subInd.subIndustries.sort((a, b) => a.title - b.title)
            // setSubIndustries(tempArr2)
            // setFilteredRequests(tempArr)
            return true
        } catch (error) {
            console.log(error)
            return error;
        }
    }
    fetchIndData()
}, [])
  return (
    <Container sx={{ marginTop: 4 }}>
      <Typography variant="h4" component="h1" align="center" gutterBottom>
        Our Services
      </Typography>
      <List>
        {industries.map((industry) => (
          <ListItem key={industry.id} sx={{ marginBottom: 2 }}>
            <ListItemText
              primary={industry.title}
              // secondary={industry.description}
            />
          </ListItem>
        ))}
      </List>
    </Container>
  );
};

export default IndustryList;
