import React, { useState, useEffect } from 'react'
import { useLocation, useNavigate } from 'react-router-dom';

// @mui
import { Grid, Button, Container, Typography, TextField, Divider } from '@mui/material';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import AddIcon from '@mui/icons-material/Add';
import NotificationAddIcon from '@mui/icons-material/NotificationAdd';
import InputAdornment from '@mui/material/InputAdornment';
import StarIcon from '@mui/icons-material/Star';
import StarBorderIcon from '@mui/icons-material/StarBorder';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { lightBlue } from '@mui/material/colors';
import Fab from '@mui/material/Fab';
import EditIcon from '@mui/icons-material/Edit';
import SaveIcon from '@mui/icons-material/Save';
import OutlinedInput from '@mui/material/OutlinedInput';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

import { Auth, Hub } from 'aws-amplify';

// AWS Fuction Calls
import { updateLeadField, saveNotes, listRequestNotes, getServiceRequests, getVendorFavorites, addToFavourite, removeFavourites } from '../../awsFuncCalls'

// ----------------------------------------------------------------------

const keyTitle = [
  {
    key: "companyName",
    value: "Company Name",
    editable: true
  },
  {
    key: "address",
    value: "Address",
    editable: true
  },
  {
    key: "address2",
    value: "Address2",
    editable: true
  },
  {
    key: "city",
    value: "City",
    editable: true
  },
  {
    key: "provinceState",
    value: "Province",
    editable: true
  },
  {
    key: "postalZip",
    value: "Postal Code",
    editable: true
  },
  {
    key: "contactFirstName",
    value: "Contact First Name",
    editable: true
  },
  {
    key: "contactLastName",
    value: "Contact Last Name",
    editable: true
  },
  {
    key: "titlePosition",
    value: "Title",
    editable: true
  },
  {
    key: "contactPhone",
    value: "Phone Number",
    editable: true
  },
  {
    key: "contactEmail",
    value: "Email Address",
    editable: true
  },
  {
    key: "url",
    value: "Website Address",
    editable: true
  },
  {
    key: "buildingType",
    value: "Building Type",
    editable: true
  },
  {
    key: "requestType",
    value: "Request Type",
    editable: false,
    valueGetter: (params) => {
      return params.title
    }
  },
  {
    key: "industry",
    value: "Industry",
    editable: false,
    valueGetter: (params) => {
      return params.title
    }
  },
  {
    key: "subIndustry",
    value: "Sub Industry",
    editable: false,
    valueGetter: (params) => {
      return params.title
    }
  },
  {
    key: "uploadDate",
    value: "Upload Date",
    editable: false,
    valueGetter: (params) => {
      return new Date((Number(params))).toDateString()
    },
  },
  {
    key: "goLiveDate",
    value: "Go Live",
    editable: false,
    valueGetter: (params) => {
      return new Date((Number(params))).toDateString()
    },
  },
  {
    key: "expirationDate",
    value: "Expiration",
    editable: false,
    valueGetter: (params) => {
      return new Date((Number(params))).toDateString()
    },
  },
  {
    key: "requestNotes",
    value: "Request Notes",
    editable: true
  },
]

export default function EditLead() {

  const navigate = useNavigate()

  const [noteInput, setNoteInput] = useState('')
  const [notes, setNotes] = useState([])
  const [requestDetails, setRequestDetails] = useState([])
  const [favourite, setFavourite] = useState([])
  const [isFavourite, setIsFavourite] = useState(false)
  const [loading, setLoading] = useState(true)
  const [newValue, setNewValue] = useState('')
  const [oldValue, setOldValue] = useState(null)
  const [open, setOpen] = useState(false);
  const [loadingDialog, setLoadingDialog] = useState(true)


  const { state } = useLocation();
  const requestId = state?.row.id;

  useEffect(() => {
    const asyncFunction = async () => {

      const cognitoUserInfo = await Auth.currentAuthenticatedUser({ bypassCache: true })
      if (cognitoUserInfo) {
        try {
          const requestDetails = await getServiceRequests(requestId)
          // console.log(requestDetails)
          setRequestDetails(requestDetails.allRequests.data.getRequest)
          setLoading(false)
          return true
        } catch (error) {
          console.log(error)
          return error;
        }
      }
      else {
        return true
      }
    }
    asyncFunction();

  }, [])

  const handleClickOpen = (id, key, title, value) => {
    setOldValue({
      id,
      key,
      title,
      value
    })
    setOpen(true);
    setLoadingDialog(false)
    // console.log("A->", a, "B->", b, "C->", c)
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleDetailChange = async () => {
    // setRequestDetails({...requestDetails, key: newValue})
    // console.log(oldValue.id, oldValue.key, oldValue.title, newValue)
    const updateResponse = await updateLeadField(oldValue.id, oldValue.key, newValue)
    if(updateResponse){
      setRequestDetails(updateResponse.response.data.updateRequest)
      handleClose()
    } else {
      handleClose()
    }
    
  }

  return (
    <>
      {
        loading ?
          <div>loading</div>
          :
          <Container >
            <Grid container style={{ marginBottom: 20 }}>
              <Grid item xs={2}>
                <IconButton
                  aria-label="Favourite"
                  size="large"
                  onClick={() => navigate(-1)}
                >
                  <ArrowBackIcon fontSize='large' />
                </IconButton>
              </Grid>
              <Grid item xs={10}>
                <Typography variant="h4">Edit Lead Request</Typography>
              </Grid>
            </Grid>
            <Grid container spacing={2} alignItems="center">
              {
                keyTitle.map(object => {
                  return (
                    <>
                      <Grid item xs={12} md={4} >
                        <Typography >{object.value}</Typography>
                      </Grid>
                      <Grid item container xs={12} md={8} flexDirection='row'>
                        {/* {
                          object.editable &&
                          <EditIcon />
                        } */}

                        <OutlinedInput
                          multiline
                          disabled
                          // disabled={object.key !== editMode}
                          id="standard-disabled"
                          label="Disabled"
                          fullWidth
                          // onChange={(event) => handleDetailChange(requestDetails.id, object.key, event.target.value)}
                          endAdornment={
                            object.editable &&
                            <InputAdornment position="end">
                              <IconButton
                                onClick={ () => handleClickOpen(requestDetails.id, object.key, object.value, object.valueGetter ? object.valueGetter(requestDetails[object.key]) : requestDetails[object.key])
                                  // object.key === editMode ?
                                  // ()=> console.log(requestDetails.id, object.key, requestDetails[object.key])
                                  // // sendChange(requestDetails.id, object.key, )
                                  // : 
                                  // () => setEditMode(object.key)
                                }
                              // aria-label="toggle password visibility"
                              // onClick={handleClickShowPassword}
                              // onMouseDown={handleMouseDownPassword}
                              // edge="end"
                              >
                                <EditIcon />
                                {/* {object.key !== editMode ? <EditIcon /> : <SaveIcon />} */}
                              </IconButton>
                            </InputAdornment>
                          }
                          value={object.valueGetter ? object.valueGetter(requestDetails[object.key]) : requestDetails[object.key]}
                        //  defaultValue={editMode && object.valueGetter ? object.valueGetter(requestDetails[object.key]) : requestDetails[object.key]}
                        // variant="standard"
                        />

                        <Dialog open={open} onClose={handleClose}>
                          {
                            loadingDialog ?
                            <div>loading...</div>
                            :
                            <>
                            <DialogTitle>Edit {oldValue.title}</DialogTitle>
                            <DialogContent>
                              <DialogContentText>
                                Old: {oldValue.value}
                              </DialogContentText>
                              <TextField
                                multiline
                                value={newValue}
                                onChange={(event)=> setNewValue(event.target.value)}
                                autoFocus
                                margin="dense"
                                id="name"
                                label={`New ${oldValue.title}`}
                                type="text"
                                fullWidth
                                variant="outlined"
                              />
                            </DialogContent>
                            <DialogActions>
                              <Button onClick={handleClose}>Cancel</Button>
                              <Button onClick={handleDetailChange}>Confirm</Button>
                            </DialogActions>
                            </>
                          }
                        </Dialog>
                        {/* <Typography >{object.valueGetter ? object.valueGetter(requestDetails[object.key]) : requestDetails[object.key]}</Typography> */}
                      </Grid>
                    </>
                  )
                })
              }
            </Grid>
          </Container>
      }

    </>
  );
}
