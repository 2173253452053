import React from 'react';
import { Accordion, AccordionSummary, AccordionDetails, Typography, Grid, Paper, Button } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Link, useLocation, useNavigate } from 'react-router-dom';

const FAQ = () => {
  const faqs = [
    {
      question: 'Are leads updated regularly?',
      answer: 'Yes, both tender dates and service requests are updated on a quarterly/bi-annual basis.'
    },
    {
      question: 'What if I want to cancel?',
      answer: 'No contracts.  Subscriptions will end on the last day of your billing cycle.'
    },
    {
      question: 'What if there is an issue with my account?',
      answer: 'You can contact our customer service by email anytime at info@savvybusinessstrategies.com.  Please allow 1-2 business days for a response.'
    },
    {
      question: 'Will I be notified when there is a brand new service request or tender dates added to the website?',
      answer: 'Yes, you can choose an option to be notified by email to see newly added data.'
    },
    {
      question: 'How can I contact customer support?',
      answer: '',
      button: "Contact Us Here",
      url: "/contact"
    },
  ];

  return (
    <div style={{ paddingLeft: 100, paddingRight: 100 }}>
      {faqs.map((faq, index) => (
        <Accordion
          key={index}
        >
          <AccordionSummary

            expandIcon={<ExpandMoreIcon />}
            aria-controls={`faq${index}-content`}
            id={`faq${index}-header`}
          >
            <Typography variant="h6">{faq.question}</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>{faq.answer}</Typography>
            {faq.button &&
              <Link to={faq.url} style={{ textDecoration: 'none' }}>
                <Button onClick={() => console.log(faq.url)} size="small"><Typography sx={{ fontWeight: 'bold' }}>{faq.button}</Typography></Button>
              </Link>
            }
          </AccordionDetails>
        </Accordion>
      ))}
    </div>
  );
};

export default FAQ;
