import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom'
import { red, purple, pink, yellow, blue, lightGreen, orange, lime, amber, lightBlue } from '@mui/material/colors';
import { DataGrid, GridToolbar } from '@mui/x-data-grid';

import { Amplify, API, graphqlOperation, Auth } from 'aws-amplify'
import { Typography } from '@mui/material';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Tooltip from '@mui/material/Tooltip';
import Avatar from '@mui/material/Avatar';
import InputAdornment from '@mui/material/InputAdornment';
import SearchIcon from '@mui/icons-material/Search';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import RequestPageIcon from '@mui/icons-material/RequestPage';
import CleaningServicesIcon from '@mui/icons-material/CleaningServices';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import Link from '@mui/material/Link';

import { listSubscriptionLevels } from '../graphql/queries';
import { getVendorFavorites } from '../awsFuncCalls'

import LoadingImage from '../components/loading/LoadingImage';
import Plans from './Plans'


export default function Favourites(props) {
  const navigate = useNavigate()

  const [contractRequests, setContractRequests] = useState([])
  const [filteredRequests, setFilteredRequests] = useState([])
  const [sortFilter, setSortFilter] = useState('expirationDate')
  const [searchValue, setSearchValue] = useState('')
  const [needToSubscribe, setNeedToSubscribe] = useState(false)
  const [needToUpgrade, setNeedToUpgrade] = useState(false)
  const [isLoading, setIsLoading] = useState(true)

  const columns = [
    {
      field: 'companyName',
      headerName: 'Company',
      minWidth: 150,
      flex: 1.5,
      headerClassName: 'super-app-theme--header',
      renderCell: (params) => {

        return (
          params.row.requests.companyName &&
          <>
          <img
            src={`https://logo.clearbit.com/${params.row.requests.url}?size=200}`}
            alt={params.row.url}
            style={{ maxHeight: 45, marginRight: 13 }}
          />
          <Typography fontWeight="bold">
            {` ${params.row.requests.companyName}`}
          </Typography>
          </>
        );
      },
    },
    {
      field: 'city',
      headerName: 'City',
      minWidth: 75,
      flex: .7,
      headerClassName: 'super-app-theme--header',
      renderCell: (params) => {

        return (
          params.row.requests.city &&
          <Typography >
            {params.row.requests.city}
          </Typography>
        );
      },
    },
    {
      field: 'provinceState',
      headerName: 'Province',
      minWidth: 40,
      flex: .3,
      headerClassName: 'super-app-theme--header',
      renderCell: (params) => {

        return (
          params.row.requests.provinceState &&
          <Typography >
            {params.row.requests.provinceState.toUpperCase()}
          </Typography>
        );
      },
    },
    {
      field: 'requestNotes',
      headerName: 'Service Note',
      minWidth: 200,
      sortable: false,
      filterable: false,
      flex: 1,
      headerClassName: 'super-app-theme--header',
      renderCell: (params) => {
        return (
          <Tooltip title={params.row.requests.requestNotes}>
          <Typography >
            {`${params.row.requests.requestNotes.slice(0, 25)}...`}
          </Typography>
          </Tooltip>
        );
      },
    },
    {
      field: 'goLiveDate',
      headerName: 'Go Live Date',
      minWidth: 150,
      flex: .7,
      headerClassName: 'super-app-theme--header',
      renderCell: (params) => {
        return (
          <Typography >
            {new Date((Number(params.row.requests.goLiveDate))).toDateString()}
          </Typography>
        );
      },
    },
    {
      field: 'expirationDate',
      headerName: 'Expiry Date',
      minWidth: 150,
      flex: .7,
      headerClassName: 'super-app-theme--header',
      renderCell: (params) => {
        return (
          <Typography >
            {new Date((Number(params.row.requests.expirationDate))).toDateString()}
          </Typography>
        );
      },
    },
  ];

  useEffect(() => {
    const serviceRequestFetch = async () => {
      const cognitoUserInfo = await Auth.currentAuthenticatedUser({ bypassCache: true }) 
      const subPools = await API.graphql({ query: listSubscriptionLevels });
      const entitlementLevel = subPools.data.listSubscriptionLevels.items.find(subLevel => subLevel.id === cognitoUserInfo.attributes['custom:entitlement'])
      // if(subPools.data.listSubscriptionLevels.items.find(subLevel => subLevel.entitlement === "service"))
      // console.log(subPools, 'subpools')
      if (props.userData.group === "Admin" || entitlementLevel.entitlement === "contractService" || entitlementLevel.entitlement === "service" ){
        try {
          const res = await getVendorFavorites(cognitoUserInfo.attributes.sub)
          const tempArr = res.favourites.data.listFavourites.items.sort((a, b) => a.requests.expirationDate - b.requests.expirationDate)
          setContractRequests(tempArr)
          setFilteredRequests(tempArr)
          setTimeout(() => {
            setIsLoading(false)
          }, 1500);
          return true
        } catch (error) {
          console.log(error)
          return error;
        } 
      }
      if (cognitoUserInfo.attributes['custom:entitlement'] === '0154a494-c49b-4e1f-9935-59b1d1f6a791' || !cognitoUserInfo.attributes['custom:entitlement']) {
        setNeedToSubscribe(true)
        setTimeout(() => {
          setIsLoading(false)
        }, 1500);
        return true
      }
      return true
    }

    serviceRequestFetch();
  }, []);

  const requestSearch = (e) => {
    setSearchValue(e.target.value)
    const filteredRows = contractRequests.filter((row) => {
      return (
        row.companyName.toLowerCase().includes(e.target.value.toLowerCase()) // ||
        // row.subIndustry.title.toLowerCase().includes(e.target.value.toLowerCase())
      )

    });
    setFilteredRequests(filteredRows);
  };

  useEffect(() => {
    runFilter()
  }, [sortFilter])

  const runFilter = () => {
    let filtered
    if (sortFilter === "expirationDate") {
      filtered = contractRequests.sort((a, b) => {
        return a[sortFilter] - b[sortFilter]
      })
    }
    if (sortFilter === "companyName" || sortFilter === "city") {
      filtered = contractRequests.sort((a, b) => {
        return (a[sortFilter]).localeCompare(b[sortFilter]);
      })
    }
    return filtered
  }

  const handleRowClick = (params) => {
    navigate(`${params.row.requests.id}`,
      {
        state: {
          row: params.row.requests
        }
      })
  }

  return (
    <>
      {
        isLoading ?
          <LoadingImage dataPage />
          :
          needToSubscribe ?
            <Plans userData={props.userData} />
            :
            <>
              <Grid container style={{ marginBottom: 30, backgroundColor: 'white' }}>
                <Grid item container lg={.75} md={1} sm={12} style={{ alignItems: 'center', justifyContent: 'flex-start', flexDirection: "row" }}>
                  <CleaningServicesIcon sx={{ color: pink[200] }} />
                  <ChevronRightIcon />
                </Grid>
                <Grid item container lg={2.5} md={3} sm={12} style={{ alignItems: 'center', justifyContent: 'flex-start' }}>
                  <Typography variant="h6">Favourites</Typography>
                </Grid>
                <Grid item md={7} sm={8}>
                  <TextField
                    fullWidth
                    // label="Search"
                    id="fullWidth"
                    InputProps={{
                      startAdornment: <InputAdornment position="start"><SearchIcon /></InputAdornment>,
                    }}
                    value={searchValue}
                    onChange={(e) => requestSearch(e)}
                    size="small"
                    placeholder="Quick Search"
                  />
                </Grid>
              </Grid>
              <Paper elevation={1} >
                  <Box
                    sx={{
                      height: "100%",
                      width: '100%',
                      '& .super-app-theme--header': {
                        backgroundColor: '#EBEBEB',
                        // fontWeight: '#EBEBEB'
                      },
                    }}
                  >
                <DataGrid
                  autoHeight
                  disableClipboardPaste
                  onCellKeyDown={handleRowClick}
                  onRowClick={handleRowClick}
                  onCellClick={handleRowClick}
                  disableRowSelectionOnClick
                  disableClipboardPaste
                  onClick={handleRowClick}
                  rows={filteredRequests}
                  columns={columns}
                  initialState={{
                    pagination: {
                      paginationModel: {
                        pageSize: 10,
                      },
                    },
                  }}
                  pageSizeOptions={[10, 20, 100]}
                  disableRowSelectionOnClick
                  GridColDef={false}
                  disableColumnMenu
                  density="comfortable"
                />
                </Box>
              </Paper>
            </>
      }
    </>
  );
}