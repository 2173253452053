// import ReactDOM from 'react-dom/client';

// //
// import App from './App';
// import * as serviceWorker from './serviceWorker';
// import reportWebVitals from './reportWebVitals';

// // ----------------------------------------------------------------------

// const root = ReactDOM.createRoot(document.getElementById('root'));

// root.render(<App />);

// // If you want to enable client cache, register instead.
// serviceWorker.unregister();

// // If you want to start measuring performance in your app, pass a function
// // to log results (for example: reportWebVitals(console.log))
// // or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();


import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import { BrowserRouter } from "react-router-dom";
import { Amplify } from 'aws-amplify';
import { Authenticator } from '@aws-amplify/ui-react';
import awsExports from './aws-exports';
import App from './App';


Amplify.configure(awsExports);

ReactDOM.render(
  <Authenticator.Provider>
  <BrowserRouter>
    <App />
  </BrowserRouter>
  </Authenticator.Provider>,


  document.getElementById("root")
);