// // AccountPage.js
import React, { useState, useEffect } from 'react';
import {
  TextField,
  Button,
  Grid,
  Paper,
  Typography,
  InputAdornment,
  IconButton,
  Box,
  Link,
  CircularProgress
} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import SaveIcon from '@mui/icons-material/Save';
import { NavLink as RouterLink } from 'react-router-dom';


import { Auth, Hub } from 'aws-amplify';
import LoadingImage from '../components/loading/LoadingImage';

import { getVendorInfo, updateVendorInfo } from '../awsFuncCalls'


const accountInfo = [
  {
    key: "email",
    title: "Email",
    size: 12,
    editable: false
  },
  {
    key: "companyName",
    title: "Company Name",
    size: 12,
    editable: false
  },
  {
    key: "postalCode",
    title: "Postal Code",
    size: 12,
    editable: false
  },
]

const subscriptionInfo = [
  {
    key: "industry",
    title: "Industry",
    size: 12,
    editable: false
  },
  {
    key: "subscriptionStatus",
    title: "Subscription Status",
    size: 12,
    editable: false,
    valueGetter: (params) => {
      return params ? "Active" : "Inactive"
    }
  },
  {
    key: "subscriptionLevel",
    title: "Subscription Level",
    size: 12,
    editable: false,
    valueGetter: (params) => {
      return params.title
    }
  },
  {
    key: "subscriptionExpiry",
    title: "Billing Cycle",
    size: 12,
    editable: false,
    valueGetter: (params) => {
      if (params === "N/A") {
        return "N/A"
      }
      const milliseconds = parseInt(params, 10) * 1000
      const date = new Date(milliseconds).toLocaleDateString()
      return date
    }
  },
  {
    key: "autoRenew",
    title: "Auto Renew",
    size: 12,
    editable: false,
    valueGetter: (params) => {
      // console.log(params, "type")
      return params ? "Yes" : "No"
    }
  },
  {
    key: "subscriptionStatus",
    title: "Subscription",
    size: 12,
    editable: false,
    valueGetter: (params) => {
      return params ?
        <Button component={Link} href="https://billing.stripe.com/p/login/fZe9CobP41rV7qofYY" target="_blank" variant='outlined' style={{ marginTop: 20 }}>
          Modify Subscription *
          </Button>
        :
        <Button component={RouterLink} to={'/dashboard/account/plans'} variant='contained' style={{ marginTop: 0 }}>
          Add
        </Button>
    }
  },
]

const initialUserData = {
  firstName: '',
  lastName: '',
  email: '',
  companyName: '',
  address: '',
  city: '',
  province: '',
  postalCode: '',
  phoneNumber: '',
  industry: '',
  subIndustries: [],
  subscriptionStatus: '',
  subscriptionLevel: '',
  subscriptionExpiry: Date.now() / 1000,
  autoRenew: false
};

function AccountPage(props) {
  const [formData, setFormData] = useState(initialUserData);
  const [editing, setEditing] = useState(null)
  const [isLoading, setIsloading] = useState(true)

  useEffect(() => {
    const getAccountInfo = async () => {
      const cognitoUserInfo = await Auth.currentAuthenticatedUser({ bypassCache: true })
      // console.log(cognitoUserInfo.username)
      if (cognitoUserInfo) {
        try {
          const userInfo = await getVendorInfo(cognitoUserInfo.username)
          // console.log(userInfo, "-> Vendor Info")
          // const tempObj = userInfo.response
          setFormData({
            ...formData,
            firstName: userInfo.response.firstName ? userInfo.response.firstName : "",
            lastName: userInfo.response.lastName ? userInfo.response.lastName : "",
            email: userInfo.response.email ? userInfo.response.email : "",
            companyName: userInfo.response.companyName ? userInfo.response.companyName : "",
            address: userInfo.response.address ? userInfo.response.address : "",
            city: userInfo.response.city ? userInfo.response.city : "",
            province: userInfo.response.province ? userInfo.response.province : "",
            postalCode: userInfo.response.postalCode ? userInfo.response.postalCode : "",
            phoneNumber: userInfo.response.phoneNumber ? userInfo.response.phoneNumber : "",
            industry: userInfo.response.industry.title ? userInfo.response.industry.title : "",
            subIndustries: ["cleaning", "windows"],
            subscriptionExpiry: userInfo.response.subscriptionExpiry ? userInfo.response.subscriptionExpiry : "N/A",
            subscriptionStatus: userInfo.response.subscriptionStatus ? userInfo.response.subscriptionStatus : false, // userInfo.response.firstName ? userInfo.response.firstName : "",
            subscriptionLevel: userInfo.response.subscriptionLevel ? userInfo.response.subscriptionLevel : "N/A", // userInfo.response.firstName ? userInfo.response.firstName : "",
            autoRenew: userInfo.response.autoRenew ? userInfo.response.autoRenew : false, // userInfo.response.firstName ? userInfo.response.firstName : "",
          })
          setIsloading(false)
          return true
        } catch (error) {
          console.log(error)
          return error;
        }
      }
      else {
        return true
      }

    }
    getAccountInfo()
  }, [])

  const handleInputChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  // const handleSave = async (key, newValue) => {
  //   // Update user data in the backend or API
  //   // For demonstration, just log the updated data
  //   setEditing(null)
  //   const cognitoUserInfo = await Auth.currentAuthenticatedUser({ bypassCache: true })
  //   if (cognitoUserInfo) {
  //     const updateResponse = await updateVendorInfo(cognitoUserInfo.username, key, formData[key])
  //     if (updateResponse) {
  //       console.log(updateResponse, "RESPONSE VENDOR")
  //       // setformstate
  //     }
  //   }


  //   console.log('Updated user data:', formData);
  // };
  // const handleEdit = (key) => {
  //   setEditing(key)
  //   // Update user data in the backend or API
  //   // For demonstration, just log the updated data
  //   console.log('Original user data:', formData);
  // };

  return (
    <div>
      {
        isLoading ?
          <LoadingImage />
          :
          <div>
            <Grid container justifyContent='center' style={{ paddingBottom: 10 }}>
              <Grid container item justifyContent='center' xs={12} sm={10} lg={8}>
                <Typography variant="h4" gutterBottom>
                  Account Information
            </Typography>
                <Paper elevation={3} style={{ padding: '20px', width: '100%' }}>
                  <Grid container spacing={2} >
                    {
                      accountInfo.map((field) => {
                        return (
                          <Grid container key={field.key} item xs={field.size}>
                            <Grid item xs={6}>
                              <Typography>{field.title}</Typography>
                            </Grid>
                            <Grid item xs={6}>
                              {formData[field.key]}
                            </Grid>
                          </Grid>
                        )
                      })
                    }
                  </Grid>
                </Paper>
              </Grid>
            </Grid>
            <Grid container justifyContent='center' style={{paddingTop: 100, paddingBottom: 10 }}>
              <Grid container item justifyContent='center' xs={12} sm={10} lg={8}>
                <Typography variant="h4" gutterBottom>
                  Subscription Information
                </Typography>
                <Paper elevation={3} style={{ padding: '20px', width: '100%' }}>
                  <Grid container spacing={2} >
                    {
                      subscriptionInfo.map((field, i) => {
                        return (
                          <Grid
                            container
                            item
                            alignItems='center'
                            key={field.key + i.toString()}
                            xs={field.size}
                          >
                            <Grid item xs={6}>
                              <Typography>{field.title}</Typography>
                            </Grid>
                            <Grid item xs={6}>
                              {field.valueGetter ? field.valueGetter(formData[field.key]) : formData[field.key]}
                            </Grid>
                          </Grid>
                        )
                      })
                    }
                  </Grid>
                </Paper>
              </Grid>
            </Grid>
          </div>
      }
      {
        formData.subscriptionStatus &&
        <Grid container justifyContent='center'>
          {/* <Button component={Link} href="https://billing.stripe.com/p/login/fZe9CobP41rV7qofYY" target="_blank" variant='outlined' style={{ marginTop: 20 }}>
            Modify Subscription *
            </Button> */}
          <Typography variant='body2' textAlign='center' style={{ marginTop: 20, margin: 40 }}>
            * By clicking above, you will be forwarded to a webpage hosted by Stripe, our payment processor.  Follow the onscreen instructions to access your customer portal.
            </Typography>
        </Grid>
      }
    </div >
  );
}

export default AccountPage;
