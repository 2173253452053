import React from 'react';
import { Container, Typography } from '@mui/material';

const Privacy = () => {
    return (
        <div style={{ marginLeft: 30, marginRight: 30 }}>
            <p>Savvy Business Strategies respects your privacy and data protection rights, while recognizing the importance of protecting personal information collected, use of, disclose and/or retain when providing services.&zwj;</p>
            <p>Privacy laws in Canada generally define &ldquo;personal information&rdquo; as any information about an identifiable individual, which includes information that can be used on its own or with other.</p>
            <p>information to identify an individual. Business contact information (such as your name, title, business address, business telephone number and business email address) that is used for the purpose of communicating or facilitating communication with you in relation to your employment, business or profession is generally not considered personal information.</p>
            <p>The following is designed to help you understand what personal information we collect about you and how we use, maintain, protect and share it.</p>
            <p>This Privacy Policy applies to information we collect, use or disclose about you if you:</p>
            <ul>
                <li>Interact with any of Savvy Business Strategies websites (including www.savvybusinessstrategies.com) or our social media pages on which this Privacy Policy appears&nbsp;</li>
                <li>Use Savvy Business Strategies "Contract Requests" or "Service Requests" Resource tools, mobile applications, and our other applications and services (collectively, the &ldquo;Savvy Business Strategies company&rdquo;)&nbsp;</li>
                <li>Are a sales prospect, who is anyone whose data Savvy Business Strategies processes for the purposes of assessing customer eligibility (&ldquo;sales prospect&rdquo;); or</li>
                <li>Receive marketing communications from Savvy Business Strategies.</li>
            </ul>
            <p>&zwj;Please read this Privacy Policy carefully to understand our policies and practices for collecting, using and disclosing your personal information. If you do not agree with our policies and practices, your choice is not to use our Sites, any Savvy Business Strategies services or otherwise provide us with your personal information. By accessing or using our Sites, Savvy Business Strategies services or by providing us with your personal information, you indicate that you understand, accept and consent to the policies and practices described in this Privacy Policy.</p>
            <p>The information that we collect about you falls into the categories set out below. This information is obtained either: (a) directly and voluntarily from you when using Savvy Business Strategies services or visiting our Sites or premises; (b) automatically from your device through cookies or similar technologies (including the device data or service data described below); or (c) from third-party or publicly available sources described below.</p>
            <p>Registration, contact, and company information:</p>
            <ul>
                <li>First and last names</li>
            </ul>
            <ul>
                <li>Business email addresses</li>
            </ul>
            <ul>
                <li>Business phone numbers</li>
            </ul>
            <ul>
                <li>Business avatars</li>
            </ul>
            <ul>
                <li>Company name</li>
            </ul>
            <ul>
                <li>Your role in your company</li>
            </ul>
            <ul>
                <li>Property names</li>
            </ul>
            <ul>
                <li>Property locations</li>
            </ul>
            <ul>
                <li>Services provided</li>
            </ul>
            <ul>
                <li>Geolocation data</li>
            </ul>
            <p>Payment information*:</p>
            <ul>
                <li>Credit card information</li>
            </ul>
            <ul>
                <li>Billing and mailing addresses</li>
            </ul>
            <ul>
                <li>Other payment-related information</li>
            </ul>
            <p>*Payment information is only collected by Savvy Business Strategies (collected and processed by Stripe, a third-party online payment provider, and is dealt with in accordance with Stripe&rsquo;s privacy policy, available at https://stripe.com/en-ca/privacy)</p>
            <p>Device data:</p>
            <ul>
                <li>Operating system type and version number, manufacturer and model</li>
            </ul>
            <ul>
                <li>Browser type</li>
            </ul>
            <ul>
                <li>Screen resolution</li>
            </ul>
            <ul>
                <li>IP address</li>
            </ul>
            <ul>
                <li>Unique device identifiers</li>
            </ul>
            <p>Service data:</p>
            <ul>
                <li>The website you visited before Savvy Business Strategies' sites</li>
            </ul>
            <ul>
                <li>How long you spent on a page or screen on Savvy Business Strategies sites</li>
            </ul>
            <ul>
                <li>How you interact with our emails</li>
            </ul>
            <ul>
                <li>Navigation paths between pages or screens</li>
            </ul>
            <ul>
                <li>Date and time</li>
            </ul>
            <ul>
                <li>Pages viewed</li>
            </ul>
            <ul>
                <li>Links clicked</li>
            </ul>
            <p>&zwj;Third party source data:</p>
            <ul>
                <li>Company information</li>
            </ul>
            <ul>
                <li>Job titles</li>
            </ul>
            <ul>
                <li>Avatars</li>
            </ul>
            <ul>
                <li>Email addresses</li>
            </ul>
            <ul>
                <li>Phone numbers</li>
            </ul>
            <ul>
                <li>Addresses</li>
            </ul>
            <ul>
                <li>Geolocation data</li>
            </ul>
            <p>The sources of this third-party personal information may include:</p>
            <ul>
                <li>Publicly available information (as defined by applicable law in the case of public available personal information)</li>
            </ul>
            <ul>
                <li>Contact enrichment and lead generation providers.</li>
            </ul>
            <p>You may also provide information to be published or displayed (hereinafter, &ldquo;posted&rdquo;) on public areas of the Site or transmitted to other users of the Site or third parties (collectively, &ldquo;User Contributions&rdquo;). Your User Contributions are posted on the Site and transmitted to others at your own risk. Please be aware that no security measures are perfect. Additionally, we cannot control the actions of other users of the Site with whom you may choose to share your User Contributions. Therefore, we cannot and do not guarantee that unauthorized persons will not view your User Contributions. Please keep this in mind if you choose to include personal information in your User Contributions.</p>
            <p>We collect and process your information, including any personal information, for the following purposes:</p>
            <p>Providing and Facilitating Delivery of Savvy Business Strategies Services and Sites: We process your personal information to meet our contractual obligations with you for use of Savvy Business Strategies Services, and to fulfill our obligations under applicable terms of service. Where we have not entered into a service contract with you, we process any personal information we have collected about you in accordance with applicable law to create a profile account about your business, which you may claim and build on, as described in our Terms of Service.</p>
            <p>Communicating with you about Savvy Business Strategies Services and providing customer support: We may send you service, technical and other administrative messages to administer the site and provide Savvy Business Strategies Services. For example, we may send you messages about the availability of Savvy Business Strategies Services or security updates to the Site. We also process your personal information to respond to your comments and questions and to provide customer care and support.</p>
            <p>Improving our Services and Sites: We process your personal information, in particular service data and device data, to improve and optimize Savvy Business Strategies services and sites and to understand how you use Savvy Business Strategies services and sites, including to monitor usage or traffic patterns and to analyze trends and develop new products, services, features and functionality. This data generally analyzed and used on an aggregated and anonymous basis. However, if we can use it alone or in combination with other data to identify an individual, we treat it as personal information in accordance with this Privacy Policy</p>
            <p>Sending marketing communications: We process your personal information to send you marketing communications via email about our products, services and upcoming events that might interest you where we have your consent in accordance with applicable law. Please see below, to learn how you can control your marketing preferences.</p>
            <p>Registering Office Visitors: We process your personal information for security reasons and for the purpose of hosting your visit, to the extent that such processing is necessary, to protect our premises and confidential information against unauthorized access. As well, we do this to ensure the safety of our staff and office visitors.</p>
            <p>Maintaining Security of Savvy Business Strategies Services and Sites: We process your personal information to control unauthorized use or abuse of the Savvy Business Strategies Services and Sites, or otherwise detect, investigate or prevent activities that may violate Savvy Business Strategies Terms of Service or applicable laws, and to maintain and promote the safety and security of Savvy Business Strategies Sites and Services.</p>
            <p>Displaying Personalized Advertisements: We may process your personal information to provide you with personalized advertising on our Sites and on third party sites that we believe would be of interest to you based on your preferences. See the section entitled &ldquo;Your Personal Information Rights&rdquo; to learn how you can opt-out of such targeted ads.</p>
            <p>Carrying out Other Legitimate Business Purposes: We use your personal information for business purposes related to the provision of Savvy Business Strategies Services, including invoicing, audits, and fraud monitoring and prevention.</p>
            <p>Complying with legal obligations: We process your personal information when cooperating or complying with public and government authorities, courts or regulators in accordance with our obligations under applicable laws and to protect against imminent harm to our rights, property or safety, or that of our users or the public, as required or permitted by law.</p>
            <p>We may also use your information in any other way we may describe when you provide the information or for any other purpose with your consent.</p>
            <p>We may disclose personal information that we collect, or that you provide as described in this Privacy Policy:</p>
            <ul>
                <li>In accordance with applicable law, to a buyer or other successor in the event of a merger, divestiture, restructuring, reorganization, dissolution, or other sale or transfer of some or all of Savvy Business Strategies assets, whether as a going concern or as part of bankruptcy, liquidation, or similar proceeding, in which personal information held by Savvy Business Strategies about our customers and users is among the assets transferred.</li>
            </ul>
            <ul>
                <li>To our authorized service providers to fulfill the purpose for which you provide it.</li>
            </ul>
            <ul>
                <li>For any other purpose disclosed by us when you provide the information.</li>
            </ul>
            <ul>
                <li>For any additional purpose, with your consent.</li>
            </ul>
            <p>We may also disclose your personal information:</p>
            <ul>
                <li>To comply with any court order, law, or legal process, including to respond to any government or regulatory request, in accordance with applicable law.</li>
            </ul>
            <ul>
                <li>To enforce or apply our Terms of Service and other agreements, including for billing and collection purposes.</li>
            </ul>
            <ul>
                <li>If we believe disclosure is necessary or appropriate to protect the rights, property, or safety of Savvy Business Strategies, our customers, or others. This includes exchanging information with other companies and organizations for the purposes of fraud protection and credit risk reduction, in accordance with applicable law.</li>
            </ul>
            <p>The security of your personal information is very important to us. We use physical, technical, and administrative measures designed to secure your personal information from accidental loss and from unauthorized or unlawful access, use, alteration and disclosure.</p>
            <p>The safety and security of your information also depends on you. Where we have given you (or where you have chosen) a password for access to certain parts of our sites, you are responsible for keeping this password confidential. We ask you not to share your password with anyone. We urge you to be careful about what personal information or other information you disclose using the chat features and message boards of the sites, which can be collected, used and/or disclosed by other users.</p>
            <p>While we take all appropriate steps to safeguard Personal Information under our control, there is an inherent risk involved in the exchange of information using any form of communication, such as via the Internet, email and telephone. While we employ advanced data encryption technology, you should be aware that there is a residual risk in transmitting any data electronically. Despite our efforts, no security measures are perfect or impenetrable and no method of data transmission can be guaranteed against any interception or other type of misuse. This risk is inherent in all Internet dealings.</p>
            <p>Your personal information may be transferred to, processed and/or stored in a foreign jurisdiction outside of the jurisdiction in which it was originally collected. As a result, your personal information may be subject to the laws of such foreign jurisdiction and may be accessible without notice to you by the courts, law enforcement and national security authorities of such foreign jurisdiction.</p>
            <p>Except as otherwise permitted or required by applicable law or regulation, we will only retain your personal information for as long as necessary to fulfill the purposes we collected it for, including for the purposes of satisfying any legal, accounting, or reporting requirements. Under some circumstances we may anonymize and de-identify your personal information so that it can no longer be associated with you. We reserve the right to use such anonymous and de identified data for any legitimate business purpose without further notice to you or your consent.&zwj;</p>
            <p>Our Sites and Savvy Business Strategies Services are intended for individuals who are age of majority or older in their jurisdiction of residence. We do not knowingly collect personal information of minors. If you are a minor, please do not use the sites or provide any personal information to us via the sites or otherwise. If you are aware that we have unknowingly collected or received personal information of a minor without the consent of a parent or legal guardian, please contact us at info@savvybusinessstrategies.com and we will delete such information.</p>
            <p>Depending on your location and subject to applicable laws, you may have certain data protection rights. You can exercise any of these rights by submitting a request to us at info@savvybusinessstrategies.com</p>
            <p>If you wish to access, correct or update your personal information, or request deletion or portability of your personal information (where applicable), please submit your request to us.</p>
            <p>You have the right to opt-out of marketing communications we send you at any time. If you no longer wish to receive our newsletter and promotional communications, you may opt-out of receiving them by clicking on the &ldquo;unsubscribe&rdquo; or &ldquo;opt-out&rdquo; link in the communications we send you. Please note, however, that it may not be possible to opt-out of certain service-related communications. You can let us know at any time if you do not wish to receive marketing messages by contacting us using the contact details below.</p>
            <p>We and third parties such as advertising networks may use cookies to collect personal information and display advertisements, which may be based on your activities across the Internet and targeted to you for products and services in which we believe you might be interested. Most advertising networks offer you a way to opt-out of targeted advertising. You may also opt-out of receiving targeted advertising on participating sites and services by visiting http://youradchoices.ca/choices. We are not responsible for effectiveness of or compliance with any third parties opt-out options or programs.</p>
            <p>Similarly, if we have collected and processed your personal information with your consent, then you can withdraw your consent at any time. Withdrawing your consent will not affect the lawfulness of any processing we conducted prior to your withdrawal.</p>
            <p>You have the right to complain to a privacy commissioner about our collection and use of your personal information. For more information, please contact your local privacy commissioner.&nbsp;</p>
            <p>We may amend this Privacy Policy from time to time in response to changing legal, technical or business developments, as indicated by the &ldquo;last updated&rdquo; date at the top of the Privacy Policy. We will post a notice of amendments on the home page of our Site and/or provide email notifications to registered users, consistent with the significance of the changes we make. By continuing to use our sites, Savvy Business Strategies Services or by otherwise providing us with your personal information after such changes, you will be signifying your consent to our revised Privacy Policy. If you do not agree with any changes to our Privacy Policy, please do not use our Sites, Savvy Business Strategies services or otherwise provide us with your personal information. If you have any questions, comments or concerns about the way your personal information is being used or processed by Savvy Business Strategies or would like more information about our use of nonCanadian service providers, please submit any questions, comments or concerns in writing to request to us at info@savvybusinessstrategies.com</p>
        </div>
    );
};

export default Privacy;
