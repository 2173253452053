import { Helmet, HelmetProvider } from 'react-helmet-async';
import { faker } from '@faker-js/faker';
import { useNavigate } from "react-router-dom";
// @mui
import { useTheme } from '@mui/material/styles';
import { Grid, Container, Typography, Button, Paper } from '@mui/material';
import { red, purple, pink, yellow, blue, lightGreen, orange, lime, amber, lightBlue, green } from '@mui/material/colors';


const buttons = [
  { title: "Upload Leads", key: "uploadleads", color: blue.A200 },
  { title: "Add Industry", key: "addindustry", color: pink.A200 },
  { title: "Add Sub Industry", key: "addsubindustry", color: lime.A200 },
  { title: "View All Leads", key: "viewleads", color: lightBlue[200] },
  { title: "Call List", key: "calllist", color: orange[500] },
  { title: "Subscriptions", key: "subcription", color: purple.A400 },
  { title: "Vendor Accounts", key: "viewvendors", color: green.A400 },
]

// ----------------------------------------------------------------------

export default function DashboardAppPage(props) {
  const theme = useTheme();
  const navigate = useNavigate();


  return (
    <>

      <HelmetProvider >
        <Helmet>
          <title> Savvy Business </title>
        </Helmet>
      </HelmetProvider>

      <Container maxWidth="xl">
        <Typography variant="h4" sx={{ mb: 5 }}>
          Hi {props.userData.companyName}, welcome back!
        </Typography>


        <Grid item xs={12}>
          <Grid container justifyContent="space-around" >
            {buttons.map((value) => (
              <Grid item container
                component={Button}
                alignItems='center'
                justifyContent='center'
                key={value.key}
                md={5}
                xs={10}
                style={{
                  height: '10em',
                  width: '5em',
                  marginBottom: '3em',
                  backgroundColor: value.color,
                  borderRadius: '1em',

                }}
                onClick={() => navigate(`/admin/${value.key}`)}
              >
                <Typography variant="h4" fontWeight="bold" color="black">
                  {value.title}
                </Typography>
              </Grid>
            ))}
          </Grid>
        </Grid>
      </Container>
    </>
  );
}
