import React, { useState, useEffect } from 'react';
import {
  TextField,
  Button,
  Grid,
  Paper,
  Typography,
  InputAdornment,
  IconButton,
  Box,
  Link,
  paperClasses
} from '@mui/material';
import { red, purple, pink, yellow, blue, lightGreen, orange, lime, amber, lightBlue } from '@mui/material/colors';
import FavoriteIcon from '@mui/icons-material/Favorite';
import HttpsIcon from '@mui/icons-material/Https';
import GppBadIcon from '@mui/icons-material/GppBad';
import StarIcon from '@mui/icons-material/Star';
import Icon from '@mui/material/Icon';

import mainLogo from '../assets/images/sbslogo.png'
import FAQ from '../components/FAQ/FAQ';

const boxes = [
  {
    icon: <FavoriteIcon fontSize="large" sx={{ color: 'white' }} />,
    title: "Top Quality Support",
    subTitle: "Excellence in service. Unmatched in quality and responsiveness."

  },
  {
    icon: <HttpsIcon fontSize="large" sx={{ color: 'white' }} />,
    title: "Safe and Secure",
    subTitle: "Reliable security measures in place for peace of mind."

  },
  {
    icon: <GppBadIcon fontSize="large" sx={{ color: 'white' }} />,
    title: "Cancel Any Time",
    subTitle: "No long term contracts. Peace of mind to pivot business needs as they come and go."

  },
]


function PricingPage(props) {

  const [isLoading, setIsLoading] = useState(true)
  const [email, setEmail] = useState('')
  const [referenceId, setReferenceId] = useState('')

  // Paste the stripe-pricing-table snippet in your React component

  useEffect(() => {
    setEmail(props.userData.email)
    setReferenceId(props.userData.id)
    setIsLoading(false)
  }, [])


  return (
    isLoading
      ?
      <div >
        loading...
      </div>
      :
      <Paper elevation={0}>
        <Grid container flexDirection='column' alignItems='center' style={{ marginBottom: 50 }} >
          <Grid item container justifyContent="center" alignItems="center" flexDirection="column">
            {/* <Grid item>
              <img src={mainLogo} alt="logo" style={{ height: '80px', marginTop: '1rem', marginBottom: '1rem' }} />
            </Grid> */}
            <Grid item>
              <Typography variant='h3' fontWeight="bold">
                Choose the best plan for your business
          </Typography>
            </Grid>
          </Grid>
          {/* <Grid item>
            <Typography variant='h6'>
              Special Promotion 30% off monthly plans for a limited time
            </Typography>
          </Grid>
          <Grid item>
            <Typography variant='body2'>
              USE CODE SALE30 AT CHECKOUT
            </Typography>
          </Grid> */}
        </Grid>
        <stripe-pricing-table
          pricing-table-id="prctbl_1PJxo1DuugDLhrk0VFsQoflU"
          publishable-key="pk_live_51OUbM3DuugDLhrk0x2BG2IbiBBc5zi1YmVt0WhxtY4dGGY5SnkykMFNrWVDq1QSMr7HIRuJ5ywozBrxUaiY1HIkD00vPsODFpf"
          client-reference-id={referenceId}
          customer-email={email}
        />
        <Grid container flexDirection='column' alignItems='center' style={{ marginTop: 50 }} >
          <Grid item container justifyContent="center" alignItems="center" flexDirection="column" sx={{ paddingX: 20, textAlign: 'center' }}>
            {/* <Grid item>
              <img src={mainLogo} alt="logo" style={{ height: '80px', marginTop: '1rem', marginBottom: '1rem' }} />
            </Grid> */}
            <Grid item>
              <Typography variant='h3' fontWeight="bold">
                Get instant access to best quality leads with up to date information.
          </Typography>
            </Grid>
          </Grid>
          <Grid item>
            <Typography variant='h6'>
              Use Savvy Business Strategies to help grow your business
            </Typography>
          </Grid>
        </Grid>
        <Grid container flexDirection='column' alignItems='center' style={{ marginTop: 60 }} >
          <Grid container flexDirection='row' justifyContent='space-around'>
            {
              boxes.map((box) => {
                return (
                  <Grid key={box.title} item container flexDirection='column' justifyContent='space-evenly' alignItems='center' sx={{ height: '20em', width: '30em', backgroundColor: blue[900], borderRadius: '1em', textAlign: 'center', padding: 4, marginTop: 1 }}>
                    {box.icon}
                    <Typography variant='h5' fontWeight='bold' sx={{ color: 'white' }}>
                      {box.title}
                    </Typography>
                    <Typography variant='h6' sx={{ color: 'white' }}>
                      {box.subTitle}
                    </Typography>
                  </Grid>
                )
              })
            }
          </Grid>
        </Grid>
        <Grid container justifyContent='center' style={{ marginTop: 40, marginBottom: 20 }}>
          <Typography variant="h3" fontWeight="bold">
            Frequently Asked Questions
        </Typography>
        </Grid>
        <FAQ />
        {/* <Grid container flexDirection='column' alignItems='center' style={{ marginTop: 120, paddingBottom: 120 }} >
          <Grid item container justifyContent='center' sx={{paddingBottom: 10}} >
              <Typography variant='h3' fontWeight="bold">
              Heres what some of our customers are saying
            </Typography>
          </Grid>
          <Grid container flexDirection='row' justifyContent='space-around'>
            <Grid item container flexDirection="column" justifyContent='space-between' alignItems='center' sx={{ minHeight: '15em', width: '20em', border: '1px solid lightgrey', borderRadius: '1em', textAlign: 'center', padding: 4 }}>
              <Grid item container justifyContent='center'>
              <StarIcon fontSize="small" sx={{color:"#FDBB2E"}}/>
              <StarIcon fontSize="small" sx={{color:"#FDBB2E"}} />
              <StarIcon fontSize="small" sx={{color:"#FDBB2E"}} />
              <StarIcon fontSize="small" sx={{color:"#FDBB2E"}} />
              <StarIcon fontSize="small" sx={{color:"#FDBB2E"}} />
              </Grid>
              <Typography variant='h5' fontWeight='bold'>
                John Doe
              </Typography>
              <Typography variant='h6'>
                My revenues increased by 50% since I started using Savvy Business Strategies.  Highly recommend!
              </Typography>
            </Grid>
            <Grid item container flexDirection="column" justifyContent='space-between' alignItems='center' sx={{ minHeight: '15em', width: '20em', border: '1px solid lightgrey', borderRadius: '1em', textAlign: 'center', padding: 4 }}>
              <Grid item container justifyContent='center'>
              <StarIcon fontSize="small" sx={{color:"#FDBB2E"}}/>
              <StarIcon fontSize="small" sx={{color:"#FDBB2E"}} />
              <StarIcon fontSize="small" sx={{color:"#FDBB2E"}} />
              <StarIcon fontSize="small" sx={{color:"#FDBB2E"}} />
              <StarIcon fontSize="small" sx={{color:"#FDBB2E"}} />
              </Grid>
              <Typography variant='h5' fontWeight='bold'>
                Lois Lane
              </Typography>
              <Typography variant='h6'>
                Everything got better after using Savvy.  I was able to focus more on bringing on new business and developping relationships.
              </Typography>
            </Grid>
            <Grid item container flexDirection="column" justifyContent='space-between' alignItems='center' sx={{ minHeight: '15em', width: '20em', border: '1px solid lightgrey', borderRadius: '1em', textAlign: 'center', padding: 4 }}>
              <Grid item container justifyContent='center'>
              <StarIcon fontSize="small" sx={{color:"#FDBB2E"}}/>
              <StarIcon fontSize="small" sx={{color:"#FDBB2E"}} />
              <StarIcon fontSize="small" sx={{color:"#FDBB2E"}} />
              <StarIcon fontSize="small" sx={{color:"#FDBB2E"}} />
              <StarIcon fontSize="small" sx={{color:"#FDBB2E"}} />
              </Grid>
              <Typography variant='h5' fontWeight='bold'>
                Adam
              </Typography>
              <Typography variant='h6'>
                Great tool for any service based business.
              </Typography>
            </Grid>
          </Grid>
        </Grid> */}
      </Paper>
  );
}

export default PricingPage;