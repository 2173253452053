import { useState } from 'react';
// @mui
import { alpha } from '@mui/material/styles';
import { Box, Divider, Typography, Stack, MenuItem, Avatar, IconButton, Popover } from '@mui/material';
import { Auth } from 'aws-amplify'
import { Link, useLocation, useNavigate } from 'react-router-dom';

// ----------------------------------------------------------------------

const MENU_OPTIONS = [
  {
    label: 'Account',
    icon: 'eva:person-fill',
    url: 'dashboard/account'
  },
  {
    label: 'Help',
    icon: 'eva:settings-2-fill',
    url: 'dashboard/contact'
  },
];

// ----------------------------------------------------------------------

// export default function AccountPopover({ userData, setUserLoggedIn, setUserData }) {
export default function AccountPopover(props) {

  const navigate = useNavigate()

  const [open, setOpen] = useState(null);

  const handleOpen = (event) => {
    setOpen(event.currentTarget);
  };

  const handleClose = () => {
    setOpen(null);
  };


  async function signOut() {
    try {
      await Auth.signOut({ global: true });
      props.setUserLoggedIn(false)
      props.setUserData()
      handleClose()
      navigate('/')

    } catch (error) {
      console.log('error signing out: ', error);
    }
  }

  return (
    <>
      <IconButton
        onClick={handleOpen}
        sx={{
          p: 0,
          ...(open && {
            '&:before': {
              zIndex: 1,
              content: "''",
              width: '100%',
              height: '100%',
              borderRadius: '50%',
              position: 'absolute',
              bgcolor: (theme) => alpha(theme.palette.grey[900], 0.3),
            },
          }),
        }}
      >
        <Avatar >
          {`${props.userData.companyName.charAt(0).toUpperCase()}`}
          {/* {userData.firstName + userData.lastName} */}
        </Avatar>
      </IconButton>

      <Popover
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        PaperProps={{
          sx: {
            p: 0,
            mt: 1.5,
            ml: 0.75,
            width: 180,
            '& .MuiMenuItem-root': {
              typography: 'body2',
              borderRadius: 0.75,
            },
          },
        }}
      >
        <Box sx={{ my: 1.5, px: 2.5 }}>
          <Typography variant="subtitle2" noWrap>
            {props.userData.firstName} {props.userData.lastName}
          </Typography>
          <Typography variant="body2" sx={{ color: 'text.secondary' }} noWrap>
            {props.userData.companyName}
          </Typography>
        </Box>

        <Divider sx={{ borderStyle: 'dashed' }} />

        <Stack sx={{ p: 1 }}>
          {MENU_OPTIONS.map((option) => (
              <Link key={`/${option.url}`} to={`/${option.url}`} style={{ textDecoration: 'none', color: 'black' }}>
                <MenuItem key={option.label} onClick={handleClose} >
                  {option.label}
                </MenuItem>
              </Link>
          ))}
        </Stack>

      <Divider sx={{ borderStyle: 'dashed' }} />

      <MenuItem onClick={() => signOut()} sx={{ m: 1 }}>
        Logout
        </MenuItem>
    </Popover>
    </>
  );
}
