import React, { useState } from 'react';
import { Container, Typography, Paper, TextField, Button, Grid, MenuItem } from '@mui/material';

import { Auth, API } from 'aws-amplify';
import * as mutations from '../graphql/mutations';

function ContactForm(props) {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    subject: '',
    message: '',
  });

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const isLoggedIn = async () => {
        try {
            await Auth.currentAuthenticatedUser();
            return true;
        } catch {
            return false;
        }
    }
    const isLoggedInStatus = await isLoggedIn();

    try {
      const sendHelp = await API.graphql({
        query: mutations.sendHelpRequest,
        variables: {
          name: formData.name,
          email: formData.email,
          accountEmail: props.userData.email ? props.userData.email : "Not Logged In",
          subject: formData.subject,
          message: formData.message
        },
        authMode: isLoggedInStatus ? "AMAZON_COGNITO_USER_POOLS" : "AWS_IAM",

      })
      alert("Your message has been sent.  Please allow 1-2 business days to receive a response.")
    } catch (e) {
      alert(e)
      console.log(e)
    }
    // Reset form after submission
    setFormData({
      name: '',
      email: '',
      subject: '',
      message: '',
    });
  };

  return (
    <Container sx={{ marginTop: 4 }}>
      <Paper elevation={3} sx={{ padding: 4 }}>
        <Typography variant="h4" gutterBottom>
          Contact Us
        </Typography>
        <form onSubmit={handleSubmit}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                label="Your Name"
                name="name"
                value={formData.name}
                onChange={handleChange}
                required
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                label="Your Email"
                name="email"
                type="email"
                value={formData.email}
                onChange={handleChange}
                required
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                select
                label="Subject"
                name="subject"
                value={formData.subject}
                onChange={handleChange}
                required
              >
                <MenuItem value="General Inquiry">General Inquiry</MenuItem>
                <MenuItem value="Technical Support">Technical Support</MenuItem>
                <MenuItem value="Feedback">Feedback</MenuItem>
                <MenuItem value="Schedule a Demo">Schedule a Demo</MenuItem>
              </TextField>
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Message"
                name="message"
                multiline
                rows={4}
                value={formData.message}
                onChange={handleChange}
                required
              />
            </Grid>
            <Grid item xs={12}>
              <Button type="submit" variant="contained" color="primary">
                Submit
              </Button>
            </Grid>
          </Grid>
        </form>
      </Paper>
      <Grid container flexDirection='column' alignItems='center' style={{marginTop: 15}}>
        <Grid item>
          <Typography textAlign="center">
            80 Atlantic Ave. Suite 400
          </Typography>
        </Grid>
        {/* <Grid item>
          <Typography>
            Suite 400
          </Typography>
        </Grid> */}
        <Grid item container flexDirection='row' justifyContent='center'>
          <Grid item>
            <Typography>
              Toronto,
          </Typography>
          </Grid>
          <Grid item style={{marginLeft: 3}}>
            <Typography>
              ON
          </Typography>
          </Grid>
        </Grid>
        <Grid item>
          <Typography>
            M6K 1X9
          </Typography>
        </Grid>
      </Grid>
    </Container>
  );
}

export default ContactForm;
