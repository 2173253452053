import { API, graphqlOperation, Auth } from 'aws-amplify';
import { createAdmin, updateAdmin, createRequest, createNotes, createFavourites, deleteFavourites, updateRequest, updateVendor, listRequestsForUser, createIndustry, updateIndustry, createSubIndustry } from './graphql/mutations';
import { getRequest, listNotes, listRequests, getVendor, listFavourites, listIndustries, listSubIndustries, listVendors } from './graphql/queries';

const serviceRequestId = "1599cd48-456a-45ff-88be-5a74ea7ff2e1"
const contractRequestId = "b43cc803-1ed4-48b4-bcad-656962abaa85"
const leadRequestId = "4b5ec17a-f8a6-40f8-9c4f-1eca119fae6b"

export async function listAllContractRequests(industry) {
    const variables = {
        filter: {
            requestRequestTypeId: { eq: contractRequestId },
            requestIndustryId: { eq: industry }
        }
    };
    const allRequests = await API.graphql({ query: listRequests, variables });
    return { allRequests }
}

export async function listAllServiceRequests(industry) {
    const variables = {
        filter: {
            requestRequestTypeId: { eq: serviceRequestId },
            requestIndustryId: { eq: industry }
        }
    };

    const allRequests = await API.graphql({ query: listRequests, variables });
    return { allRequests }
}

export async function listAllRequests() {
    const allRequests = await API.graphql({ query: listRequests });
    return { allRequests }
}


export async function getServiceRequests(ID) {
    const allRequests = await API.graphql({ query: getRequest, variables: { id: ID } });
    return { allRequests }
}


export async function getVendorFavorites(ID) {
    const variables = {
        filter: { favouritesOwnerIdId: { eq: ID } }
    }

    const favourites = await API.graphql({ query: listFavourites, variables });
    return { favourites }
}

export async function addToFavourite(requestId, vendorId) {
    const input = { favouritesOwnerIdId: vendorId, favouritesRequestsId: requestId, vendorFavouritesId: vendorId };
    const res = await API.graphql(graphqlOperation(createFavourites, { input }));
    return { res }
}

export async function removeFavourites(requestId) {
    const input = { id: requestId };
    const res = await API.graphql(graphqlOperation(deleteFavourites, { input }));
}

export async function saveNotes(date, note, requestID, requestUserNotesId, vendorID, vendorUserNotesId) {
    const notesInput = { date, note, requestID, requestUserNotesId, vendorID, vendorUserNotesId };
    const newNotesArr = await API.graphql(graphqlOperation(createNotes, { input: notesInput }));
}

export async function listRequestNotes(userId, requestId) {
    const variables = {
        filter: {
            and: [
                { vendorUserNotesId: { eq: userId } },
                { requestUserNotesId: { eq: requestId } }
            ]
        }
    };

    const notes = await API.graphql({ query: listNotes, variables });
    return { notes: notes.data.listNotes.items }
}



export async function listAllIndustries() {

    const isLoggedIn = async () => {
        try {
            await Auth.currentAuthenticatedUser();
            return true;
        } catch {
            return false;
        }
    }
    const isLoggedInStatus = await isLoggedIn();
    const variables = {
        filter: {
            active: { eq: true }
        }
    };
    const industries = await API.graphql({
        query: listIndustries,
        variables,
        authMode: isLoggedInStatus ? "AMAZON_COGNITO_USER_POOLS" : "AWS_IAM",
    });
    return { industries: industries.data.listIndustries.items }
}


export async function addIndustry(title) {
    const industryInput = { title, active: true };
    const newIndustryItem = await API.graphql(graphqlOperation(createIndustry, { input: industryInput }));
    return { newInd: newIndustryItem.data.createIndustry }
}

export async function addSubIndustry(title, industryId) {
    const industryInput = {
        title,
        active: true,
        subIndustryIndustryId: industryId,
        industrySubIndustryId: industryId
    };
    const newSubIndustryItem = await API.graphql(graphqlOperation(createSubIndustry, { input: industryInput }));
    return { newSubInd: newSubIndustryItem.data.createSubIndustry }
}

export async function editIndustry(title, id) {
    const industryInput = { title, id };
    const updateIndustryItem = await API.graphql(graphqlOperation(updateIndustry, { input: industryInput }));
    return { newInd: updateIndustryItem.data.updateIndustry, success: true }
}

export async function listAllSubIndustries() {
    const variables = {
        filter: {
            active: { eq: true }
        }
    };
    const subIndustries = await API.graphql({ query: listSubIndustries, variables });
    return { subIndustries: subIndustries.data.listSubIndustries.items }
}


export async function updateLeadField(id, key, newValue) {
    const requestChanges = {
        id,
        [key]: newValue
    }
    const res = await API.graphql(graphqlOperation(updateRequest, { input: requestChanges }))
    return { response: res }
}

export async function uploadRequests(val) {

    const [
        companyName = companyName.trim(),
        address,
        address2,
        city,
        provinceState,
        postalZip,
        url,
        contactFirstName,
        contactLastName,
        titlePosition,
        contactPhone,
        contactEmail,
        buildingType,
        requestType,
        industry,
        subIndustry,
        requestNotes,
        goLiveDate,
        expirationDate
    ] = val
    let requestIDFound
    if (requestType.trim() === "Service Request") {
        requestIDFound = serviceRequestId
    }
    if (requestType.trim() === "Contract Request") {
        requestIDFound = contractRequestId
    }
    if (requestType.trim() === "Lead") {
        requestIDFound = leadRequestId
    }
    const timeNow = Date.now() // new Date().toISOString()
    const goLive = new Date(goLiveDate) // new Date('2023-08-27').toISOString()
    const expiration = new Date(expirationDate) // new Date('2023-09-27').toISOString()
    const request = {
        companyName: companyName.trim(),
        address: address.trim(),
        address2: address2.trim(),
        city: city.trim(),
        url: url.trim(),
        titlePosition: titlePosition.trim(),
        contactPhone: contactPhone.trim(),
        contactEmail: contactEmail.trim(),
        contactFirstName: contactFirstName.trim(),
        contactLastName: contactLastName.trim(),
        buildingType: buildingType.trim(),
        postalZip: postalZip.trim(),
        provinceState: provinceState.trim(),
        requestRequestTypeId: requestIDFound,
        requestIndustryId: industry,
        requestSubIndustryId: subIndustry,
        requestNotes: requestNotes.trim(),
        goLiveDate: goLive.getTime(),
        expirationDate: expiration.getTime(),
        uploadDate: timeNow.toString(),
    }
    const res = await API.graphql(graphqlOperation(createRequest, { input: request }))
    return { uploadRes: res }
}

export async function getVendorInfo(ID) {
    const response = await API.graphql({ query: getVendor, variables: { id: ID } });
    return { response: response.data.getVendor }
}

export async function updateVendorInfo(id, key, newValue) {
    const requestChanges = {
        id,
        [key]: newValue
    }
    const res = await API.graphql(graphqlOperation(updateVendor, { input: requestChanges }))
    return { response: res }
}


export async function listAllVendors() {
    const allVendors = await API.graphql({ query: listVendors });
    return { allVendors }
}


export default {
    uploadRequests,
    listAllContractRequests,
    listAllServiceRequests,
    saveNotes,
    listRequestNotes,
    addToFavourite,
    removeFavourites,
    updateLeadField,
    addIndustry,
    addSubIndustry,
    editIndustry
}