// component
import { red, purple, pink, yellow, blue, lightGreen, orange, lime, amber, lightBlue } from '@mui/material/colors';
import StarIcon from '@mui/icons-material/Star';
import BarChartIcon from '@mui/icons-material/BarChart';
import CleaningServicesIcon from '@mui/icons-material/CleaningServices';
import RequestPageIcon from '@mui/icons-material/RequestPage';
import UpcomingIcon from '@mui/icons-material/Upcoming';
import DescriptionIcon from '@mui/icons-material/Description';
import NotificationsActiveIcon from '@mui/icons-material/NotificationsActive';
import HistoryIcon from '@mui/icons-material/History';
import AccountBoxIcon from '@mui/icons-material/AccountBox';
import SvgColor from '../../../components/svg-color';

// ----------------------------------------------------------------------


// const icon = (name) => <SvgColor src={`/assets/icons/navbar/${name}.svg`} sx={{ width: 1, height: 1 }} />;

export const navConfig = [
  {
    title: 'favourites',
    path: '/dashboard/favourites',
    icon: <StarIcon sx={{ color: pink.A200 }}/>,
    // icon: icon('ic_user'),
  },
  {
    title: 'service requests',
    path: '/dashboard/servicerequests',
    icon: <CleaningServicesIcon sx={{ color: blue[900] }}/>,
  },
  {
    title: 'contract requests',
    path: '/dashboard/contractrequests',
    icon: <RequestPageIcon sx={{ color: orange[500] }}/>,
  },
  {
    title: 'leads',
    path: '/dashboard/leads',
    icon: <DescriptionIcon sx={{ color: lightGreen.A400 }}/>,
  },
  // {
  //   title: 'new leads - coming soon',
  //   path: '/dashboard/newleads',
  //   icon: <UpcomingIcon sx={{ color: lightGreen.A400 }}/>,
  // },
  // {
  //   title: 'reminders - coming soon',
  //   path: '/dashboard/reminders',
  //   icon: <NotificationsActiveIcon sx={{ color: amber[700] }}/>,
  // },
  // {
  //   title: 'recently viewed',
  //   path: '/dashboard/recent',
  //   icon: <HistoryIcon sx={{ color: blue[900] }}/>,
  // },
  {
    title: 'account',
    path: '/dashboard/account',
    icon: <AccountBoxIcon sx={{ color: purple.A400 }}/>,
  }
];


export const adminConfig = [
  {
    title: 'admin',
    path: '/admin',
    // icon: icon('ic_analytics'),
    icon: <BarChartIcon sx={{ color: purple.A400 }}/>,
  },
  // {
  //   title: 'favourites',
  //   path: '/dashboard/favourites',
  //   icon: <StarIcon sx={{ color: pink.A200 }}/>,
  //   // icon: icon('ic_user'),
  // },
  // {
  //   title: 'service requests',
  //   path: '/dashboard/servicerequests',
  //   icon: <CleaningServicesIcon sx={{ color: lightBlue[200] }}/>,
  // },
  // {
  //   title: 'contract requests',
  //   path: '/dashboard/contractrequests',
  //   icon: <RequestPageIcon sx={{ color: orange[500] }}/>,
  // },
  // {
  //   title: 'new leads',
  //   path: '/dashboard/newleads',
  //   icon: <UpcomingIcon sx={{ color: lightGreen.A400 }}/>,
  // },
  // {
  //   title: 'reminders',
  //   path: '/dashboard/reminders',
  //   icon: <NotificationsActiveIcon sx={{ color: amber[700] }}/>,
  // },
  // {
  //   title: 'recently viewed',
  //   path: '/dashboard/recent',
  //   icon: <HistoryIcon sx={{ color: blue[900] }}/>,
  // },
  {
    title: 'account',
    path: '/dashboard/account',
    icon: <AccountBoxIcon sx={{ color: purple.A400 }}/>,
  }
];

export default { navConfig, adminConfig };
