import React from 'react';
import { Container, Typography } from '@mui/material';

const Terms = () => {
  return (
    <div style={{ marginLeft: 30, marginRight: 30 }}>
      <p>SBS&nbsp;</p>
      <p>Terms and Conditions&nbsp;</p>
      <p>TO WHAT YOU AGREE</p>
      <p>You agree to all the terms and conditions of this Agreement and represent, on behalf of you, your company (including any predecessor entities of your company), employees, and any individuals performing work on your behalf, at the time you become a subscriber or claimer and thereafter, while this Agreement remains in effect, as follows:</p>
      <ul>
        <li>You are, and at all times will be, properly and fully licensed*, bonded, and insured (at levels in accordance with applicable industry standards) under all applicable laws and trade regulations (and upon request will produce documentation to verify this), and further, that you have not been turned down for insurance coverage related to your provision of services. You will notify us promptly of any such changes to your licensing, bonding, or insured status;</li>
      </ul>
      <p>If you or your company are operating under a license with special restrictions, you agree and represent that you will always operate within the guidelines as specified by such restrictions;</p>
      <ul>
        <li>You will comply with the subscription requirements in these Terms, as specified from time to time. If these requirements are materially amended, you will be advised and provided the opportunity to comply with any changes within a reasonable period, as designated by;</li>
      </ul>
      <ul>
        <li>You agree that Savvy Business Strategies holds the right, but not obligation, to monitor the content on the Site and to determine compliance with these Terms and any other operating rules we establish;</li>
      </ul>
      <ul>
        <li>You agree that you are responsible for obtaining and maintaining all telephone, computer hardware, and other equipment needed for access to and use of the Site and/or Services and all charges related thereto. We shall not be liable for any damage to your or any user&rsquo;s, subscribers, claimers, or service professional&rsquo;s equipment, or for any data charges resulting from use of the site;</li>
      </ul>
      <ul>
        <li>You hereby represent that you, any predecessor entities of your company, any other majority shareholders, partners or members, and your company, are free from any (i) bankruptcies, or civil legal judgments within the last 3 years, and (ii) any criminal convictions, OR you have provided Savvy Business Strategies with an accurate and truthful written statement detailing any exceptions. If at any time while you are a Savvy Business Strategies subscriber or claimer, you and any predecessor entities of your company, any other majority shareholders, partners or members, or your company, should file for bankruptcy or be involved in bankruptcy proceedings, have a civil legal judgment entered against such party or a criminal conviction entered against such party, you will promptly notify Savvy Business Strategies. You further represent that you have not been sanctioned or penalized by any governmental authorities in connection with your provision of services to any of your customers, and that you have not been denied membership to (or had your membership revoked from) any professional industry associations. You authorize Savvy Business Strategies to verify that all the above representations are truthful and accurate at all times while this Agreement remains in effect;</li>
      </ul>
      <ul>
        <li>You agree that we shall have the right to disclose any information we have regarding you and your company we believe necessary to satisfy any law, regulation, or governmental request for information from us regarding any work or services you have performed for Users;</li>
      </ul>
      <ul>
        <li>You understand and agree that you are solely responsible for your interactions with other Users, Members, Claimers, or Service Professionals. We reserve the right, but are under no obligation, to monitor disputes between you and other Users, Members, Subscribers, Claimers, or Service Professionals;</li>
      </ul>
      <ul>
        <li>We reserve the right to charge you for any amounts paid to a User pursuant to a dispute between you and a user or based upon your performance of or failure to perform services for a user, and you agree to reimburse us for any such payments.</li>
      </ul>
      <ul>
        <li>You agree that if another User, Member, Claimer, or Service Professional breaches any obligation to you, you are solely responsible for enforcing any right that you may have. Savvy Business Strategies is not responsible for enforcing any rights under a contract between you and another User, Member, Subscriber, Claimer, or Service Professional;</li>
      </ul>
      <ul>
        <li>You agree that you are responsible for maintaining the confidentiality of all passwords associated with your use of the site and/or subscription and are solely responsible for all activities resulting from the use of your password and conducted through your subscriber and account;</li>
      </ul>
      <ul>
        <li>If you provide any quotes to Users through the Site, such quotes are provided for informational purposes only. A User cannot contract with you via the Site. In the event a User selects you from our Site in response to a quote you provide (or schedules an appointment), such selection (or appointment) is merely an indication of the User's interest in contacting or being contacted by you;</li>
      </ul>
      <ul>
        <li>If you provide written, verbal, telecommunication, audio, video recordings, comments, or testimonials about service(s) or products, you agree that we shall have sole ownership of any and all intellectual property rights in such comments or testimonials, and that we may post and publish your comments or portions thereof at our sole discretion on our site or in marketing materials, including your name and company or agency, and that you shall not be entitled to any payments associated with our use of the foregoing;</li>
      </ul>
      <ul>
        <li>You hereby authorize us to use your name, company name, and franchise name, as applicable, and any of the trademarks, service marks, trade names and logos, content including photographs or audio video recordings, in the form or format that you supply to us or that you upload to our site, for use and posting on our site and for use in marketing materials to be promoted to users through online postings, via emails or otherwise, to help promote you or your services, and that you shall not be entitled to any payments associated with our use of the foregoing;</li>
      </ul>
      <ul>
        <li>You represent that you hold all necessary rights to display any of the logos, service marks, trademarks, and any other content that you upload to our site, and you represent that your uploading for display of any such content, and the use by Savvy Business Strategies of such content, as contemplated by this Agreement, shall not violate any third party's intellectual property rights. If there are any limitations or restrictions pertaining to the use or presentation of such logos, trade or service marks, it shall be your responsibility to provide us, in writing, with any such restrictions or limitations of use;</li>
      </ul>
      <ul>
        <li>During the term of this Agreement, you may be authorized to use certain marks of Savvy Business Strategies, as necessary to promote your business, provided authorization is granted by us in writing in advance and at our sole discretion. You are expressly prohibited from registering any trademarks or domain names that would belong to Savvy Business Strategies;</li>
      </ul>
      <ul>
        <li>Savvy Business Strategies reserves the right, in its sole discretion, to review, reject and remove any content that you upload to the site or your company profile;</li>
      </ul>
      <ul>
        <li>You represent that you own or have the necessary permissions to use and authorize the use of your content as described herein. You understand and agree that you are responsible for your content and, once published, you must contact Savvy Business Strategies directly to have it removed. You assume any risk associated with your content, including another user&rsquo;s reliance on its accuracy or reliability, or any information in your content that may make you identifiable;</li>
      </ul>
      <ul>
        <li>You understand and acknowledge that you may expose yourself to liability if, for example, your content contains material that is: false, intentionally misleading, or defamatory; violates any third-party right, including any copyright, trademark, patent, trade secret, moral right, privacy right, right of publicity, or any other intellectual property or proprietary right; contains material that is unlawful, including illegal hate speech or pornography; exploits or otherwise harms minors; or violates or advocates the violation of any law or regulation. We reserve the right to remove, screen, edit, or reinstate any content from time to time, in our sole discretion, without notice to you;</li>
      </ul>
      <ul>
        <li>You own your content, and we own the Savvy Business Strategies content, including, but not limited to, visual interfaces and graphics, interactive features, design features, compilation, products, computer code, software, user ratings and reviews, and any and all other multimedia elements and/or components of the site, excluding your content, the user content, and any third party content displayed on the site. We own the copyrights, trademarks, trade names, service marks, and other intellectual and proprietary rights associated with the Savvy Business Strategies content and the site throughout the world, which are thereto protected under copyright, trade dress, patent, trademark laws and all other applicable intellectual and proprietary rights. As such, you may not reproduce, modify, create derivative works or adaptations thereof, distribute, publicly display or in any way exploit any of the Savvy Business Strategies content, in whole or in part, except as expressly authorized by us or our affiliates. Except as expressly set forth herein, we do not grant you any express or implied rights, authorizations, or licenses, and all rights in and to the site and the Savvy Business Strategies content are ours;</li>
      </ul>
      <ul>
        <li>We may use your Content in many ways. This includes publicly displaying it, reformatting it, incorporating it into advertisements, distributing it, and allowing others to do the same on their own websites and media platforms. You hereby irrevocably grant us royalty-free, perpetual, irrevocable, non-exclusive, world-wide rights and license rights to use your content for any purpose, and you hereby represent and warrant to Savvy Business Strategies that you hold all the rights, licenses, authorizations necessary to grant such license, thereby irrevocably granting the users of the site and any third party publicly accessing the site, right to access your content in connection with their use of the site and any service thereon. Finally, you irrevocably waive, and cause to be waived, against Savvy Business Strategies and its users any claims and assertions of moral rights or attribution with respect to your Content. By "use" we mean use, copy, publicly perform or display, distribute, modify, translate, and create derivative works of your content;</li>
      </ul>
      <ul>
        <li>You acknowledge and agree that all of the content and information posted on the site, including but not limited to company profiles, screening information, and user ratings and reviews for all Savvy Business Strategies service professionals, members, subscribers, and claimers, excluding any logos or trademarked materials or other intellectual property of service professionals provided by service professionals), is the sole and exclusive property of Savvy Business Strategies. You acknowledge and agree that you have no right to reproduce, post, publish, display or otherwise use any user ratings and reviews (including those relating to you and your business), or any other content posted on the site, other than content provided directly by you;</li>
      </ul>
      <ul>
        <li>You represent and warrant that all the information you provide to Savvy Business Strategies, including all information you include on your company profile, is true and accurate. You agree to maintain and update such information to keep it accurate. You acknowledge that a violation of any of the foregoing could result in significant damages, and you agree that you are liable to Savvy Business Strategies for any such damages and will indemnify Savvy Business Strategies in the event of any third-party claims against Savvy Business Strategies based on or arising from your violation of the foregoing. If you provide any information that is inaccurate, or Savvy Business Strategies has reasonable grounds to believe is inaccurate, Savvy Business Strategies may suspend or terminate your use of the site and/or subscription and/or decline to permit your continued and/or future use of the site and/or future subscription;</li>
      </ul>
      <ul>
        <li>You understand and agree that the individual whose email address is associated with an account at the time of registration must have the authority to bind you to this Agreement;</li>
      </ul>
      <ul>
        <li>You acknowledge and agree that Savvy Business Strategies has the right, in its sole discretion, to contact users about services you provide to gain their feedback and rating of you.</li>
      </ul>
      <ul>
        <li>Savvy Business Strategies may publicly display advertisements and other information adjacent to, or included with, your Content. You are not entitled to any compensation for such advertisements. The manner, mode and extent of such advertising is subject to change without specific notice to you. These advertisements may be tailored to the content of the site, and the nature and extent of such advertising by us is subject to change without prior notice.</li>
      </ul>
      <ul>
        <li>You will not engage in any illegal acts or acts of wrongdoing, dishonesty or unethical business practices with Savvy Business Strategies, any user, or other third party, including, but not limited to, disclosing any user personal information to any third party. You will at all times be in full compliance with all applicable Federal, Provincial, local and other laws and regulations that apply to your activities. YOU ACKNOWLEDGE AND AGREE THAT TO KNOWINGLY SHARE, DISTRIBUTE, TRANSFER, OR SELL A SAVVY BUSINESS STRATEGIES LEAD (OR THE INFORMATION CONTAINED THEREIN), OTHER THAN AS EXPRESSLY SET FORTH IN THIS AGREEMENT, IS A VERY SERIOUS BREACH OF CONTRACT AND FRAUDULENT MATTER THAT COULD RESULT IN INVASION OF PRIVACY RIGHTS OR OTHERS, SIGNIFICANT COSTS AND DAMAGES TO OTHERS AND TO SAVVY BUSINESS STRATEGIES AND OTHER SERVICE PROFESSIONAL PARTNERS. RESPONDING TO SUCH VIOLATION WOULD ALSO RESULT IN THE LOSS OF TIME AND EFFORT ON THE PART OF SAVVY BUSINESS STRATEGIES. THERE ALSO MAY BE REGULATORY FINES AND PENALTIES IMPOSED FOR CONTACTING USERS AND BUSINESSES IN A MANNER NOT IN ACCORDANCE WITH THE APPLICABLE LAWS AND REGULATIONS, INCLUDING, BUT NOT LIMITED TO, FEDERAL AND PROVINCIAL DO-NOT-CALL REGULATIONS AND FEDERAL ANTI-SPAM REGULATIONS. ACCORDINGLY, IF YOU KNOWINGLY SHARE, DISTRIBUTE, TRANSFER, OR SELL A SERVICE REQUEST, OR LEAD (OR THE INFORMATION CONTAINED THEREIN), OTHER THAN AS EXPRESSLY SET FORTH IN THIS AGREEMENT, YOU AGREE TO FULLY INDEMNIFY AND BE LIABLE TO SAVVY BUSINESS STRATEGIES, AS SET FORTH BELOW, FOR ALL THE DAMAGES, WHETHER DIRECT OR INDIRECT, PUNITIVE AND/OR CONSEQUENTIAL, AND ANY REGULATORY OR JUDICIAL FINES OR PENALTIES THAT MAY ARISE FROM SUCH ACTIVITIES;</li>
      </ul>
      <ul>
        <li>You agree to allow only authorized employees of your company, or independent contractors performing services directly on behalf of your business, to contact or provide any service to users you learn of via a lead. To the extent you use any such subcontractors, you shall be responsible and liable for all acts and omissions of such subcontractors and for ensuring that such subcontractors comply with all the provisions of this Agreement;</li>
      </ul>
      <ul>
        <li>You agree not to sell, trade, gift, assign, or otherwise transfer any leads provided by Savvy Business Strategies to any other party, including any other Savvy Business Strategies Service Professional;</li>
      </ul>
      <ul>
        <li>In the event you participate in any promotion whereby Savvy Business Strategies promotes an offer or discount related to your services, whether on the Savvy Business Strategies Site, or otherwise, you agree to abide by the terms and conditions of such offer or discount;</li>
      </ul>
      <ul>
        <li>By becoming to a Savvy Business Strategies Subscriber, claimer, or otherwise using the Savvy Business Strategies site or services, and/or by inquiring about a Savvy Business Strategies Subscription or other Savvy Business Strategies products or services, you are requesting, and you expressly consent to being contacted by us and by our agents and representatives via phone, email, mail, or other reasonable means, at any of your contact numbers or addresses, even if you have previously opted-out from receiving marketing emails from Savvy Business Strategies, in order that we may provide the services set forth on our site, to service your account, to reasonably address matters pertaining to your account, or for other purposes reasonably related to our business. You agree to sign up for at least two (2) methods of lead notification (for example SMS and email) and acknowledge that if you do not sign up for at least two (2) methods of lead notification, Savvy Business Strategies is not responsible for any delayed or undelivered leads. You may also subscribe to marketing emails, as well as our e-mail newsletter, or emails from third parties made available through Savvy Business Strategies. You may opt out of non-essential communications at any time.</li>
      </ul>
      <p>RESTRICTIONS.</p>
      <p>You may not use the Site to bully or harass Savvy Business Strategies users, subscribers, claimers, or other service professionals, upload illegal material, modify site content, send spam, or hack into user information.</p>
      <p>In connection with your use of the site, you agree that you shall not:</p>
      <ul>
        <li>Help, motivate, or enable others to infringe the terms and conditions of this Agreement, or Savvy Business Strategies terms;</li>
      </ul>
      <ul>
        <li>Impersonate any person or entity, including, without limitation, a Savvy Business Strategies agent;</li>
      </ul>
      <ul>
        <li>Plagiarize or infringe the intellectual property rights of a third party, including any breach of confidence, copyright, trademark, patent, trade secret, moral right, privacy right, right of publicity, personality rights or any other intellectual property or proprietary right;</li>
      </ul>
      <ul>
        <li>Use any automated process to obtain, copy, process, access, or use our services or any process, whether automated or manual, to capture data or content from any of our users, content or services for any reason;</li>
      </ul>
      <ul>
        <li>Collect, receive, transfer, or disseminate any personally identifiable information of any user of the site without the express authorization of the holder;</li>
      </ul>
      <ul>
        <li>Engage in keyword spamming of reviews or profile text, or otherwise attempt to manipulate any site's search results, write fake, or defamatory content or trade reviews with other businesses;</li>
      </ul>
      <ul>
        <li>Reverse engineer, decompile, or disassemble the site, or to convert into human readable form any of the contents of this site not intended to be so read, including, but not limited to, using or directly viewing the underlying code for the Site except as interpreted and displayed in the browser;</li>
      </ul>
      <ul>
        <li>Upload, post, or otherwise disseminate any content that: (i) infringes or violates the intellectual property rights of any third party, including any trademark, copyright, trade secret, moral rights, ancillary rights or other rights therefrom, or causes or results in the violation of any applicable law or regulation; (ii) in any way exploits the site other than as specifically sanctioned by us; (iii) encourages any conduct that could promote or cause discrimination, racism, hatred, harm, or violence against any individual or group of persons; (iv) threatens minors in any manner; (v) promotes or encourages illegal, fraudulent or inappropriate activities; and/or (vi) promotes or disseminates any content that is or may be considered obscene, pornographic, inappropriate or otherwise objectionable;</li>
      </ul>
      <ul>
        <li>Modify, adapt, appropriate, reproduce, distribute, translate, create derivative works or adaptations of, publicly display, sell, trade, or in any way exploit the site content, except as expressly authorized by Savvy Business Strategies;</li>
      </ul>
      <ul>
        <li>Remove or modify any watermark, copyright, trademark, or other proprietary rights notice that appears on any image, banner, frame, content, or portion of the site or to retrieve or index any portion of the site;</li>
      </ul>
      <ul>
        <li>Deliver or attempt to deliver, any unsolicited advertising, electronic email, promotion, spam, or chain letters;</li>
      </ul>
      <ul>
        <li>Use the site to transfer to other users any type of computer viruses, worms, defects, trojan horses or other items of a destructive nature;</li>
      </ul>
      <ul>
        <li>Take any action that will or may cause an unreasonably large load on Savvy Business Strategies&rsquo; technology infrastructure or otherwise make excessive traffic demands of the Site;</li>
      </ul>
      <ul>
        <li>Bully, intimidate, harass, put undue pressure, initiate a legal action, threaten a legal action against any User of the Site;</li>
      </ul>
      <ul>
        <li>Use the Site for any purpose that is unlawful or prohibited by the terms and conditions of this Agreement;</li>
      </ul>
      <ul>
        <li>Use the Site in any manner that could interfere with any other User's use and enjoyment of the Site; or</li>
      </ul>
      <ul>
        <li>Attempt to gain unauthorized access to any of the site, services, accounts, computer systems or networks connected to Savvy Business Strategies through hacking, password mining, or any other means.</li>
      </ul>
      <p>Savvy Business Strategies reserves the right to decide what data, content, or information is published to, or removed from, the site is deemed to violate any of the above restrictions.</p>
      <p>INTELLECTUAL PROPERTY.</p>
      <ul>
        <li>Limited License and Copyright &ndash; Subject to the terms and conditions of this Agreement, you grant Savvy Business Strategies a non-exclusive, non-transferable, limited right to access, use and display this site and the content thereon and to view and download the content, only in connection with your personal and non-commercial use of the Site. This authorization is not a transfer of title in the content and is subject to the restrictions in this Agreement;</li>
      </ul>
      <ul>
        <li>Trade-marks &ndash; The trade-marks, logos, and company names of Savvy Business Strategies, or any of its affiliates, used on this site may not be copied, imitated, or used, in whole or in part, without the prior written consent of Savvy Business Strategies or any such affiliate. Other products, services, logos, and company names mentioned on this Site may be the trade-marks of their respective owners. Except as expressly provided herein, Savvy Business Strategies and its affiliates do not grant any express or implied right or license to you under any intellectual property right, including under any patent, trade-mark, copyright, trade secret, or confidential information;</li>
      </ul>
      <ul>
        <li>Non-infringement &ndash; You may not post, or otherwise make available, to the Site any material that is protected by a copyright, trade-mark, or other proprietary right without the express permission of the owner. You agree to assume sole liability and to indemnify Savvy Business Strategies for any damage resulting from infringement of any third party's copyrights, trade-marks, or other proprietary rights or any other harm resulting from your use of such infringing materials in a review, or on or in connection with the Site.</li>
      </ul>
      <p>FEES.</p>
      <ul>
        <li>Term &ndash; You agree that the initial term of your Savvy Business Strategies Subscription is effective for (1) consecutive months;</li>
      </ul>
      <ul>
        <li>Subscription Payment &ndash; Payment may be made either in-full-in-advance (for a term of twelve (12) months) or month-by-month via an approved payment method, as set out by Savvy Business Strategies. Any payment made in-full-in-advance is wholly and completely non-refundable;</li>
      </ul>
      <ul>
        <li>Lead Payment &ndash; Subscribers may purchase Leads through the Site;</li>
      </ul>
      <ul>
        <li>Refusal &ndash; You understand and agree that Savvy Business Strategies reserves the right to cancel your Savvy Business Strategies subscription if it deems, in its sole and absolute discretion, to be inappropriate in any way;</li>
      </ul>
      <ul>
        <li>Termination &ndash; You understand and agree that Savvy Business Strategies, in its sole and absolute discretion, reserves the right to downgrade your subscription or terminate your subscription and account at any time without notice. You may terminate your subscription at any time, with sixty (30) days' written notice. Any payment made in-full-in-advance is wholly and completely non-refundable;</li>
      </ul>
      <ul>
        <li>You acknowledge and agree that it is your responsibility to ensure that the communication methods that you have selected in your Savvy Business Strategies account, and all contact and billing information, are kept up-to-date and accurate. Savvy Business Strategies is not responsible, or liable, for undelivered account/subscriber notifications. You agree to promptly notify Savvy Business Strategies if your payment method is cancelled (including if you lose your card or it is stolen), or if you become aware of a potential breach of security (such as an unauthorized disclosure or use of your payment method). In addition, you authorize us to obtain updated or replacement expiration dates and card numbers for you credit or debit card as provided by your credit or debit card issuer;</li>
      </ul>
      <ul>
        <li>You acknowledge and agree that you will not receive a detailed account statement unless you provide Savvy Business Strategies with a valid email address. Your account balance is also available by emailing info@savvybusinessstrategies.com. We will cancel your paid subscription if your account is 60 days overdue. You are then responsible for paying the amount owing if you wish to reinstate your subscription;</li>
      </ul>
      <ul>
        <li>Any disputes about charges to your account must be submitted to Savvy Business Strategies in writing within thirty (30) days of the date such charges are incurred. You agree to waive all disputes not made within the thirty (30) day period, and all such charges will be final and not subject to challenge;</li>
      </ul>
      <ul>
        <li>Past due accounts may be sent to a third-party collection agency and reported to a credit rating agency and we may bill you for, and you agree to pay for, any and all collection and related litigation fees;</li>
      </ul>
      <ul>
        <li>No fee is due or payable to the extent such fee is in violation of any applicable law;</li>
      </ul>
      <ul>
        <li>You authorize Savvy Business Strategies to charge you for any sales or similar taxes that may be imposed on your Savvy Business Strategies subscription payments, lead fees, or any other fees charged by Savvy Business Strategies.</li>
      </ul>
      <p>INDEMNIFICATION, LIMITATION OF LIABILITY, AND DISCLAIMER OF WARRANTIES.</p>
      <ul>
        <li>Indemnification. You shall fully protect, indemnify and defend Savvy Business Strategies and all of its agents, officers, directors, shareholders, suppliers, partners, employees and each of their successors and assigns ("Indemnified Parties") and hold each of them harmless from and against any and all claims, demands, liens, damages, causes of action, liabilities of any and every nature whatsoever, including but not limited to personal injury, intellectual property infringement, fraud, deceptive advertising, violation of any state, provincial or federal laws or regulations, property damage, attorneys' fees and court costs, arising in any manner, directly or indirectly, out of or in connection with or in the course of or incidental to any of your work for or dealings with any customers, or your services, representations, advertising, or obligations (including but not limited to your obligation to maintain the confidentiality of user personal information) as provided in this Agreement, including any extra work, REGARDLESS OF CAUSE OR OF ANY FAULT OR NEGLIGENCE OF SAVVY BUSINESS STRATEGIES OR THE INDEMNIFIED PARTIES AND WITHOUT REGARD TO CAUSE OR TO ANY CONCURRENT OR CONTRIBUTING FAULT, STRICT LIABILITY OR NEGLIGENCE, WHETHER SOLE, JOINT OR CONCURRENT, ACTIVE OR PASSIVE BY HOMESTARS OR THE INDEMNIFIED PARTIES.</li>
      </ul>
      <ul>
        <li>In no event shall Savvy Business Strategies or its affiliates, agents, licensors, suppliers or their respective directors, officers or employees be liable to you for any direct, indirect, incidental, special, punitive, and/or consequential damages whatsoever, whether based on warranty, contract, tort, or any other legal theory, and whether or not you are advised of the possibility of such damages. The foregoing limitation of liability shall apply to the fullest extent permitted by law in the applicable jurisdiction. Savvy Business Strategies&rsquo; maximum cumulative liability to you for any losses or damages arising out of, or in connection with, your access to the Site and any services therein shall be limited to the lesser of: (i) the amount paid, if any, by you to us in connection with the site and any services therein during the twelve (12) months prior to the action giving rise to such liability (if any); or (ii) CDN $50.00 (fifty Canadian dollars);</li>
      </ul>
      <ul>
        <li>The use of this site is at your own risk and Savvy Business Strategies assumes no liability or responsibility pertaining to the content, your use of the site, or the receipt, storage, transmission, or other use of your personal information;</li>
      </ul>
      <ul>
        <li>The Content and information accessible through this site may contain inaccuracies and typographical errors. Savvy Business Strategies and its affiliates make no representations or warranties in respect of the accuracy or completeness of the content or information accessible on or through this site, or the reliability of any advice, opinion, statement, or other information displayed or distributed through this site. Savvy Business Strategies assumes no responsibility for the accuracy and completeness of any review. You acknowledge and agree that any reliance on any of the foregoing shall be at your sole risk. Savvy Business Strategies will not be responsible to you or any third party for any damages, expenditures, loss of profits, or prospective profits of any kind or nature sustained or arising out of, or alleged to have been sustained, or to have arisen out of your use of this Site;</li>
      </ul>
      <ul>
        <li>Your use of our site is at your own risk, and therefore you hereby acknowledge and agree that we supply our services &ldquo;as is&rdquo;, &ldquo;with all faults&rdquo;, and &ldquo;as available&rdquo;, including all content, software, materials, services, functions, and/or information made available through the site. Savvy Business Strategies is not responsible for any content or information that you may find undesirable or objectionable. Savvy Business Strategies hereby disclaims any and all liability arising out of or related to any purported facts or information and description of any products displayed on our site, including all warranties of any kind, either express or implied, including, without limitation, warranties of title, merchantability, fitness for a particular purpose, or non-infringement. Neither Savvy Business Strategies , nor its employees, affiliates, contractors and/or agents warrantee that the site will be error-free, uninterrupted, secure, or produce particular results, that a quote is current, valid and/or un-expired, or that the information obtained therefrom will be reliable or accurate. No advice or information given by Savvy Business Strategies or its employees, affiliates, contractors and/or agents shall create a guarantee;</li>
      </ul>
      <ul>
        <li>If you are dissatisfied with the site and the content thereon, or the terms of this Agreement, your sole and exclusive remedy is to discontinue use of the site.</li>
      </ul>
      <p>LEGAL DISPUTES.</p>
      <p>Savvy Business Strategies is committed to resolving disputes quickly in a cost effective and neutral way. In connection with any dispute that arises out of this Agreement or our services, you agree to the following:</p>
      <ul>
        <li>Get In Touch &ndash; It is our belief that many disputes can be resolved amicably. We encourage you to contact us by email before resorting to alternatives;</li>
      </ul>
      <ul>
        <li>Improperly Filed Claims &ndash; Claims you bring against Savvy Business Strategies must be resolved in accordance with this Legal Disputes Section. A claim filed or brought contrary to the Legal Disputes Section shall be considered improperly filed. A claim filed contrary to the Legal Disputes Section may allow Savvy Business Strategies to recover legal fees, provided that Savvy Business Strategies has notified you in writing of the improperly filed claim and you have failed to promptly have the claim withdrawn;</li>
      </ul>
      <ul>
        <li>Best Efforts &ndash; Both parties agree to use their best efforts to settle any dispute, Claim, question, or disagreement directly through good-faith negotiations, which shall be a precondition to either party initiating a lawsuit or arbitration. This includes, but is not limited to, the provision of all relevant information and documentation concerning any Claim within a timely manner.</li>
      </ul>
      <ul>
        <li>NO CLASS ACTIONS OR ARBITRATIONS &ndash; YOU AGREE TO RESOLVE ANY CLAIMS RELATING TO THIS AGREEMENT OR OUR SERVICES ON AN INDIVIDUAL BASIS, AND MAY NOT BRING A CLAIM AS A PLAINTIFF OR A CLASS MEMBER IN A CLASS, CONSOLIDATED, OR REPRESENTATIVE ACTION. CLASS ARBITRATIONS, CLASS ACTIONS, AND CONSOLIDATION WITH OTHER ARBITRATIONS ARE NOT PERMITTED.</li>
      </ul>
      <ul>
        <li>ARBITRATION AND GOVERNING LAW.</li>
      </ul>
      <ul>
        <li>Law and Forum for Legal Disputes &ndash; This Agreement shall be governed in all respects by the laws of the Province of Ontario, Canada as they apply to agreements entered into and to be performed entirely within Ontario between Ontario residents, without regard to conflict of law provisions. You understand and agree that any Claim or dispute you may have against Savvy Business Strategies must be resolved exclusively by a Provincial or Federal court located in Toronto, Ontario, Canada, except as otherwise agreed by the parties or as described in the Arbitration Option paragraph below. For the purpose of litigating all such Claims or disputes, you agree to submit to the personal jurisdiction of the courts located within Toronto, Ontario, Canada;</li>
      </ul>
      <ul>
        <li>Arbitration Option &ndash; For any Claim (excluding Claims for injunctive or other equitable relief) totalling an amount of less than CDN $25,000.00 the party requesting relief will elect to resolve the dispute through binding non-appearance-based arbitration. Arbitration shall be initiated through an established alternative dispute resolution (ADR) provider which shall be mutually agreed upon by all parties. The following rules shall be complied with by the ADR provider and the parties: (a) the arbitration shall be conducted by telephone, online, and/or be solely based on written submissions. The initiating party shall choose the specific manner by which the arbitration will be conducted; (b) the arbitration shall not involve any personal appearances by the parties or witnesses, unless otherwise mutually agreed by the parties; and (c) any judgment on the award rendered by the arbitrator may be entered in any court of competent jurisdiction.</li>
      </ul>
      <p>GENERAL.</p>
      <ul>
        <li>ENTIRE AGREEMENT &ndash; THESE TERMS AND CONDITIONS OF THE AGREEMENT CONSTITUTE THE ENTIRE AGREEMENT AND UNDERSTANDING BETWEEN YOU AND SAVVY BUSINESS STRATEGIES AND GOVERNS YOUR USE OF THE SITE AND SERVICES PROVIDED THEREIN, SUPERSEDING ANY PRIOR AGREEMENTS BETWEEN YOU AND SAVVY BUSINESS STRATEGIES, WHETHER WRITTEN OR ORAL. THE SECTION AND PARAGRAPH HEADINGS IN THIS AGREEMENT ARE FOR CONVENIENCE ONLY AND WILL NOT AFFECT THEIR INTERPRETATION;</li>
      </ul>
      <ul>
        <li>Severability and Waiver &ndash; If any provision of this Agreement is held unenforceable by a court of competent jurisdiction, then such provision will be modified to reflect the parties' intention. All remaining provisions of this Agreement will remain in full force and effect. The failure of Savvy Business Strategies to exercise, in any respect, any right provided for herein will not be deemed a waiver of any further rights hereunder. No waiver by Savvy Business Strategies of any breach or default hereunder shall be deemed a waiver of any preceding or subsequent breach or default;</li>
      </ul>
      <ul>
        <li>Assignment of Rights &ndash; Savvy Business Strategies may assign any or all of its rights hereunder to any party without your consent. You are not permitted to assign any of your rights or obligations under this Agreement without the prior written consent of Savvy Business Strategies, and any such attempted assignment will be void and unenforceable.</li>
      </ul>
      <ul>
        <li>Force Majeure &ndash; Savvy Business Strategies will not be liable for any failure to perform our obligations hereunder, where such failure results from any cause beyond our reasonable control, including, without limitation, mechanical, electronic, or communications failure or degradation;</li>
      </ul>
      <ul>
        <li>Relationship &ndash; You and Savvy Business Strategies are independent contractors, and no agency, partnership, joint venture, employee-employer, or franchiser-franchisee relationship is intended or created by this Agreement.</li>
      </ul>
      <ul>
        <li>Cancellation &ndash; Savvy Business Strategies may, at its sole discretion, cancel or suspend any of your privileges related to the use of the Site, in whole or in part, for any reason, including, without limitation, the non-compliance with the Terms of this Agreement.</li>
      </ul>
      <ul>
        <li>Acceptance &ndash; By agreeing you confirm that you have read and understood these Terms and that you agree to be bound by these and other Terms incorporated by reference above</li>
      </ul>
      <p><br /><br /></p>
    </div>
  );
};

export default Terms;
