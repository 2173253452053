import React, { useState, useEffect } from 'react';
import { Typography, TextField, Button, List, ListItem, ListItemText, ListItemSecondaryAction, IconButton, Dialog, DialogTitle, DialogContent, DialogActions } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';


import { listAllIndustries, addIndustry, editIndustry } from '../../awsFuncCalls'

function AddIndustry() {
    const [newIndustryName, setNewIndustryName] = useState('');
    const [industries, setIndustries] = useState([])
    const [editIndustryId, setEditIndustryId] = useState(null);
    const [editedIndustryName, setEditedIndustryName] = useState('');

    useEffect(() => {
        const asyncFunction = async () => {
            try {
                const res = await listAllIndustries()
                const tempArr = res.industries.sort((a, b) => a.title.toUpperCase().localeCompare(b.title.toUpperCase()))
                setIndustries(tempArr)
                // setFilteredRequests(tempArr)
                return true
            } catch (error) {
                console.log(error)
                return error;
            }
        }
        asyncFunction();
    }, []);

    const handleAddIndustry = async () => {
        if (newIndustryName.trim() !== '') {
            const res = await addIndustry(newIndustryName.trim())
            setIndustries([...industries, res.newInd]);
            setNewIndustryName('');
        }
    };

    const handleEditIndustry = (id, name) => {
        setEditIndustryId(id);
        setEditedIndustryName(name);
    };

    const handleSaveEdit = async (id) => {
        if (editedIndustryName.trim() !== '') {
            const res = await editIndustry(editedIndustryName.trim(), id)
            if(res.success) {
                const updatedIndustries = industries.map(industry => {
                    if (industry.id === id) {
                        return { ...industry, title: editedIndustryName.trim() };
                    }
                    return industry;
                });
                setIndustries(updatedIndustries);
                setEditIndustryId(null);
                setEditedIndustryName('');
            }
        }
    };

    const handleCancelEdit = () => {
        setEditIndustryId(null);
        setEditedIndustryName('');
    };

    const handleDeleteIndustry = id => {
        const updatedIndustries = industries.filter(industry => industry.id !== id);
        setIndustries(updatedIndustries);
    };

    return (
        <div>
            <Typography variant="h3">Industries</Typography>
            <List>
                {industries.map(industry => (
                    <ListItem key={industry.id}>
                        {editIndustryId === industry.id ? (
                            <>
                                <TextField
                                    value={industry.title}
                                    fullWidth
                                    variant="outlined"
                                    size="small"
                                />
                                <ArrowForwardIcon />
                                <TextField
                                    value={editedIndustryName}
                                    onChange={e => setEditedIndustryName(e.target.value)}
                                    fullWidth
                                    variant="outlined"
                                    size="small"
                                />
                                <Button onClick={()=> handleSaveEdit(industry.id)} variant="contained" color="primary">Save</Button>
                                <Button onClick={handleCancelEdit} variant="contained" color="secondary">Cancel</Button>
                            </>
                        ) : (
                            <>
                                <ListItemText primary={industry.title} key={industry.title} />
                                <ListItemSecondaryAction>
                                    <IconButton onClick={() => handleEditIndustry(industry.id, industry.name)}>
                                        <EditIcon />
                                    </IconButton>
                                    {/* <IconButton onClick={() => handleDeleteIndustry(industry.id)}>
                                        <DeleteIcon />
                                    </IconButton> */}
                                </ListItemSecondaryAction>
                            </>
                        )}
                    </ListItem>
                ))}
            </List>
            <TextField
                value={newIndustryName}
                onChange={e => setNewIndustryName(e.target.value)}
                fullWidth
                label="Enter new industry"
                variant="outlined"
                size="small"
            />
            <Button onClick={handleAddIndustry} variant="contained" color="primary">Add</Button>
        </div>
    );
}

export default AddIndustry;
