import React from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import Grid from '@mui/material/Grid';
import { Typography } from '@mui/material';

function LoadingImage(props) {

  return (
    <Grid
      container
      flexDirection='column'
      alignItems='center'
      justifyContent='center'
    >
      <div>
        <CircularProgress />
      </div>
      {
        props.dataPage &&
        <div style={{ marginTop: 20, justifyContent: 'center', textAlign: 'center', }}>
          <Typography variant="h5" fontWeight="bold">
            Loading some really great data to help drive your business forward...
          </Typography>
        </div>
      }
    </Grid>
  );
}

export default LoadingImage;
