
import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import Grid from '@mui/material/Grid';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import MenuItem from '@mui/material/MenuItem';
import * as Icons from '@mui/icons-material';

import { Link } from 'react-router-dom'


import mainLogo from '../../assets/images/sbslogo.png';

const settings = ['Profile', 'Account', 'Dashboard', 'Logout'];

function ResponsiveAppBar(props) {

    const pages = props.userLoggedIn ? [
        {
            url: 'home',
            title: "Home",
            icon: "Home",
        },
        {
            url: 'company',
            title: "Company",
            icon: "Apartment",
        },
        {
            url: 'resources',
            title: "Resources",
            icon: "Launch",
        },
        {
            url: 'faq',
            title: "FAQ",
            icon: "QuestionMark",
        },
    ] : [
        {
            url: 'home',
            title: "Home",
            icon: "Home",
        },
        {
            url: 'company',
            title: "Company",
            icon: "Apartment",
        },
        {
            url: 'resources',
            title: "Resources",
            icon: "Launch",
        },
        {
            url: 'faq',
            title: "FAQ",
            icon: "QuestionMark",
        }
    ];

    const [anchorElNav, setAnchorElNav] = React.useState(null);
    const [anchorElUser, setAnchorElUser] = React.useState(null);
    const [anchorEl, setAnchorEl] = React.useState(null);

    const IconResolver = ({ iconName, ...props }) => {
        const IconComponent = Icons[iconName];

        if (!IconComponent) {
            console.error(`Icon "${iconName}" not found`);
            return null; // You can return a default icon or an empty element here
        }

        return <IconComponent {...props} />;
    };

    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <>
            <AppBar
                position="static"
                elevation={0}
                style={{ background: 'transparent', boxShadow: 'none' }}
            >
                {/* <Grid container > */}
                <Toolbar disableGutters>

                    {/* ******* SMALL SCREEN SIZE ******* */}
                    <Box sx={{ flexGrow: 1, display: { xs: 'flex', lg: 'none' } }}>
                        <Grid container>
                            <Grid item xs={6} >
                                <img src={mainLogo} alt="logo" style={{ height: '80px', marginTop: '1rem', marginBottom: '1rem' }} />
                            </Grid>
                            <Grid item container xs={6} alignItems='center' justifyContent="flex-end"  >
                                <IconButton
                                    size="large"
                                    aria-label="account of current user"
                                    aria-controls="menu-appbar"
                                    aria-haspopup="true"
                                    onClick={handleClick}
                                    color="black"
                                >
                                    <MenuIcon />
                                </IconButton>
                                <Menu
                                    anchorEl={anchorEl}
                                    id="account-menu"
                                    open={open}
                                    onClose={handleClose}
                                    onClick={handleClose}
                                    PaperProps={{
                                        elevation: 0,
                                        sx: {
                                            overflow: 'visible',
                                            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                                            mt: 1.5,
                                            '& .MuiAvatar-root': {
                                                width: 32,
                                                height: 32,
                                                ml: -0.5,
                                                mr: 1,
                                            },
                                            '&::before': {
                                                content: '""',
                                                display: 'block',
                                                position: 'absolute',
                                                top: 0,
                                                right: 14,
                                                width: 10,
                                                height: 10,
                                                bgcolor: 'background.paper',
                                                transform: 'translateY(-50%) rotate(45deg)',
                                                zIndex: 0,
                                            },
                                        },
                                    }}
                                    transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                                    anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                                >
                                    {pages.map((page) => (
                                        <Link key={page.url} to={`/${page.url}`} style={{ textDecoration: 'none', color: 'black' }}>
                                            <MenuItem key={page.url} onClick={handleClose}>
                                                <Avatar><IconResolver key={page.icon} iconName={page.icon} /></Avatar><Typography textAlign="center">{page.title}</Typography>
                                            </MenuItem>
                                        </Link>
                                    ))}
                                    {!props.userLoggedIn &&
                                        <Link key={'/login'} to={`/login`} style={{ textDecoration: 'none', color: 'black' }}>
                                            <MenuItem key={'/login1'} onClick={handleClose}>
                                                <Avatar key={'/login2'}><IconResolver key={"/login3"} iconName="LockOpen" /></Avatar><Typography textAlign="center">Login</Typography>
                                            </MenuItem>
                                        </Link>
                                    }
                                    {!props.userLoggedIn &&
                                        <Link key={'/register'} to={`/register`} style={{ textDecoration: 'none', color: 'black' }}>
                                            <MenuItem key={'/register1'} onClick={handleClose}>
                                                <Avatar key={'/register2'}><IconResolver key={"/register3"} iconName="Login" /></Avatar><Typography textAlign="center">Get Started</Typography>
                                            </MenuItem>
                                        </Link>
                                    }
                                    {props.userLoggedIn &&
                                        <Link key={'/dashboard'} to={`/dashboard`} style={{ textDecoration: 'none', color: 'black' }}>
                                            <MenuItem key={'/dashboard'} onClick={handleClose}>
                                                <Avatar key={'/dashboard'}><IconResolver key={"/dashboard"} iconName="Dashboard" /></Avatar><Typography textAlign="center">My Account</Typography>
                                            </MenuItem>
                                        </Link>
                                    }
                                </Menu>
                            </Grid>
                        </Grid>
                    </Box>

                    {/* ****** MEDIUM SIZED SCREEN */}
                    <Box sx={{ flexGrow: 1, display: { xs: 'none', lg: 'flex' } }}>
                        <Grid container alignItems='center' >
                            <Grid item xs={3} >
                                <img src={mainLogo} alt="logo" style={{ height: '80px', marginTop: '1rem', marginBottom: '1rem', marginLeft: '1rem' }} />
                            </Grid>
                            <Grid item container flexDirection='row' justifyContent='flex-end' xs={6}  >
                                {pages.map((page) => (
                                    <Link key={`/${page.url}`} to={`/${page.url}`} style={{ textDecoration: 'none' }}>
                                        <Button
                                            variant='text'
                                            key={page.url}
                                            sx={{ m: 2, color: 'black', display: 'block', fontSize: '1.5rem' }}
                                        >
                                            {page.title}
                                        </Button>
                                    </Link>
                                ))}
                            </Grid>
                            <Grid item container flexDirection='row' justifyContent='space-around' xs={3}  >
                                {
                                    !props.userLoggedIn &&
                                    <Link to={"/register"} style={{ textDecoration: 'none' }}>
                                        <Button
                                            variant="contained"
                                            key={"login"}
                                            sx={{ fontWeight: 'bold', color: 'white', display: 'block', fontSize: '1.25rem', borderRadius: '5', height: '3.7rem' }}
                                        >
                                            Get Started
                                    </Button>
                                    </Link>
                                }
                                {
                                    !props.userLoggedIn &&
                                    <Link to={"/login"} style={{ textDecoration: 'none' }}>
                                        <Button
                                            variant="outlined"
                                            key={"login"}
                                            sx={{ fontWeight: 'bold', color: 'primary', display: 'block', fontSize: '1.25rem', borderRadius: '5', height: '3.7rem' }}
                                        >
                                            Login
                                    </Button>
                                    </Link>
                                }
                                {
                                    props.userLoggedIn &&
                                    <Link to={"/dashboard"} style={{ textDecoration: 'none' }}>
                                        <Button
                                            variant="outlined"
                                            key={"dashboard"}
                                            sx={{ fontWeight: 'bold', color: 'primary', display: 'block', fontSize: '1.25rem', borderRadius: '5', height: '3.7rem' }}
                                        >
                                            My Account
                                    </Button>
                                    </Link>
                                }
                            </Grid>

                        </Grid>
                    </Box>
                </Toolbar>
            </AppBar>
        </>
    );
}
export default ResponsiveAppBar;