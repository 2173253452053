/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const listRequestsForUser = /* GraphQL */ `
  mutation ListRequestsForUser($usreId: String, $fetchItem: String) {
    listRequestsForUser(usreId: $usreId, fetchItem: $fetchItem)
  }
`;
export const createAdmin = /* GraphQL */ `
  mutation CreateAdmin(
    $input: CreateAdminInput!
    $condition: ModelAdminConditionInput
  ) {
    createAdmin(input: $input, condition: $condition) {
      id
      firstName
      lastName
      email
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateAdmin = /* GraphQL */ `
  mutation UpdateAdmin(
    $input: UpdateAdminInput!
    $condition: ModelAdminConditionInput
  ) {
    updateAdmin(input: $input, condition: $condition) {
      id
      firstName
      lastName
      email
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteAdmin = /* GraphQL */ `
  mutation DeleteAdmin(
    $input: DeleteAdminInput!
    $condition: ModelAdminConditionInput
  ) {
    deleteAdmin(input: $input, condition: $condition) {
      id
      firstName
      lastName
      email
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createVendor = /* GraphQL */ `
  mutation CreateVendor(
    $input: CreateVendorInput!
    $condition: ModelVendorConditionInput
  ) {
    createVendor(input: $input, condition: $condition) {
      id
      stripeUserId
      firstName
      lastName
      email
      phoneNumber
      companyName
      address
      city
      province
      postalCode
      industry {
        id
        title
        requests {
          nextToken
          __typename
        }
        active
        subIndustry {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      subIndustry {
        items {
          id
          title
          active
          createdAt
          updatedAt
          vendorSubIndustryId
          industrySubIndustryId
          subIndustryIndustryId
          __typename
        }
        nextToken
        __typename
      }
      subscriptionLevel {
        id
        frequency
        title
        amount
        active
        createdDate
        inactiveDate
        entitlement
        createdAt
        updatedAt
        __typename
      }
      subscriptionStatus
      subscriptionExpiry
      autoRenew
      creationDate
      favourites {
        items {
          id
          createdAt
          updatedAt
          vendorFavouritesId
          favouritesRequestsId
          favouritesOwnerIdId
          __typename
        }
        nextToken
        __typename
      }
      userNotes {
        items {
          id
          note
          date
          requestID
          vendorID
          createdAt
          updatedAt
          vendorUserNotesId
          requestUserNotesId
          __typename
        }
        nextToken
        __typename
      }
      group
      createdAt
      updatedAt
      vendorIndustryId
      vendorSubscriptionLevelId
      __typename
    }
  }
`;
export const updateVendor = /* GraphQL */ `
  mutation UpdateVendor(
    $input: UpdateVendorInput!
    $condition: ModelVendorConditionInput
  ) {
    updateVendor(input: $input, condition: $condition) {
      id
      stripeUserId
      firstName
      lastName
      email
      phoneNumber
      companyName
      address
      city
      province
      postalCode
      industry {
        id
        title
        requests {
          nextToken
          __typename
        }
        active
        subIndustry {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      subIndustry {
        items {
          id
          title
          active
          createdAt
          updatedAt
          vendorSubIndustryId
          industrySubIndustryId
          subIndustryIndustryId
          __typename
        }
        nextToken
        __typename
      }
      subscriptionLevel {
        id
        frequency
        title
        amount
        active
        createdDate
        inactiveDate
        entitlement
        createdAt
        updatedAt
        __typename
      }
      subscriptionStatus
      subscriptionExpiry
      autoRenew
      creationDate
      favourites {
        items {
          id
          createdAt
          updatedAt
          vendorFavouritesId
          favouritesRequestsId
          favouritesOwnerIdId
          __typename
        }
        nextToken
        __typename
      }
      userNotes {
        items {
          id
          note
          date
          requestID
          vendorID
          createdAt
          updatedAt
          vendorUserNotesId
          requestUserNotesId
          __typename
        }
        nextToken
        __typename
      }
      group
      createdAt
      updatedAt
      vendorIndustryId
      vendorSubscriptionLevelId
      __typename
    }
  }
`;
export const deleteVendor = /* GraphQL */ `
  mutation DeleteVendor(
    $input: DeleteVendorInput!
    $condition: ModelVendorConditionInput
  ) {
    deleteVendor(input: $input, condition: $condition) {
      id
      stripeUserId
      firstName
      lastName
      email
      phoneNumber
      companyName
      address
      city
      province
      postalCode
      industry {
        id
        title
        requests {
          nextToken
          __typename
        }
        active
        subIndustry {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      subIndustry {
        items {
          id
          title
          active
          createdAt
          updatedAt
          vendorSubIndustryId
          industrySubIndustryId
          subIndustryIndustryId
          __typename
        }
        nextToken
        __typename
      }
      subscriptionLevel {
        id
        frequency
        title
        amount
        active
        createdDate
        inactiveDate
        entitlement
        createdAt
        updatedAt
        __typename
      }
      subscriptionStatus
      subscriptionExpiry
      autoRenew
      creationDate
      favourites {
        items {
          id
          createdAt
          updatedAt
          vendorFavouritesId
          favouritesRequestsId
          favouritesOwnerIdId
          __typename
        }
        nextToken
        __typename
      }
      userNotes {
        items {
          id
          note
          date
          requestID
          vendorID
          createdAt
          updatedAt
          vendorUserNotesId
          requestUserNotesId
          __typename
        }
        nextToken
        __typename
      }
      group
      createdAt
      updatedAt
      vendorIndustryId
      vendorSubscriptionLevelId
      __typename
    }
  }
`;
export const createLead = /* GraphQL */ `
  mutation CreateLead(
    $input: CreateLeadInput!
    $condition: ModelLeadConditionInput
  ) {
    createLead(input: $input, condition: $condition) {
      id
      companyName
      url
      address
      address2
      city
      provinceState
      postalZip
      contactFirstName
      contactLastName
      titlePosition
      contactPhone
      contactEmail
      buildingType
      requestType {
        id
        title
        requests {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      industry {
        id
        title
        requests {
          nextToken
          __typename
        }
        active
        subIndustry {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      subIndustry {
        id
        title
        industry {
          id
          title
          active
          createdAt
          updatedAt
          __typename
        }
        requests {
          nextToken
          __typename
        }
        active
        createdAt
        updatedAt
        vendorSubIndustryId
        industrySubIndustryId
        subIndustryIndustryId
        __typename
      }
      requestNotes
      internalNotes
      callBackDate
      uploadDate
      goLiveDate
      expirationDate
      createdAt
      updatedAt
      leadRequestTypeId
      leadIndustryId
      leadSubIndustryId
      __typename
    }
  }
`;
export const updateLead = /* GraphQL */ `
  mutation UpdateLead(
    $input: UpdateLeadInput!
    $condition: ModelLeadConditionInput
  ) {
    updateLead(input: $input, condition: $condition) {
      id
      companyName
      url
      address
      address2
      city
      provinceState
      postalZip
      contactFirstName
      contactLastName
      titlePosition
      contactPhone
      contactEmail
      buildingType
      requestType {
        id
        title
        requests {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      industry {
        id
        title
        requests {
          nextToken
          __typename
        }
        active
        subIndustry {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      subIndustry {
        id
        title
        industry {
          id
          title
          active
          createdAt
          updatedAt
          __typename
        }
        requests {
          nextToken
          __typename
        }
        active
        createdAt
        updatedAt
        vendorSubIndustryId
        industrySubIndustryId
        subIndustryIndustryId
        __typename
      }
      requestNotes
      internalNotes
      callBackDate
      uploadDate
      goLiveDate
      expirationDate
      createdAt
      updatedAt
      leadRequestTypeId
      leadIndustryId
      leadSubIndustryId
      __typename
    }
  }
`;
export const deleteLead = /* GraphQL */ `
  mutation DeleteLead(
    $input: DeleteLeadInput!
    $condition: ModelLeadConditionInput
  ) {
    deleteLead(input: $input, condition: $condition) {
      id
      companyName
      url
      address
      address2
      city
      provinceState
      postalZip
      contactFirstName
      contactLastName
      titlePosition
      contactPhone
      contactEmail
      buildingType
      requestType {
        id
        title
        requests {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      industry {
        id
        title
        requests {
          nextToken
          __typename
        }
        active
        subIndustry {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      subIndustry {
        id
        title
        industry {
          id
          title
          active
          createdAt
          updatedAt
          __typename
        }
        requests {
          nextToken
          __typename
        }
        active
        createdAt
        updatedAt
        vendorSubIndustryId
        industrySubIndustryId
        subIndustryIndustryId
        __typename
      }
      requestNotes
      internalNotes
      callBackDate
      uploadDate
      goLiveDate
      expirationDate
      createdAt
      updatedAt
      leadRequestTypeId
      leadIndustryId
      leadSubIndustryId
      __typename
    }
  }
`;
export const createRequest = /* GraphQL */ `
  mutation CreateRequest(
    $input: CreateRequestInput!
    $condition: ModelRequestConditionInput
  ) {
    createRequest(input: $input, condition: $condition) {
      id
      companyName
      url
      address
      address2
      city
      provinceState
      postalZip
      contactFirstName
      contactLastName
      titlePosition
      contactPhone
      contactEmail
      buildingType
      requestType {
        id
        title
        requests {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      industry {
        id
        title
        requests {
          nextToken
          __typename
        }
        active
        subIndustry {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      subIndustry {
        id
        title
        industry {
          id
          title
          active
          createdAt
          updatedAt
          __typename
        }
        requests {
          nextToken
          __typename
        }
        active
        createdAt
        updatedAt
        vendorSubIndustryId
        industrySubIndustryId
        subIndustryIndustryId
        __typename
      }
      requestNotes
      uploadDate
      goLiveDate
      expirationDate
      userNotes {
        items {
          id
          note
          date
          requestID
          vendorID
          createdAt
          updatedAt
          vendorUserNotesId
          requestUserNotesId
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      industryRequestsId
      subIndustryRequestsId
      requestTypeRequestsId
      requestRequestTypeId
      requestIndustryId
      requestSubIndustryId
      __typename
    }
  }
`;
export const updateRequest = /* GraphQL */ `
  mutation UpdateRequest(
    $input: UpdateRequestInput!
    $condition: ModelRequestConditionInput
  ) {
    updateRequest(input: $input, condition: $condition) {
      id
      companyName
      url
      address
      address2
      city
      provinceState
      postalZip
      contactFirstName
      contactLastName
      titlePosition
      contactPhone
      contactEmail
      buildingType
      requestType {
        id
        title
        requests {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      industry {
        id
        title
        requests {
          nextToken
          __typename
        }
        active
        subIndustry {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      subIndustry {
        id
        title
        industry {
          id
          title
          active
          createdAt
          updatedAt
          __typename
        }
        requests {
          nextToken
          __typename
        }
        active
        createdAt
        updatedAt
        vendorSubIndustryId
        industrySubIndustryId
        subIndustryIndustryId
        __typename
      }
      requestNotes
      uploadDate
      goLiveDate
      expirationDate
      userNotes {
        items {
          id
          note
          date
          requestID
          vendorID
          createdAt
          updatedAt
          vendorUserNotesId
          requestUserNotesId
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      industryRequestsId
      subIndustryRequestsId
      requestTypeRequestsId
      requestRequestTypeId
      requestIndustryId
      requestSubIndustryId
      __typename
    }
  }
`;
export const deleteRequest = /* GraphQL */ `
  mutation DeleteRequest(
    $input: DeleteRequestInput!
    $condition: ModelRequestConditionInput
  ) {
    deleteRequest(input: $input, condition: $condition) {
      id
      companyName
      url
      address
      address2
      city
      provinceState
      postalZip
      contactFirstName
      contactLastName
      titlePosition
      contactPhone
      contactEmail
      buildingType
      requestType {
        id
        title
        requests {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      industry {
        id
        title
        requests {
          nextToken
          __typename
        }
        active
        subIndustry {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      subIndustry {
        id
        title
        industry {
          id
          title
          active
          createdAt
          updatedAt
          __typename
        }
        requests {
          nextToken
          __typename
        }
        active
        createdAt
        updatedAt
        vendorSubIndustryId
        industrySubIndustryId
        subIndustryIndustryId
        __typename
      }
      requestNotes
      uploadDate
      goLiveDate
      expirationDate
      userNotes {
        items {
          id
          note
          date
          requestID
          vendorID
          createdAt
          updatedAt
          vendorUserNotesId
          requestUserNotesId
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      industryRequestsId
      subIndustryRequestsId
      requestTypeRequestsId
      requestRequestTypeId
      requestIndustryId
      requestSubIndustryId
      __typename
    }
  }
`;
export const createIndustry = /* GraphQL */ `
  mutation CreateIndustry(
    $input: CreateIndustryInput!
    $condition: ModelIndustryConditionInput
  ) {
    createIndustry(input: $input, condition: $condition) {
      id
      title
      requests {
        items {
          id
          companyName
          url
          address
          address2
          city
          provinceState
          postalZip
          contactFirstName
          contactLastName
          titlePosition
          contactPhone
          contactEmail
          buildingType
          requestNotes
          uploadDate
          goLiveDate
          expirationDate
          createdAt
          updatedAt
          industryRequestsId
          subIndustryRequestsId
          requestTypeRequestsId
          requestRequestTypeId
          requestIndustryId
          requestSubIndustryId
          __typename
        }
        nextToken
        __typename
      }
      active
      subIndustry {
        items {
          id
          title
          active
          createdAt
          updatedAt
          vendorSubIndustryId
          industrySubIndustryId
          subIndustryIndustryId
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateIndustry = /* GraphQL */ `
  mutation UpdateIndustry(
    $input: UpdateIndustryInput!
    $condition: ModelIndustryConditionInput
  ) {
    updateIndustry(input: $input, condition: $condition) {
      id
      title
      requests {
        items {
          id
          companyName
          url
          address
          address2
          city
          provinceState
          postalZip
          contactFirstName
          contactLastName
          titlePosition
          contactPhone
          contactEmail
          buildingType
          requestNotes
          uploadDate
          goLiveDate
          expirationDate
          createdAt
          updatedAt
          industryRequestsId
          subIndustryRequestsId
          requestTypeRequestsId
          requestRequestTypeId
          requestIndustryId
          requestSubIndustryId
          __typename
        }
        nextToken
        __typename
      }
      active
      subIndustry {
        items {
          id
          title
          active
          createdAt
          updatedAt
          vendorSubIndustryId
          industrySubIndustryId
          subIndustryIndustryId
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteIndustry = /* GraphQL */ `
  mutation DeleteIndustry(
    $input: DeleteIndustryInput!
    $condition: ModelIndustryConditionInput
  ) {
    deleteIndustry(input: $input, condition: $condition) {
      id
      title
      requests {
        items {
          id
          companyName
          url
          address
          address2
          city
          provinceState
          postalZip
          contactFirstName
          contactLastName
          titlePosition
          contactPhone
          contactEmail
          buildingType
          requestNotes
          uploadDate
          goLiveDate
          expirationDate
          createdAt
          updatedAt
          industryRequestsId
          subIndustryRequestsId
          requestTypeRequestsId
          requestRequestTypeId
          requestIndustryId
          requestSubIndustryId
          __typename
        }
        nextToken
        __typename
      }
      active
      subIndustry {
        items {
          id
          title
          active
          createdAt
          updatedAt
          vendorSubIndustryId
          industrySubIndustryId
          subIndustryIndustryId
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createSubIndustry = /* GraphQL */ `
  mutation CreateSubIndustry(
    $input: CreateSubIndustryInput!
    $condition: ModelSubIndustryConditionInput
  ) {
    createSubIndustry(input: $input, condition: $condition) {
      id
      title
      industry {
        id
        title
        requests {
          nextToken
          __typename
        }
        active
        subIndustry {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      requests {
        items {
          id
          companyName
          url
          address
          address2
          city
          provinceState
          postalZip
          contactFirstName
          contactLastName
          titlePosition
          contactPhone
          contactEmail
          buildingType
          requestNotes
          uploadDate
          goLiveDate
          expirationDate
          createdAt
          updatedAt
          industryRequestsId
          subIndustryRequestsId
          requestTypeRequestsId
          requestRequestTypeId
          requestIndustryId
          requestSubIndustryId
          __typename
        }
        nextToken
        __typename
      }
      active
      createdAt
      updatedAt
      vendorSubIndustryId
      industrySubIndustryId
      subIndustryIndustryId
      __typename
    }
  }
`;
export const updateSubIndustry = /* GraphQL */ `
  mutation UpdateSubIndustry(
    $input: UpdateSubIndustryInput!
    $condition: ModelSubIndustryConditionInput
  ) {
    updateSubIndustry(input: $input, condition: $condition) {
      id
      title
      industry {
        id
        title
        requests {
          nextToken
          __typename
        }
        active
        subIndustry {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      requests {
        items {
          id
          companyName
          url
          address
          address2
          city
          provinceState
          postalZip
          contactFirstName
          contactLastName
          titlePosition
          contactPhone
          contactEmail
          buildingType
          requestNotes
          uploadDate
          goLiveDate
          expirationDate
          createdAt
          updatedAt
          industryRequestsId
          subIndustryRequestsId
          requestTypeRequestsId
          requestRequestTypeId
          requestIndustryId
          requestSubIndustryId
          __typename
        }
        nextToken
        __typename
      }
      active
      createdAt
      updatedAt
      vendorSubIndustryId
      industrySubIndustryId
      subIndustryIndustryId
      __typename
    }
  }
`;
export const deleteSubIndustry = /* GraphQL */ `
  mutation DeleteSubIndustry(
    $input: DeleteSubIndustryInput!
    $condition: ModelSubIndustryConditionInput
  ) {
    deleteSubIndustry(input: $input, condition: $condition) {
      id
      title
      industry {
        id
        title
        requests {
          nextToken
          __typename
        }
        active
        subIndustry {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      requests {
        items {
          id
          companyName
          url
          address
          address2
          city
          provinceState
          postalZip
          contactFirstName
          contactLastName
          titlePosition
          contactPhone
          contactEmail
          buildingType
          requestNotes
          uploadDate
          goLiveDate
          expirationDate
          createdAt
          updatedAt
          industryRequestsId
          subIndustryRequestsId
          requestTypeRequestsId
          requestRequestTypeId
          requestIndustryId
          requestSubIndustryId
          __typename
        }
        nextToken
        __typename
      }
      active
      createdAt
      updatedAt
      vendorSubIndustryId
      industrySubIndustryId
      subIndustryIndustryId
      __typename
    }
  }
`;
export const createRequestType = /* GraphQL */ `
  mutation CreateRequestType(
    $input: CreateRequestTypeInput!
    $condition: ModelRequestTypeConditionInput
  ) {
    createRequestType(input: $input, condition: $condition) {
      id
      title
      requests {
        items {
          id
          companyName
          url
          address
          address2
          city
          provinceState
          postalZip
          contactFirstName
          contactLastName
          titlePosition
          contactPhone
          contactEmail
          buildingType
          requestNotes
          uploadDate
          goLiveDate
          expirationDate
          createdAt
          updatedAt
          industryRequestsId
          subIndustryRequestsId
          requestTypeRequestsId
          requestRequestTypeId
          requestIndustryId
          requestSubIndustryId
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateRequestType = /* GraphQL */ `
  mutation UpdateRequestType(
    $input: UpdateRequestTypeInput!
    $condition: ModelRequestTypeConditionInput
  ) {
    updateRequestType(input: $input, condition: $condition) {
      id
      title
      requests {
        items {
          id
          companyName
          url
          address
          address2
          city
          provinceState
          postalZip
          contactFirstName
          contactLastName
          titlePosition
          contactPhone
          contactEmail
          buildingType
          requestNotes
          uploadDate
          goLiveDate
          expirationDate
          createdAt
          updatedAt
          industryRequestsId
          subIndustryRequestsId
          requestTypeRequestsId
          requestRequestTypeId
          requestIndustryId
          requestSubIndustryId
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteRequestType = /* GraphQL */ `
  mutation DeleteRequestType(
    $input: DeleteRequestTypeInput!
    $condition: ModelRequestTypeConditionInput
  ) {
    deleteRequestType(input: $input, condition: $condition) {
      id
      title
      requests {
        items {
          id
          companyName
          url
          address
          address2
          city
          provinceState
          postalZip
          contactFirstName
          contactLastName
          titlePosition
          contactPhone
          contactEmail
          buildingType
          requestNotes
          uploadDate
          goLiveDate
          expirationDate
          createdAt
          updatedAt
          industryRequestsId
          subIndustryRequestsId
          requestTypeRequestsId
          requestRequestTypeId
          requestIndustryId
          requestSubIndustryId
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createSubscriptionLevel = /* GraphQL */ `
  mutation CreateSubscriptionLevel(
    $input: CreateSubscriptionLevelInput!
    $condition: ModelSubscriptionLevelConditionInput
  ) {
    createSubscriptionLevel(input: $input, condition: $condition) {
      id
      frequency
      title
      amount
      active
      createdDate
      inactiveDate
      entitlement
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateSubscriptionLevel = /* GraphQL */ `
  mutation UpdateSubscriptionLevel(
    $input: UpdateSubscriptionLevelInput!
    $condition: ModelSubscriptionLevelConditionInput
  ) {
    updateSubscriptionLevel(input: $input, condition: $condition) {
      id
      frequency
      title
      amount
      active
      createdDate
      inactiveDate
      entitlement
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteSubscriptionLevel = /* GraphQL */ `
  mutation DeleteSubscriptionLevel(
    $input: DeleteSubscriptionLevelInput!
    $condition: ModelSubscriptionLevelConditionInput
  ) {
    deleteSubscriptionLevel(input: $input, condition: $condition) {
      id
      frequency
      title
      amount
      active
      createdDate
      inactiveDate
      entitlement
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createFavourites = /* GraphQL */ `
  mutation CreateFavourites(
    $input: CreateFavouritesInput!
    $condition: ModelFavouritesConditionInput
  ) {
    createFavourites(input: $input, condition: $condition) {
      id
      requests {
        id
        companyName
        url
        address
        address2
        city
        provinceState
        postalZip
        contactFirstName
        contactLastName
        titlePosition
        contactPhone
        contactEmail
        buildingType
        requestType {
          id
          title
          createdAt
          updatedAt
          __typename
        }
        industry {
          id
          title
          active
          createdAt
          updatedAt
          __typename
        }
        subIndustry {
          id
          title
          active
          createdAt
          updatedAt
          vendorSubIndustryId
          industrySubIndustryId
          subIndustryIndustryId
          __typename
        }
        requestNotes
        uploadDate
        goLiveDate
        expirationDate
        userNotes {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        industryRequestsId
        subIndustryRequestsId
        requestTypeRequestsId
        requestRequestTypeId
        requestIndustryId
        requestSubIndustryId
        __typename
      }
      ownerId {
        id
        stripeUserId
        firstName
        lastName
        email
        phoneNumber
        companyName
        address
        city
        province
        postalCode
        industry {
          id
          title
          active
          createdAt
          updatedAt
          __typename
        }
        subIndustry {
          nextToken
          __typename
        }
        subscriptionLevel {
          id
          frequency
          title
          amount
          active
          createdDate
          inactiveDate
          entitlement
          createdAt
          updatedAt
          __typename
        }
        subscriptionStatus
        subscriptionExpiry
        autoRenew
        creationDate
        favourites {
          nextToken
          __typename
        }
        userNotes {
          nextToken
          __typename
        }
        group
        createdAt
        updatedAt
        vendorIndustryId
        vendorSubscriptionLevelId
        __typename
      }
      createdAt
      updatedAt
      vendorFavouritesId
      favouritesRequestsId
      favouritesOwnerIdId
      __typename
    }
  }
`;
export const updateFavourites = /* GraphQL */ `
  mutation UpdateFavourites(
    $input: UpdateFavouritesInput!
    $condition: ModelFavouritesConditionInput
  ) {
    updateFavourites(input: $input, condition: $condition) {
      id
      requests {
        id
        companyName
        url
        address
        address2
        city
        provinceState
        postalZip
        contactFirstName
        contactLastName
        titlePosition
        contactPhone
        contactEmail
        buildingType
        requestType {
          id
          title
          createdAt
          updatedAt
          __typename
        }
        industry {
          id
          title
          active
          createdAt
          updatedAt
          __typename
        }
        subIndustry {
          id
          title
          active
          createdAt
          updatedAt
          vendorSubIndustryId
          industrySubIndustryId
          subIndustryIndustryId
          __typename
        }
        requestNotes
        uploadDate
        goLiveDate
        expirationDate
        userNotes {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        industryRequestsId
        subIndustryRequestsId
        requestTypeRequestsId
        requestRequestTypeId
        requestIndustryId
        requestSubIndustryId
        __typename
      }
      ownerId {
        id
        stripeUserId
        firstName
        lastName
        email
        phoneNumber
        companyName
        address
        city
        province
        postalCode
        industry {
          id
          title
          active
          createdAt
          updatedAt
          __typename
        }
        subIndustry {
          nextToken
          __typename
        }
        subscriptionLevel {
          id
          frequency
          title
          amount
          active
          createdDate
          inactiveDate
          entitlement
          createdAt
          updatedAt
          __typename
        }
        subscriptionStatus
        subscriptionExpiry
        autoRenew
        creationDate
        favourites {
          nextToken
          __typename
        }
        userNotes {
          nextToken
          __typename
        }
        group
        createdAt
        updatedAt
        vendorIndustryId
        vendorSubscriptionLevelId
        __typename
      }
      createdAt
      updatedAt
      vendorFavouritesId
      favouritesRequestsId
      favouritesOwnerIdId
      __typename
    }
  }
`;
export const deleteFavourites = /* GraphQL */ `
  mutation DeleteFavourites(
    $input: DeleteFavouritesInput!
    $condition: ModelFavouritesConditionInput
  ) {
    deleteFavourites(input: $input, condition: $condition) {
      id
      requests {
        id
        companyName
        url
        address
        address2
        city
        provinceState
        postalZip
        contactFirstName
        contactLastName
        titlePosition
        contactPhone
        contactEmail
        buildingType
        requestType {
          id
          title
          createdAt
          updatedAt
          __typename
        }
        industry {
          id
          title
          active
          createdAt
          updatedAt
          __typename
        }
        subIndustry {
          id
          title
          active
          createdAt
          updatedAt
          vendorSubIndustryId
          industrySubIndustryId
          subIndustryIndustryId
          __typename
        }
        requestNotes
        uploadDate
        goLiveDate
        expirationDate
        userNotes {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        industryRequestsId
        subIndustryRequestsId
        requestTypeRequestsId
        requestRequestTypeId
        requestIndustryId
        requestSubIndustryId
        __typename
      }
      ownerId {
        id
        stripeUserId
        firstName
        lastName
        email
        phoneNumber
        companyName
        address
        city
        province
        postalCode
        industry {
          id
          title
          active
          createdAt
          updatedAt
          __typename
        }
        subIndustry {
          nextToken
          __typename
        }
        subscriptionLevel {
          id
          frequency
          title
          amount
          active
          createdDate
          inactiveDate
          entitlement
          createdAt
          updatedAt
          __typename
        }
        subscriptionStatus
        subscriptionExpiry
        autoRenew
        creationDate
        favourites {
          nextToken
          __typename
        }
        userNotes {
          nextToken
          __typename
        }
        group
        createdAt
        updatedAt
        vendorIndustryId
        vendorSubscriptionLevelId
        __typename
      }
      createdAt
      updatedAt
      vendorFavouritesId
      favouritesRequestsId
      favouritesOwnerIdId
      __typename
    }
  }
`;
export const createNotes = /* GraphQL */ `
  mutation CreateNotes(
    $input: CreateNotesInput!
    $condition: ModelNotesConditionInput
  ) {
    createNotes(input: $input, condition: $condition) {
      id
      note
      date
      requestID
      request {
        id
        companyName
        url
        address
        address2
        city
        provinceState
        postalZip
        contactFirstName
        contactLastName
        titlePosition
        contactPhone
        contactEmail
        buildingType
        requestType {
          id
          title
          createdAt
          updatedAt
          __typename
        }
        industry {
          id
          title
          active
          createdAt
          updatedAt
          __typename
        }
        subIndustry {
          id
          title
          active
          createdAt
          updatedAt
          vendorSubIndustryId
          industrySubIndustryId
          subIndustryIndustryId
          __typename
        }
        requestNotes
        uploadDate
        goLiveDate
        expirationDate
        userNotes {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        industryRequestsId
        subIndustryRequestsId
        requestTypeRequestsId
        requestRequestTypeId
        requestIndustryId
        requestSubIndustryId
        __typename
      }
      vendorID
      vendor {
        id
        stripeUserId
        firstName
        lastName
        email
        phoneNumber
        companyName
        address
        city
        province
        postalCode
        industry {
          id
          title
          active
          createdAt
          updatedAt
          __typename
        }
        subIndustry {
          nextToken
          __typename
        }
        subscriptionLevel {
          id
          frequency
          title
          amount
          active
          createdDate
          inactiveDate
          entitlement
          createdAt
          updatedAt
          __typename
        }
        subscriptionStatus
        subscriptionExpiry
        autoRenew
        creationDate
        favourites {
          nextToken
          __typename
        }
        userNotes {
          nextToken
          __typename
        }
        group
        createdAt
        updatedAt
        vendorIndustryId
        vendorSubscriptionLevelId
        __typename
      }
      createdAt
      updatedAt
      vendorUserNotesId
      requestUserNotesId
      __typename
    }
  }
`;
export const updateNotes = /* GraphQL */ `
  mutation UpdateNotes(
    $input: UpdateNotesInput!
    $condition: ModelNotesConditionInput
  ) {
    updateNotes(input: $input, condition: $condition) {
      id
      note
      date
      requestID
      request {
        id
        companyName
        url
        address
        address2
        city
        provinceState
        postalZip
        contactFirstName
        contactLastName
        titlePosition
        contactPhone
        contactEmail
        buildingType
        requestType {
          id
          title
          createdAt
          updatedAt
          __typename
        }
        industry {
          id
          title
          active
          createdAt
          updatedAt
          __typename
        }
        subIndustry {
          id
          title
          active
          createdAt
          updatedAt
          vendorSubIndustryId
          industrySubIndustryId
          subIndustryIndustryId
          __typename
        }
        requestNotes
        uploadDate
        goLiveDate
        expirationDate
        userNotes {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        industryRequestsId
        subIndustryRequestsId
        requestTypeRequestsId
        requestRequestTypeId
        requestIndustryId
        requestSubIndustryId
        __typename
      }
      vendorID
      vendor {
        id
        stripeUserId
        firstName
        lastName
        email
        phoneNumber
        companyName
        address
        city
        province
        postalCode
        industry {
          id
          title
          active
          createdAt
          updatedAt
          __typename
        }
        subIndustry {
          nextToken
          __typename
        }
        subscriptionLevel {
          id
          frequency
          title
          amount
          active
          createdDate
          inactiveDate
          entitlement
          createdAt
          updatedAt
          __typename
        }
        subscriptionStatus
        subscriptionExpiry
        autoRenew
        creationDate
        favourites {
          nextToken
          __typename
        }
        userNotes {
          nextToken
          __typename
        }
        group
        createdAt
        updatedAt
        vendorIndustryId
        vendorSubscriptionLevelId
        __typename
      }
      createdAt
      updatedAt
      vendorUserNotesId
      requestUserNotesId
      __typename
    }
  }
`;
export const deleteNotes = /* GraphQL */ `
  mutation DeleteNotes(
    $input: DeleteNotesInput!
    $condition: ModelNotesConditionInput
  ) {
    deleteNotes(input: $input, condition: $condition) {
      id
      note
      date
      requestID
      request {
        id
        companyName
        url
        address
        address2
        city
        provinceState
        postalZip
        contactFirstName
        contactLastName
        titlePosition
        contactPhone
        contactEmail
        buildingType
        requestType {
          id
          title
          createdAt
          updatedAt
          __typename
        }
        industry {
          id
          title
          active
          createdAt
          updatedAt
          __typename
        }
        subIndustry {
          id
          title
          active
          createdAt
          updatedAt
          vendorSubIndustryId
          industrySubIndustryId
          subIndustryIndustryId
          __typename
        }
        requestNotes
        uploadDate
        goLiveDate
        expirationDate
        userNotes {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        industryRequestsId
        subIndustryRequestsId
        requestTypeRequestsId
        requestRequestTypeId
        requestIndustryId
        requestSubIndustryId
        __typename
      }
      vendorID
      vendor {
        id
        stripeUserId
        firstName
        lastName
        email
        phoneNumber
        companyName
        address
        city
        province
        postalCode
        industry {
          id
          title
          active
          createdAt
          updatedAt
          __typename
        }
        subIndustry {
          nextToken
          __typename
        }
        subscriptionLevel {
          id
          frequency
          title
          amount
          active
          createdDate
          inactiveDate
          entitlement
          createdAt
          updatedAt
          __typename
        }
        subscriptionStatus
        subscriptionExpiry
        autoRenew
        creationDate
        favourites {
          nextToken
          __typename
        }
        userNotes {
          nextToken
          __typename
        }
        group
        createdAt
        updatedAt
        vendorIndustryId
        vendorSubscriptionLevelId
        __typename
      }
      createdAt
      updatedAt
      vendorUserNotesId
      requestUserNotesId
      __typename
    }
  }
`;
export const sendHelpRequest = /* GraphQL */ `
  mutation SendHelpRequest(
    $name: String!
    $email: String!
    $accountEmail: String
    $subject: String!
    $message: String
  ) {
    sendHelpRequest(
      name: $name
      email: $email
      accountEmail: $accountEmail
      subject: $subject
      message: $message
    )
  }
`;
